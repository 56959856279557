import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { 
    Autocomplete, Box, Divider, Paper, Tooltip, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
    LinearProgress, TextField, Snackbar, Alert} from "@mui/material";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useNavigate } from "react-router-dom";
import {
    SELECT_SC_KEYWORD,
    LOAD_SC_LIST
} from "../actions/preview";
import {
    MANAGER_DASH_LOAD_KEYWORD_DETAIL,
    ASSIGN_KEYWORD_TO_USER,
    MANAGER_CLOSE_NOTIFICATION
} from "../actions/manager";
import {
    SET_TASK_FROM_MANAGER
} from "../actions/content";

function BoardCard({ sx, index, style, data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const users = useSelector(state => state.admin.users);
    const settingLoading = useSelector(state => state.manager.settingLoading);
    const keywordDetail = useSelector(state => state.manager.keywordDetail);
    const item = data[index];
    const rowSX = {display: 'flex'};
    const labelSX = {width: '4rem'};
    const [onSetting, setOnSetting] = useState(false);
    const [reassignUser, setReassignUser] = useState({});
    const offSetting = (event)=>{
        event.stopPropagation();
        setOnSetting(false);
        setReassignUser({});
    }

    return <Box sx={{ padding: "0.125rem", display: "flex" }} style={style}>
        <Paper sx={{ display: "flex", flexGrow: 1, ...sx }}>
            <Box sx={{ width: "8px", marginRight: "4px", bgcolor: "info.light" }}/>
            <Box sx={{ display: "flex", padding: "0.25rem", flexGrow: 1, flexDirection: "column", justifyContent: "space-between"}} onClick={(e)=> {
                e.preventDefault()
                // Temporary hide due to vatools screen need task information like base
                // dispatch({ type: SET_TASK_FROM_MANAGER, payload: {id: item.id, name: item.keyword}});
                // navigate('/editor');
            }}>
                <Box sx={{display: "flex", flexDirection: "column"}}> 
                    <Box sx={{display: "flex", justifyContent: "end"}}>
                        {/* <Tooltip title={"Assign Task"}><AccountCircleIcon sx={{size: "large", margin: "0 4px 0 4px"}} onClick={(e)=> {
                                e.preventDefault();
                                e.stopPropagation();
                                dispatch({ type: MANAGER_DASH_LOAD_KEYWORD_DETAIL, payload: {id: item.id} });
                                setOnSetting(true);
                            }}/>
                        </Tooltip> */}
                        <Tooltip title={"Redirect to Preview Page"}>
                            <ArrowCircleRightIcon sx={{size: "large", color:"#0275d8", margin: "0 4px 0 4px"}} onClick={(e)=> {
                                e.preventDefault()
                                e.stopPropagation();
                                dispatch({ type: SELECT_SC_KEYWORD, payload: {id: item.id, name: item.keyword} });
                                dispatch({ type: LOAD_SC_LIST });
                                navigate('/manager/preview');
                            }}/>
                        </Tooltip>
                    </Box>
                    <Box sx={{fontSize: '1rem'}}><Typography variant={"h6"}>{item.keyword} ({item.contents})</Typography></Box>
                    {item.inventory > 0 && <Box sx={{fontSize: '0.8rem', display: 'flex'}}>
                        <Inventory2Icon sx={{fontSize: 16}}/>
                        <Tooltip title={"Inventory"}><Box sx={{margin: "0 4px", padding: '0 4px', backgroundColor: '#f5a27e'}}>{item.inventory}</Box></Tooltip>
                        <Tooltip title={"Channeled"}><Box sx={{margin: "0 4px", padding: '0 4px', backgroundColor: '#f5dd7e'}}>{item.channeled}</Box></Tooltip>
                        <Tooltip title={"Available"}><Box sx={{margin: "0 4px", padding: '0 4px', backgroundColor: '#7ec3f5'}}>{item.available}</Box></Tooltip>
                        <Tooltip title={"Provisioned"}><Box sx={{margin: "0 4px", padding: '0 4px', backgroundColor: '#65f1d7'}}>{item.provisioned}</Box></Tooltip>
                        <Tooltip title={"Lived"}><Box sx={{margin: "0 4px", padding: '0 4px', backgroundColor: '#6bf38b'}}>{item.lived}</Box></Tooltip>
                    </Box>}
                    {item.volume > 0 && <Box sx={{fontSize: '0.8rem',...rowSX}}><Box sx={labelSX}>Volume</Box><Box><Typography>{item.volume}</Typography></Box></Box>}
                    {item.difficulty > 0 && <Box sx={{fontSize: '0.8rem',...rowSX}}><Box sx={labelSX}>Difficulty</Box><Box><Typography>{item.difficulty}</Typography></Box></Box>}
                    {item.potential > 0 && <Box sx={{fontSize: '0.8rem',...rowSX}}><Box sx={labelSX}>Potential</Box><Box><Typography>{item.potential}</Typography></Box></Box>}
                </Box>
                <Box sx={{textAlign: "end"}}>
                    <Typography sx={{fontSize: 'smaller', fontWeight: "200"}}>{item.id}</Typography>
                </Box>
            </Box>
            <Dialog open={onSetting} onClose={offSetting}>
                <DialogTitle sx={{fontSize: 'large'}}>Assign Keyword</DialogTitle>
                {settingLoading && <LinearProgress variant="indeterminate" color="primary"/>}
                <DialogContent>
                    <DialogContentText sx={{padding: "10px"}}>
                        <Box sx={{paddingBottom: "10px", display: "flex"}}>
                            <Typography sx={{fontSize: 'medium', fontWeight: "bold", paddingRight: "5px"}}>
                                Name:
                            </Typography>
                            <Typography sx={{fontSize: 'medium', paddingRight: "5px"}}>
                               {item?.keyword}
                            </Typography>
                        </Box>
                        <Box sx={{display: "flex"}}>
                            <Typography sx={{fontSize: 'medium', fontWeight: "bold", paddingRight: "5px"}} >
                                Assignee:
                            </Typography>
                            <Autocomplete
                                sx={{
                                    width: "150px",
                                    "& .MuiOutlinedInput-root": {
                                        padding: "0px",
                                        "& .MuiAutocomplete-input": {
                                            padding: "2px 2px 2px 4px"
                                        }
                                    },
                                
                                }}
                                fullWidth
                                onChange={(e, value)=>{
                                    e.preventDefault();
                                    if(value) {
                                        setReassignUser(value);
                                    }
                                }}
                                // onBlur={event=>{
                                //     dispatch({ type: ASSIGN_KEYWORD_TO_USER, payload: {userId: reassignUser.userId} })
                                // }}
                                disableClearable
                                options={Object.values(users).filter(x => x.userId !== keywordDetail?.taskAssigned) || []}
                                getOptionLabel={(option) => option?.name || ""}
                                // isOptionEqualToValue={(option, value) => option?.id === value.id}
                                value={(reassignUser?.userId ? users[reassignUser?.userId] : users[keywordDetail?.taskAssigned]) || {}}
                                renderInput={
                                    (params) => <TextField
                                        {...params}
                                        placeholder="Select User"
                                        InputProps={
                                            {
                                                ...params.InputProps,
                                            }}
                                    />
                                }
                            />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        dispatch({ type: ASSIGN_KEYWORD_TO_USER, payload: {userId: reassignUser.userId} });
                    }}>Confirm</Button>
                    <Button onClick={offSetting}>Dismiss</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    </Box>;
}

function BoardHeader({ title, desc, total, sx }) {
    return <Tooltip sx={{fontSize: '120%'}} arrow title={
        desc
    }><Box sx={{
        position: "sticky",
        top: 0,
        padding: "0.5rem 0.25rem",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "space-between",
        ...sx
    }}>
        <Typography sx={{fontSize: '1.1rem'}} variant={"h5"}>{title}</Typography>
        <Typography sx={{fontSize: '1.1rem'}} variant={"h6"}>({total})</Typography>
    </Box></Tooltip>;
}

function BoardList({ sx, items, itemHeight }) {
    return <Box sx={sx}>
        <AutoSizer>
            {({ width, height }) => (
                <List
                    height={height}
                    width={width}
                    itemData={items}
                    itemSize={itemHeight}
                    itemCount={items.length}
                >
                    {BoardCard}
                </List>
            )}
        </AutoSizer>
    </Box>;
}

function BoardFooter({ sx }) {
    return <Box sx={{
        position: "sticky",
        bottom: 0,
        minHeight: "2rem",
        padding: "0.5rem 0.25rem",
        textTransform: "capitalize",
        ...sx
    }}>

    </Box>;
}
export function Board({ selector, itemHeight = 64 }) {
    const dispatch = useDispatch();
    const data = useSelector(state => get(state, selector));
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [code, setCode] = useState("#FFF");
    const [items, setItems] = useState([]);
    const noticeType = useSelector(state=>state.manager.noticeType);
    const notice = useSelector(state=>state.manager.notice);
    const notify = useSelector(state=>state.manager.notify);
    useEffect(() => {
        if (data) setTitle(data.title);
        if (data) setDesc(data.desc);
        if (data) setCode(data.code);
    }, [setTitle, data]);
    useEffect(() => {
        if (data) setItems(data.items);
    }, [setItems, data]);

    const handleClose = () => {
        dispatch({ type: MANAGER_CLOSE_NOTIFICATION });
    };

    return <Paper elevation={5} sx={{
        width: "20%",
        flexGrow: 1,
        m: "0.25rem",
        p: "0.25rem",
        position: "relative",
        display: "flex",
        flexDirection: "column"
    }}>
        <BoardHeader sx={{backgroundColor: code}}
                     title={title}
                     desc={desc}
                     total={items.length}
        />
        <Divider/>
        <BoardList sx={{
            flexGrow: 1
        }} items={items} itemHeight={itemHeight}/>
        <Divider/>
        <BoardFooter sx={{}}/>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notify}
            onClose={handleClose}
            autoHideDuration={3000}
        >
            <Alert variant="filled" severity={noticeType}>
                {notice}
            </Alert>
        </Snackbar>
    </Paper>;
}