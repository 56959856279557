import {createReducer} from "@reduxjs/toolkit";
import {
    LOAD_SC_KW_LIST, LOAD_SC_KW_LIST_SUCCESS, LOAD_SC_KW_LIST_FAIL,
    SELECT_SC_KEYWORD, REMOVE_SEARCRH_SC_KEYWORD,
    LOAD_SC_LIST, LOAD_SC_LIST_SUCCESS, LOAD_SC_LIST_FAIL, DOWNLOAD_SOURCE_CONTENTS,
    DOWNLOAD_SOURCE_CONTENTS_SUCCESS,DOWNLOAD_SOURCE_CONTENTS_FAIL,PREVIEW_CLOSE_NOTIFICATION,
    SUBMIT_SC_DATA, SUBMIT_SC_DATA_SUCCESS,SUBMIT_SC_DATA_FAIL, SELECT_ALL_THUMBNAIL,
    MANAGER_SEARCH_SC_KEYWORD, MANAGER_SEARCH_SC_KEYWORD_SUCCESS, FETCH_SC_KEYWORD_FROM_SERVER,
    PREVIEW_OP_CLOSE, PREVIEW_OP_OPEN, SELECT_THUMBNAIL
} from "../actions/preview";
import {createVibes} from "@cosmos/vibes";

export const previewReducer = createReducer(
    {
        keyword: {
            id: "",
            name: ""
        },
        data: {},
        filters: {
            keyword: "",
        },
        scFilterType: -1,
        filteredIndex: [],
        sourceContent: {},
        canoniocalSCArr: [], // place to store all sc list
        SCArrDisplay: [], // place to store filtered sc list (display)
        scArr: [],  // sc list that want update status to 4
        removeSCArr: [], // sc list that want update status to -1
        loading: false,
        scLoading: false, // for page 2 (thumbnail page)
        notice: "",
        notify: false,
        noticeType: "error",
        opOpen: false,
        opOptions: [],
        opSelected: {},
        openPopper: false,
        filteredMgrKwIdx: []
    }
    ,
    builder => {
        builder.addCase(LOAD_SC_KW_LIST, (state) => {
            state.loading = true;
        }).addCase(LOAD_SC_KW_LIST_SUCCESS, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.filteredMgrKwIdx = action.payload.filteredMgrKwIdx;
        }).addCase(LOAD_SC_KW_LIST_FAIL, state => {
            state.loading = false;
        }).addCase(SELECT_SC_KEYWORD, (state, action) => {
            state.keyword.id = action.payload.id;
            state.keyword.name = action.payload.name;
        }).addCase(REMOVE_SEARCRH_SC_KEYWORD, (state, action) => {
            state.keyword = { id: "", name: ""};
            state.sourceContent = {};
            state.scArr = [];
            state.removeSCArr = [];
            state.canoniocalSCArr = [];
            state.SCArrDisplay = [];
            state.opOptions = [];
            state.filters.keyword = "";
        }).addCase(LOAD_SC_LIST, (state, action) => {
            state.loading = true;
        }).addCase(LOAD_SC_LIST_SUCCESS, (state, action) => {
            state.loading = false;
            state.sourceContent = action.payload.sourceContent; // source content that with all filtered status
            state.scArr = action.payload.scArr;
            state.canoniocalSCArr = action.payload.canoniocalSCArr;
            state.SCArrDisplay = action.payload.SCArrDisplay;
        }).addCase(LOAD_SC_LIST_FAIL, (state, action) => {
            state.loading = false;
        }).addCase(DOWNLOAD_SOURCE_CONTENTS, (state, action) => {
            state.scLoading = true;
        }).addCase(DOWNLOAD_SOURCE_CONTENTS_SUCCESS, (state, action) => {
            state.scLoading = false
            state.notify = true
            state.notice = action.payload
            state.noticeType = "success"
        }).addCase(DOWNLOAD_SOURCE_CONTENTS_FAIL, (state, action) => {
            state.scLoading = false
            state.notify = true
            state.notice = action.payload
            state.noticeType = "error"
        }).addCase(PREVIEW_CLOSE_NOTIFICATION, (state, action) => {
            state.notify = false
            state.notice = null
            state.noticeType = "error"
        }).addCase(SUBMIT_SC_DATA, (state, action) => {
            state.scLoading = true
        }).addCase(SUBMIT_SC_DATA_SUCCESS, (state, action) => {
            state.scLoading = false
            state.keyword = { id: "", name: ""};
            state.sourceContent = {};
            state.scArr = [];
            state.removeSCArr = [];
            state.canoniocalSCArr = [];
            state.SCArrDisplay = [];
            state.opOptions = [];
            state.filters.keyword = "";
        }).addCase(SUBMIT_SC_DATA_FAIL, (state, action) => {
            state.scLoading = false
        }).addCase(SELECT_ALL_THUMBNAIL, (state,action)=>{
            const allThumbnails = state.SCArrDisplay;
            state.removeSCArr = allThumbnails
            state.scArr = []
        }).addCase(MANAGER_SEARCH_SC_KEYWORD, (state,action)=>{
            state.loading = true
            state.filters.keyword = action.word
        }).addCase(MANAGER_SEARCH_SC_KEYWORD_SUCCESS, (state,action)=>{
            state.loading = false
            state.filteredMgrKwIdx = action.payload
        }).addCase(FETCH_SC_KEYWORD_FROM_SERVER, (state,action)=>{
            state.opOptions = action.payload
        }).addCase(PREVIEW_OP_OPEN, (state,action)=>{
            state.opOpen = true
        }).addCase(PREVIEW_OP_CLOSE, (state,action)=>{
            state.opOpen = false
        }).addCase(SELECT_THUMBNAIL, (state,action)=>{
            const scId = action.payload.id;
            let removeSCArr = state.removeSCArr;
            let scArr = state.scArr
            if(removeSCArr.includes(scId)) {
                removeSCArr = removeSCArr.filter(x => x !== scId);
                scArr = [
                    ...state.scArr,
                    scId
                ]
            }else {
                scArr = scArr.filter(x => x !== scId);
                removeSCArr = [
                    ...state.removeSCArr,
                    scId
                ]
            }
            state.removeSCArr = removeSCArr
            state.scArr = scArr
        })
    }
)

export const loadPreviewKWList = createVibes({
    type: LOAD_SC_KW_LIST,
    latest: true,
    processOptions: {
        successType: LOAD_SC_KW_LIST_SUCCESS,
        failType: LOAD_SC_KW_LIST_FAIL
    },
    async process({ getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;

        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query loadSCKeywords($filter: KeywordFilter) {
                    loadSCKeywords(filter: $filter) { id name baseKeyword {id base volume difficulty globalVolume cpc cps clicks}}
                }`,
                variables: {
                    filter: {
                        channel,
                    },
                }
            }
        });

        if (data && data.loadSCKeywords) {
            const res = data.loadSCKeywords;
            return {
                data: _.keyBy(res, "id"),
                filteredMgrKwIdx: res.map((keyword) => keyword.id),
            }
        } else {
            throw new Error("Fail to load keyword", data);
        }
    }
});

export const loadSCList = createVibes({
    type: LOAD_SC_LIST,
    latest: true,
    processOptions: {
        successType: LOAD_SC_LIST_SUCCESS,
        failType: LOAD_SC_LIST_FAIL
    },
    async process({ getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const keyword = state.preview.keyword;

        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query getoriginals($filter: SourceFilter) {
                    originals(filter: $filter) { id source keyword tokenRaw tokenThumb altDescription status type}
                }
                `,
                variables: {
                    filter: {
                        kwId: keyword.id,
                        status: 2,
                        channel: channel
                    }
                }
            }
        });

        if (data && data.originals) {
            const originals = data.originals;

            const previewRecords = originals.filter(x => x.status === 2); // Will show the thumbnail in preview page
            const previewArr =  _.map(previewRecords, 'id');

            return {
                sourceContent: _.keyBy(originals, "id"), // source content that with all filtered status
                scArr: previewArr,
                canoniocalSCArr: previewArr,
                SCArrDisplay: previewArr
            }
        } else {
            throw new Error("Fail to load source content", data);
        }
    }
});

export const submitSourceContentData = createVibes({
    type: SUBMIT_SC_DATA,
    async process({ getState, axios }, dispatch, done ) {
        try {
            const sourceContent = getState().preview.removeSCArr; // update status to -1
            const sources = getState().preview.scArr; // update status to 4
            const keyword = getState().preview.keyword; // keyword that need to update SCReviewAt 
            // TODO : need to check the response from server due to loading bar keep loading 
            // Expected: Return to first page 
            const { data } = await axios.request({
                method: "post",
                data: {
                    query: `mutation updateSourceContentStatus($sourceContent: [String], $sources: [String], $keywordId: Int, $keyword: String){
                        removeSourceContent(sourceContentId:$sourceContent)
                        markContentForPump (sources: $sources)
                        calInventory(keywordId: $keywordId)
                        updateKeywordSCReviewAt(keywordId: $keywordId, keyword: $keyword)
                    }
                    `,
                    variables: {
                        sourceContent,
                        sources,
                        keywordId: keyword.id,
						keyword: keyword.name
                    }
                },
            });

            const {markContentForPump } = data;

            if (markContentForPump) {
                dispatch({ type: SUBMIT_SC_DATA_SUCCESS });
                dispatch({ type: REMOVE_SEARCRH_SC_KEYWORD });
            }
        } catch (error) {
            if (error?.response) {
                dispatch({
                    type: SUBMIT_SC_DATA_FAIL,
                    payload: error.response?.data.errors[0]?.message,
                });
            } else {
                dispatch({
                    type: SUBMIT_SC_DATA_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
        done();
    },
});

export const downloadSourceContentData = createVibes({
    type: DOWNLOAD_SOURCE_CONTENTS,
    async process({ getState, axios }, dispatch, done ) {
        try {
            const keyword = getState().preview.keyword;
            const state = getState();
            const selected = state.system.selectedProject;
            const project = state.system.projects[selected];
            const channel = project.channel;

            const { data } = await axios.request({
                method: "post",
                data: {
                    query: `mutation ($channel: String, $kwId: Int) { 
                        downloadContentFiles(channel: $channel, kwId: $kwId)
                      }
                    `,
                    variables: {
                        channel,
						kwId: keyword.id
                    }
                },
            });

            const {downloadContentFiles } = data;

            if (downloadContentFiles) {
                dispatch({ type: DOWNLOAD_SOURCE_CONTENTS_SUCCESS, payload: "Success to download source content" });
            }else {
                dispatch({
                    type: DOWNLOAD_SOURCE_CONTENTS_FAIL,
                    payload: "Failed to download source content",
                });
            }
            
        } catch (error) {
            if (error?.response) {
                dispatch({
                    type: DOWNLOAD_SOURCE_CONTENTS_FAIL,
                    payload: error.response?.data.errors[0]?.message,
                });
            } else {
                dispatch({
                    type: DOWNLOAD_SOURCE_CONTENTS_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
        done();
    },
});

export const managerSearchSCKeyword = createVibes({
    type: MANAGER_SEARCH_SC_KEYWORD,
    latest: true,
    processOptions: {
        successType: MANAGER_SEARCH_SC_KEYWORD_SUCCESS,
    },
    async process({ getState, action }) {
        try {
            const managerKeywordList = getState().preview.data;
            const idx = Object.values(managerKeywordList)
                .filter((kd) => {
                    return kd.name?.startsWith(action.word);
                })
                .map((kd) => kd.id);
            return idx;
        } catch (error) {
            console.log(error);
        }
    },
});

export const fetchPreviewKeywordsFromServer = createVibes({
    type: MANAGER_SEARCH_SC_KEYWORD,
    latest: true,
    processOptions: {
        successType: FETCH_SC_KEYWORD_FROM_SERVER
    },
    async process({ getState, action, axios }) {
        const data = await axios.request({
            method: "post",
            data: {
                query: `query searchKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                        id name
                    }
                }`,
                variables: {
                    filter: {
                        search: action.word,
                    },
                },
            },
        });

        const { keywords } = data.data;
        return keywords;
    },
});