export const LOAD_USERS = "@@user/LOAD_USERS";
export const LOAD_USERS_SUCCESS = "@@user/LOAD_USERS_SUCCESS";
export const EDIT_USER_INFO = "@@user/EDIT_USER_INFO";
export const UPDATE_USER_MANAGER = "@@user/UPDATE_USER_MANAGER";
export const UPDATE_USER_PROJECTS = "@@user/UPDATE_USER_PROJECTS"
export const SAVE_USER = "@@user/SAVE_USER";
export const SAVE_USER_SUCCESS = "@@user/SAVE_USER_SUCCESS";
export const SAVE_USER_FAIL = "@@user/SAVE_USER_FAIL";
export const ADMIN_RESET = "@@user/RESET_FORM";

export const ADMIN_DASH_LOAD = '@@admin/DASH_LOAD';
export const ADMIN_DASH_LOADED = '@@admin/DASH_LOADED';
export const ADMIN_DASH_FAIL = '@@admin/DASH_FAIL';

export const SELECT_USER_INFO = "@@user/SELECT_USER_INFO";
export const SELECT_USER_INFO_SUCCESS = "@@user/SELECT_USER_INFO_SUCCESS";
export const UPDATE_USER = "@@user/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "@@user/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "@@user/UPDATE_USER_FAIL";