export const REL_KW_SEARCH_KEYWORDS = '@@related/REL_KW_SEARCH_KEYWORDS';
export const REL_KW_SEARCH_KEYWORDS_SUCCESS = '@@related/REL_KW_SEARCH_KEYWORDS_SUCCESS';
export const REL_KW_SEARCH_KEYWORDS_FAIL = '@@related/REL_KW_SEARCH_KEYWORDS_FAIL';

export const REL_KW_SELECT_KW = '@@related/REL_KW_SELECT_KW';

export const REL_KW_SEARCH_REL_KEYWORDS = '@@related/REL_KW_SEARCH_REL_KEYWORDS';
export const REL_KW_SEARCH_REL_KEYWORDS_SUCCESS = '@@related/REL_KW_SEARCH_REL_KEYWORDS_SUCCESS';
export const REL_KW_SEARCH_REL_KEYWORDS_FAIL = '@@related/REL_KW_SEARCH_REL_KEYWORDS_FAIL';

export const REL_KW_ADD = '@@related/REL_KW_ADD';
export const REL_KW_ADD_SUCCESS = '@@related/REL_KW_ADD_SUCCESS';
export const REL_KW_ADD_FAIL = '@@related/REL_KW_ADD_FAIL';

export const REL_KW_REMOVE = '@@related/REL_KW_REMOVE';
export const REL_KW_REMOVE_SUCCESS = '@@related/REL_KW_REMOVE_SUCCESS';
export const REL_KW_REMOVE_FAIL = '@@related/REL_KW_REMOVE_FAIL';

export const CLOSE_REL_KW_NOTIFICATION = '@@related/CLOSE_REL_KW_NOTIFICATION';
export const CLEAR_REL_KW_DATA = '@@related/CLEAR_REL_KW_DATA';

export const REL_KW_CREATE_NEW_KEYWORD = '@@related/REL_KW_CREATE_NEW_KEYWORD';
export const REL_KW_CREATE_NEW_KEYWORD_SUCCESS = '@@related/REL_KW_CREATE_NEW_KEYWORD_SUCCESS';
export const REL_KW_CREATE_NEW_KEYWORD_FAIL = '@@related/REL_KW_CREATE_NEW_KEYWORD_FAIL';

export const UPDATE_REL_KW_DIALOG_FLAG = '@@related/UPDATE_REL_KW_DIALOG_FLAG';
