export const TRANSLATION_SEARCH_KEYWORDS = '@@translation/TRANSLATION_SEARCH_KEYWORDS';
export const TRANSLATION_SEARCH_KEYWORDS_SUCCESS = '@@translation/TRANSLATION_SEARCH_KEYWORDS_SUCCESS';
export const TRANSLATION_SEARCH_KEYWORDS_FAIL = '@@translation/TRANSLATION_SEARCH_KEYWORDS_FAIL';

export const TRANSLATION_LOAD_KEYWORD_DETAILS = '@@translation/TRANSLATION_LOAD_KEYWORD_DETAILS';
export const TRANSLATION_LOAD_KEYWORD_DETAILS_SUCCESS = '@@translation/TRANSLATION_LOAD_KEYWORD_DETAILS_SUCCESS';
export const TRANSLATION_LOAD_KEYWORD_DETAILS_FAIL = '@@translation/TRANSLATION_LOAD_KEYWORD_DETAILS_FAIL';

export const CREATE_TRANSLATION_CLOSE_NOTIFICATION = "@@translation/CREATE_TRANSLATION_CLOSE_NOTIFICATION";

export const CLEAR_TRANSLATION_LIST = "@@translation/CLEAR_TRANSLATION_LIST";
export const SELECT_TRANSLATION_KEYWORD_DETAIL = "@@translation/SELECT_TRANSLATION_KEYWORD_DETAIL";

export const EDIT_TRANSLATION_INPUT_VALUE = "@@translation/EDIT_TRANSLATION_INPUT_VALUE";

export const SUBMIT_KD_TRANSLATION_EDIT = "@@translation/SUBMIT_KD_TRANSLATION_EDIT";
export const SUBMIT_KD_TRANSLATION_EDIT_SUCCESS = "@@translation/SUBMIT_KD_TRANSLATION_EDIT_SUCCESS";
export const SUBMIT_KD_TRANSLATION_EDIT_FAIL = "@@translation/SUBMIT_KD_TRANSLATION_EDIT_FAIL";

export const CLEAR_SELECT_TRANSLATION = "@@translation/CLEAR_SELECT_TRANSLATION";

export const SUBMIT_KD_TRANSLATION_CREATE = "@@translation/SUBMIT_KD_TRANSLATION_CREATE";
export const SUBMIT_KD_TRANSLATION_CREATE_SUCCESS = "@@translation/SUBMIT_KD_TRANSLATION_CREATE_SUCCESS";
export const SUBMIT_KD_TRANSLATION_CREATE_FAIL = "@@translation/SUBMIT_KD_TRANSLATION_CREATE_FAIL";