export const MANAGER_DASH_LOAD = '@@manager/MANAGER_DASH_LOAD0';
export const MANAGER_DASH_LOADED = '@@manager/MANAGER_DASH_LOAD0ED';

export const MANAGER_DASH_LOAD_KEYWORD_DETAIL = '@@manager/MANAGER_DASH_LOAD_KEYWORD_DETAIL';
export const MANAGER_DASH_LOAD_KEYWORD_DETAIL_SUCCESS = '@@manager/MANAGER_DASH_LOAD_KEYWORD_DETAIL_SUCCESS';
export const MANAGER_DASH_LOAD_KEYWORD_DETAIL_FAIL = '@@manager/MANAGER_DASH_LOAD_KEYWORD_DETAIL_FAIL';

export const ASSIGN_KEYWORD_TO_USER = '@@manager/ASSIGN_KEYWORD_TO_USER';
export const ASSIGN_KEYWORD_TO_USER_SUCCESS = '@@manager/ASSIGN_KEYWORD_TO_USER_SUCCESS';
export const ASSIGN_KEYWORD_TO_USER_FAIL = '@@manager/ASSIGN_KEYWORD_TO_USER_FAIL';

export const UNASSIGN_KEYWORD_TO_USER = '@@manager/UNASSIGN_KEYWORD_TO_USER';
export const UNASSIGN_KEYWORD_TO_USER_SUCCESS = '@@manager/UNASSIGN_KEYWORD_TO_USER_SUCCESS';
export const UNASSIGN_KEYWORD_TO_USER_FAIL = '@@manager/UNASSIGN_KEYWORD_TO_USER_FAIL';

export const MANAGER_CLOSE_NOTIFICATION = '@@manager/MANAGER_CLOSE_NOTIFICATION';


export const MANAGER_SEARCH_KEYWORDS = '@@manager/MANAGER_SEARCH_KEYWORDS';
export const MANAGER_SEARCH_KEYWORDS_SUCCESS = '@@manager/MANAGER_SEARCH_KEYWORDS_SUCCESS';
export const MANAGER_SEARCH_KEYWORDS_FAIL = '@@manager/MANAGER_SEARCH_KEYWORDS_FAIL';

export const SET_MANAGER_SEARCHED_KD = '@@manager/SET_MANAGER_SEARCHED_KD';

export const MANAGER_CREATE_KD = '@@manager/MANAGER_CREATE_KD';
export const MANAGER_CREATE_KD_SUCCESS = '@@manager/MANAGER_CREATE_KD_SUCCESS';
export const MANAGER_CREATE_KD_FAIL = '@@manager/MANAGER_CREATE_KD_FAIL';

export const UPDATE_MANAGER_NEW_KW_DIALOG = '@@manager/UPDATE_MANAGER_NEW_KW_DIALOG';
export const UPDATE_MANAGER_ASSIGN_DIALOG = '@@manager/UPDATE_MANAGER_ASSIGN_DIALOG';

export const MANAGER_CHECK_KD_EXIST = '@@manager/MANAGER_CHECK_KD_EXIST';
export const MANAGER_CHECK_KD_EXIST_SUCCESS = '@@manager/MANAGER_CHECK_KD_EXIST_SUCCESS';
export const MANAGER_CHECK_KD_EXIST_FAIL = '@@manager/MANAGER_CHECK_KD_EXIST_FAIL';