export const LOAD_SC_KW_LIST = "@@preview/LOAD_SC_KW_LIST";
export const LOAD_SC_KW_LIST_SUCCESS = "@@preview/LOAD_SC_KW_LIST_SUCCESS";
export const LOAD_SC_KW_LIST_FAIL = "@@preview/LOAD_SC_KW_LIST_FAIL";

export const SELECT_SC_KEYWORD = "@@preview/SELECT_SC_KEYWORD";
export const REMOVE_SEARCRH_SC_KEYWORD = "@@preview/REMOVE_SEARCRH_SC_KEYWORD";

export const LOAD_SC_LIST = "@@preview/LOAD_SC_LIST";
export const LOAD_SC_LIST_SUCCESS = "@@preview/LOAD_SC_LIST_SUCCESS";
export const LOAD_SC_LIST_FAIL = "@@preview/LOAD_SC_LIST_FAIL";

export const SUBMIT_SC_DATA = "@@preview/SUBMIT_SC_DATA";
export const SUBMIT_SC_DATA_SUCCESS = "@@preview/SUBMIT_SC_DATA_SUCCESS";
export const SUBMIT_SC_DATA_FAIL = "@@preview/SUBMIT_SC_DATA_FAIL";
export const PREVIEW_CLOSE_NOTIFICATION = "@@preview/PREVIEW_CLOSE_NOTIFICATION";

export const DOWNLOAD_SOURCE_CONTENTS = "@@preview/DOWNLOAD_SOURCE_CONTENTS";
export const DOWNLOAD_SOURCE_CONTENTS_SUCCESS = "@@preview/DOWNLOAD_SOURCE_CONTENTS_SUCCESS";
export const DOWNLOAD_SOURCE_CONTENTS_FAIL = "@@preview/DOWNLOAD_SOURCE_CONTENTS_FAIL";

export const SELECT_THUMBNAIL = "@@preview/SELECT_THUMBNAIL";
export const REMOVE_THUMBNAIL = "@@preview/REMOVE_THUMBNAIL";
export const SELECT_ALL_THUMBNAIL = "@@preview/SELECT_ALL_THUMBNAIL";

export const MANAGER_SEARCH_SC_KEYWORD = "@@preview/MANAGER_SEARCH_SC_KEYWORD";
export const MANAGER_SEARCH_SC_KEYWORD_SUCCESS = "@@preview/MANAGER_SEARCH_SC_KEYWORD_SUCCESS";
export const FETCH_SC_KEYWORD_FROM_SERVER = "@@preview/FETCH_SC_KEYWORD_FROM_SERVER";

export const PREVIEW_OP_OPEN = "@@preview/PREVIEW_OP_OPEN";
export const PREVIEW_OP_CLOSE = "@@preview/PREVIEW_OP_CLOSE";