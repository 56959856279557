import { useDispatch, useSelector } from "react-redux";
import { 
    Box, Card, LinearProgress, Paper, Toolbar, Typography, Autocomplete, TextField,
    CircularProgress, 
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    KW_RLS_SEARCH_KEYWORDS, KW_RLS_LOAD_KW,
    CLEAR_KW_RLS_DATA
} from "../../actions/keywordrelationship";
import SearchIcon from '@mui/icons-material/Search';
import { createFilterOptions } from '@mui/material/Autocomplete';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

export function KeywordRelationshipPage() {
    const dispatch = useDispatch();
    const keywordsOpt = useSelector(state=>state.keywordRelationship.keywordsOpt);
    const loading = useSelector(state=>state.keywordRelationship.loading);
    const opLoading = useSelector(state=>state.keywordRelationship.opLoading);
    const keyword = useSelector(state=>state.keywordRelationship.keyword);
    const upStreamKwArr = useSelector(state=>state.keywordRelationship.upStreamKwArr);
    const downStreamKwArr = useSelector(state=>state.keywordRelationship.downStreamKwArr);

    const [searchTextValue, setSearchTextValue] = useState('')

    useEffect(() => {
        dispatch({type: KW_RLS_SEARCH_KEYWORDS});
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: CLEAR_KW_RLS_DATA });
    }, []);

    function renderCell({ style, index, data }) {

        const handleSelect = (event)=>{
            event.preventDefault();
            setSearchTextValue('');
            // Related keyword can be clicked, and check the next keyword's related
            // Suggestion keyword can be view only
            if(index >= 0 && data && data[index].related) {
                const item = data[index];
                const value = {id: item.id, name: item.name}
                dispatch({type: KW_RLS_LOAD_KW, payload: value})
            }
        }
        return <Card 
            onClick={handleSelect}
            style={style} sx={{ height: "40px", padding: "3px", 
                display: "flex", alignItems: "center",
                ...(data[index].related === true && {
                    '&:hover': { cursor: "pointer"},
                    background: "rgba(0, 0, 0, 0.1)"
                }),
                ...(!(data[index].related) && {
                    background: "rgba(25, 135, 84, 0.4)",
                }),
            }}>
            <Box>
                {/* <Typography sx={{paddingLeft: "5px"}}>{index + 1 }. {data[index].name}</Typography> */}
                <Typography sx={{paddingLeft: "5px"}}>{data[index].name}</Typography>
            </Box>
        </Card>
    }
    
    return <Paper sx={{flexGrow: 1, display: "flex"}}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between"}}
                     variant="dense">
                <Typography variant="h5">Keyword Relationship</Typography>
                <Box sx={{width: "300px", display: "flex", alignItems: "center"}}>
                    <SearchIcon/>
                    <Autocomplete
                        freeSolo
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        fullWidth
                        onInputChange={(e, v, r) => {
                            if(e) {
                                dispatch({type: KW_RLS_SEARCH_KEYWORDS, search: v});
                            }
                        }}
                        onChange={(e, value)=>{
                            e.preventDefault();
                            if(value) {
                                setSearchTextValue(value)
                                dispatch({type: KW_RLS_LOAD_KW, payload: value})
                            }
                        }}
                        // disableClearable
                        options={keywordsOpt || []}
                        value={searchTextValue}
                        getOptionLabel={(option) => option?.name || ""}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                placeholder="Search keyword by name"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment:(
                                        <>
                                            {opLoading ? (<CircularProgress size={20}/>) : null}
                                            {
                                                params.InputProps
                                                .endAdornment
                                            }
                                        </>
                                    )
                                }}
                            />
                        }
                    />
                </Box>
                <Box>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{flex: 1, display: "flex", padding: "15px"}}>
                <Box sx={{flex: 1}}>
                    <Box>
                        <Box sx={{display: "flex", flex: 1, paddingBottom: "15px"}}>
                            <Typography sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "15px"}} >Keyword ID:</Typography>
                            {keyword.id ? keyword.id : "-"}
                        </Box>
                        <Box  sx={{display: "flex", flex: 1, paddingBottom: "15px"}}>
                            <Typography sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "15px"}} >Keyword Name:</Typography>
                            {keyword.name ? keyword.name : "-"}
                        </Box>
                    </Box>
                    <Box sx={{display: "flex", height: "95%", padding: "10px 20px 0 20px"}}>
                        <Box sx={{flex: 1, marginRight: "15px"}}>
                            <Typography sx={{fontSize: "16px"}} >Up Stream Keywords: </Typography>
                            <Box sx={{height: "94%", padding: "10px"}}>
                                {upStreamKwArr.length > 0 ? 
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <FixedSizeList
                                                width={width}
                                                height={height - 50}
                                                itemSize={40}
                                                itemData={upStreamKwArr}
                                                itemCount={upStreamKwArr.length}
                                            >
                                                {renderCell}
                                            </FixedSizeList>
                                        )}
                                    </AutoSizer> : "-"
                                }
                            </Box>
                        </Box>
                        <Box sx={{flex: 1, leftRight: "15px"}}>
                            <Typography sx={{fontSize: "16px"}} >Down Stream Keywords: </Typography>
                            <Box sx={{height: "94%", padding: "10px"}}>
                                {downStreamKwArr.length > 0 ? 
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <FixedSizeList
                                                width={width}
                                                height={height - 50}
                                                itemSize={40}
                                                itemData={downStreamKwArr}
                                                itemCount={downStreamKwArr.length}
                                            >
                                                {renderCell}
                                            </FixedSizeList>
                                        )}
                                    </AutoSizer> : "-"
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Paper>
}