import {Paper, Box, Typography, Button, List, ListItem, ListItemText, InputLabel, Autocomplete, TextField, Chip} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    EDIT_LISTING_VALUE,
    SUBMIT_LISTING,
    UPDATE_LISTING_STATUS
} from "../../actions/listing";
import moment from "moment";
import { Authorization } from "@cosmos/runes/esm";
import {
    LOAD_KEYWORDS_LIST
} from "../../actions/content";

export function ListingEditor() {
    const dispatch = useDispatch();
    const listingDetail = useSelector((state) => state.listing.listingDetail);
    const loading = useSelector((state) => state.listing.loading);
    const projects = useSelector((state) => state.system.projects);
    const selectedProject = useSelector((state) => state.system.selectedProject);
    const bases = useSelector((state) => state.system.bases);
    const keywords = useSelector(state=>state.editor.keywords);
    const [baseArr, setBaseArr] = useState([]);
    const [keywordsOption, setKeywordsOption] = useState([]);

    useEffect(() => {
        if(bases && Object.keys(bases).length > 0) {
            setBaseArr(Object.values(bases))
        }
    }, [bases]);
    useEffect(() => {
        dispatch({type: LOAD_KEYWORDS_LIST});
    }, [dispatch]);

    useEffect(()=>{
        // filter out data not equal to primary data
        const keyArr = Object.values(keywords);
        setKeywordsOption(keyArr);
    }, [setKeywordsOption, keywords]);

    const handleEditSave = (e) => {
        dispatch({ type: SUBMIT_LISTING});
    };

    return <Paper>
        <Box sx={{display: 'flex', flex: 1, padding: "20px", marginBottom:"15px", alignItems: "center"}}>
            <Typography sx={{display: 'flex', flex: 1, justifyContent: "flex-start"}} variant="h4">Listing Details
                {listingDetail && listingDetail.id ? ": " + listingDetail.id : ""}
            </Typography>
            <Authorization allowed={["pixel.content.approval"]} failback={<></>}>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{marginRight: "10px"}}
                    disabled={!listingDetail.id || listingDetail.status !== "NEW"}
                    onClick={()=> dispatch({ type: UPDATE_LISTING_STATUS, payload: {id: listingDetail.id, status:0, base: listingDetail.base}})}
                >
                    Approve
                </Button>
            </Authorization>
            <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={!listingDetail.type || loading}
                onClick={handleEditSave}
            >
                SAVE
            </Button>
        </Box>
        <List>
            {/* type , channel */}
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}} >Type:</InputLabel>
                    <Autocomplete
                        sx={{display: "flex", flex: 1, marginRight: "20px", "& .MuiOutlinedInput-root": {paddingTop: "0px", paddingBottom: "0px"}}}
                        id="type-box"
                        options={["New", "Top", "Trend","Pop"]}
                        disabled = {listingDetail.id}
                        onChange={(event, value) => {
                            if (value) {
                                dispatch({ type: EDIT_LISTING_VALUE, payload: {type: value}});
                            }
                        }}
                        value={listingDetail.type}
                        renderInput={(params) => <TextField {...params} />}
                    />
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}}>Channel: </InputLabel>
                <ListItemText sx={{display: "flex", flex: 1, marginRight: "20px"}} fullWidth>
                    {projects[selectedProject]?.name}
                </ListItemText>
            </ListItem>
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}} >Base:</InputLabel>
                    <Autocomplete
                        sx={{display: "flex", flex: 1, marginRight: "20px", "& .MuiOutlinedInput-root": {paddingTop: "0px", paddingBottom: "0px"}}}
                        id="type-box-base"
                        options={baseArr}
                        getOptionLabel={base => base.name}
                        disabled = {listingDetail.id}
                        onChange={(event, value) => {
                            if (value) {
                                dispatch({ type: EDIT_LISTING_VALUE, payload: {baseData: value, base: value.id}});
                            }
                        }}
                        value={listingDetail?.baseData}
                        renderInput={(params) => <TextField {...params} />}
                    />
            </ListItem>
            {/* validFrom (DatePicker) */}
            <ListItem sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}}>Valid From:</InputLabel>
                <Box sx={{display: "flex", flex: 1, marginRight: "20px"}}>
                    <TextField
                        inputProps={{
                            style: {padding: 9}
                        }}
                        id="date"
                        type="date"
                        fullWidth 
                        value={moment(listingDetail.validFrom).format("YYYY-MM-DD")}
                        onChange={
                            (event)=>{
                                if(event){
                                    // default add 14 days when user select a date 
                                    const validTo = moment(event.target.value).add(14, 'days').format("YYYY-MM-DD")
                                    dispatch({ type: EDIT_LISTING_VALUE, payload: {validFrom: event.target.value, validTo}});
                                }
                            }
                        }
                    />                           
                </Box>   
            </ListItem>
            {/* validTo (DatePicker)*/}
            <ListItem sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}}>Valid To:</InputLabel>
                <Box sx={{display: "flex", flex: 1, marginRight: "20px"}}>
                    <TextField
                        inputProps={{
                            style: {padding: 9}
                        }}
                        id="date"
                        type="date"
                        fullWidth
                        disabled={true}
                        value={moment(listingDetail.validTo).format("YYYY-MM-DD")}
                    />                           
                </Box>   
            </ListItem>
            <ListItem sx={{display: "flex", alignItems: "flex-start"}}>
                <InputLabel sx={{display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px", paddingTop: "6px"}}>Keyword:</InputLabel>               
                <Box sx={{display: "flex", flex: 1, marginRight: "20px", flexDirection: "column"}}>
                    <Autocomplete
                        fullWidth
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        onInputChange={(e, v, r) => {   
                            if(e) {
                                dispatch({ type: LOAD_KEYWORDS_LIST, search: v });
                            }
                        }}
                        onChange={(e, v, r) => {
                            if(r === "selectOption") {
                                const val = v[v.length - 1].id;
                                const name = v[v.length - 1].name;
                                if(listingDetail.items.indexOf(val)  === -1){
                                    const nameArr = listingDetail && listingDetail.keywords
                                            ? [...listingDetail.keywords, {id:val , name:name}]
                                            : [{id:val , name:name}];
                                    const arr =
                                        listingDetail && listingDetail.items
                                                ? [...listingDetail.items, val]
                                                : [val];
                                    dispatch({ type: EDIT_LISTING_VALUE, payload: {items: arr, keywords: nameArr}});
                                }
                            } else if(r === "removeOption") {
                                const difference = listingDetail.keywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                const removeId = difference[0].id;
                                const oriItemsArr = listingDetail && listingDetail.items ? listingDetail.items : [];
                                const oriKeywordsArr = listingDetail && listingDetail.keywords ? listingDetail.keywords : [];
                                const newItemsArr = oriItemsArr.filter(x => x !== removeId);
                                const newKeywordsArr = oriKeywordsArr.filter(x => x.id !== removeId);

                                dispatch({ type: EDIT_LISTING_VALUE, payload: {items: newItemsArr, keywords: newKeywordsArr}});
                            }
                        }}
                        multiple
                        id="add-listing"
                        options={keywordsOption || []}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        value={listingDetail?.keywords || []}
                        renderTags={() => { }}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                variant="outlined"
                                placeholder="Search For Keywords"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <Box>
                                            {
                                                params.InputProps
                                                    .endAdornment
                                            }
                                        </Box>
                                    )
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        onChange={(e, v, r) => {
                            if(r === "removeOption") {
                                const difference = listingDetail.keywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                const removeId = difference[0].id;
                                const oriItemsArr = listingDetail && listingDetail.items ? listingDetail.items : [];
                                const oriKeywordsArr = listingDetail && listingDetail.keywords ? listingDetail.keywords : [];
                                const newItemsArr = oriItemsArr.filter(x => x !== removeId);
                                const newKeywordsArr = oriKeywordsArr.filter(x => x.id !== removeId);

                                dispatch({ type: EDIT_LISTING_VALUE, payload: {items: newItemsArr, keywords: newKeywordsArr}});
                            }
                        }}
                        multiple
                        id="linked-keywords"
                        options={keywordsOption}
                        getOptionLabel={(option) => option?.id}
                        getOptionSelected={(option, value) => option?.id === value.id}
                        value={listingDetail?.keywords || []}
                        renderInput={(params) => (
                            <TextField
                                sx={{height: "150px", overflowY: "auto"}}
                                fullWidth
                                {...params}
                                variant="outlined"
                                placeholder="Keywords"
                                inputProps={{
                                    ...params.inputProps,
                                    style: { display: 'none' }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null
                                }}
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                return (
                                    <Chip
                                        size="small"
                                        label={option?.name}
                                        {...getTagProps({ index })}
                                    />
                                )
                            })
                        }
                    />
                </Box> 
            </ListItem>
        </List>
    </Paper>
}