import {Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { DataTable } from '@cosmos/runes';
import {
    SELECT_TRANSLATION_KEYWORD_DETAIL,
} from "../../actions/keywordtranslation";
import moment from "moment";

export function TranslationTable() {
    const dispatch = useDispatch();
    const selectedProject = useSelector(state => state.system.selectedProject);
    const projects = useSelector(state => state.system.projects);
    const bases = useSelector(state => state.system.bases);
    const translation = useSelector((state) => state.translation.data);
    // const selectedListing = useSelector((state) => state.listing.listingDetail);
    // const index = useSelector(state => state.listing.index);
    // const filters = useSelector((state) => state.listing.filters);

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [channelData, setChannelData] = useState({});

    const [channel, setChannel] = useState(null);

    useEffect(() => {
        setChannel(projects[selectedProject]?.channel);
    }, [setChannel, projects, selectedProject]);

    useEffect(() => {        
        setData(translation);
    }, [translation]);

    const handleKeywordDetailSelect = (rowData) => {
        dispatch({ type: SELECT_TRANSLATION_KEYWORD_DETAIL , payload: rowData?.rowData })
    }

    useEffect(() => {
        const columns = [
            // {
            //     title: "Id",
            //     name: "id",
            //     sortBy: "id",
            //     width: "8%"
            // },
            {
                title: "Name",
                name: "name",
                sortBy: "name",
            },
            {
                title: "Channel",
                name: "channelName",
                sortBy: "channelName",

            },
            {
                title: "Language",
                name: "lang",
                sortBy: "lang",
            },
            {
                title: "Base",
                name: "base",
                sortBy: "base",
                width: "15%",
                cell: ({ rowData }) => {
                    let baseName = "-";
                    if (rowData && rowData?.baseData && rowData?.baseData?.name) {
                        baseName = rowData?.baseData?.name;
                    }
                    return baseName;
                },
            },
            // {
            //     title: "Level",
            //     name: "langLevel",
            //     sortBy: "langLevel",
            //     width: "25%",
            //     cell: ({ rowData }) => {
            //         let level = "-";
            //         if (rowData) {
            //             const langLevel = rowData.langLevel;
            //             if(langLevel === 0) {
            //                 level = "Copy";
            //             }else if(langLevel === 1) {
            //                 level = "Available for translation"
            //             }else if(langLevel === 2) {
            //                 level = "In Progress";
            //             }else if(langLevel === 10) {
            //                 level = "Complete";
            //             }
            //         }
            //         return level;
            //     },
            // }
        ];
        setColumns(columns);
    }, [setColumns , channel]);

    return <Box sx={{ height: "100%" }}>
        <AutoSizer>
            {({ height, width }) => (
                <DataTable
                    width={width}
                    height={height}
                    data={data}
                    columns={columns}
                    size={"exSmall"}
                    rowOnClick={handleKeywordDetailSelect}
                    rowHeight={25}
                />
            )}
        </AutoSizer>
    </Box>
}