import { createReducer } from "@reduxjs/toolkit";
import { APP_STATE_INIT, APP_STATE_READY, SET_PROJECT, LOAD_PROJECTS, LOAD_PROJECTS_SUCCESS, LOAD_PROJECTS_FAIL } from "../actions/system";
import { TOKEN_KEY, authenticator, AUTH_METHOD_REFRESH, AUTH_ATTEMPT_SUCCESS } from "@cosmos/runes";
import jwtDecode from "jwt-decode";
import { createVibes } from "@cosmos/vibes";
import { catchError, lastValueFrom, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import _  from "lodash";

export const systemReducer = createReducer(
    {
        level: 10,
        message: null,
        // projects: {
        //     1: { id: 1, code: 'WPS', name: "wallpapers.com", channel: "100001", type: "wallpaper", permission: 12131, mode: 1 },
        //     2: { id: 2, code: 'MRW', name: "mrwallpaper.com", channel: "120001", type: "wallpaper",permission: 12132, mode: 1 },
        //     3: { id: 3, code: 'GDB', name: "gifdb.com", channel: "200001", type: "gif", permission: 12133, mode: 1 },
        //     4: { id: 4, code: 'WPG', name: "wallpapergod.com", channel: "130001", type: "wallpaper", permission: 12134, mode: -1 }
        // },
        projects: {},
        selectedProject: -1,
        bases: {}
    },
    builder => {
        builder.addCase(APP_STATE_INIT, (state) => {
            state.level = 0;
            state.message = "Initializing System ...";
        }).addCase(APP_STATE_READY, (state) => {
            state.level = 10;
            state.message = "System Ready!!";
        }).addCase(SET_PROJECT, (state, action) => {
            state.selectedProject = action.payload.projectId;
        }).addCase(LOAD_PROJECTS_SUCCESS, (state, action) => {
            state.projects = action.payload.projects;
            state.selectedProject = action.payload.selectedProject;
            state.bases = action.payload.bases;
        });
    });

export const systemInitVibes = createVibes({
    type: APP_STATE_INIT,
    async process({}, dispatch, done) {
        const key = localStorage.getItem(TOKEN_KEY);
        if (key) {
            try {
                const token = await lastValueFrom(authenticator(AUTH_METHOD_REFRESH, key));
                if (token) {
                    localStorage.setItem(TOKEN_KEY, token);
                    dispatch({ type: AUTH_ATTEMPT_SUCCESS, payload: { token, user: jwtDecode(token) } });
                }
            } catch (e) {
                localStorage.removeItem(TOKEN_KEY);
            }
        }
        dispatch({ type: APP_STATE_READY });
        done();
    }
});

export const loadProjects = createVibes({
    type: LOAD_PROJECTS,
    latest: true,
    processOptions: {
        successType: LOAD_PROJECTS_SUCCESS,
        failType: LOAD_PROJECTS_FAIL
    },
    async process({ axios }) {
        try {
            const { data: result } = await axios.request({
                method: "post",
                data: {
                    query: `query {
                        projects {
                          id
                          name
                          code
                          mode
                          permission
                          channel {
                            id
                            name
                            projectId
                            lang
                            primary
                          }
                        }
                        bases {
                            id 
                            name
                        }
                      }`
                }
            });

            const { projects, bases } = result;

            let projectArr = [];
            if (projects && projects.length > 0) {
                projects.map(project => {
                    if(project && project?.channel && project?.channel.length >0) {
                        // sort channel by channelId
                        const channels = project.channel.slice().sort((a, b) => parseInt(a.id) - parseInt(b.id));
                        channels.map(channel => {
                            projectArr.push({
                                ...project,
                                channel: channel.id,
                                lang: channel.lang,
                                name: channel.name,
                                projectId: channel.projectId,
                                type: channel.id !== "200001" ? "wallpaper" : "gif",
                                primary: channel.primary
                            })
                        })
                    }
                })
            }

            if (projectArr.length > 0) {
                return {
                    projects: _.keyBy(projectArr, "channel"),
                    // selectedProject: projectArr[0].id,
                    selectedProject: projectArr[0].channel,
                    bases: _.keyBy(bases, "id"),
                }
            } else {
                throw new Error("Fail to load projects");
            }

            // if (projects && projects.length > 0) {
            //     const res = projects.map(project => {
            //         project.code = project.code.toUpperCase();
            //         project.lang = project.channel[0].lang;
            //         const channel = project.channel[0].id;
            //         project.channel = channel
            //         project.type = channel !== "200001" ? "wallpaper" : "gif";
            //         return project;
            //     });

            //     return {
            //         projects: _.keyBy(res, "id"),
            //         selectedProject: res[0].id,
            //         bases: _.keyBy(bases, "id"),
            //     }
            // } else {
            //     throw new Error("Fail to load projects");
            // }
        } catch (err) {
            throw err;
        }
    }
});