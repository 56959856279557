import { Box, Paper, Button, TextField, Snackbar, Alert} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RELOAD_KEYWORD_CHECKER, EDIT_CHECKER_INPUT_VALUE, SUBMIT_KEYWORD_CHECKER,
LOAD_BASE_KEYWORDS, CLOSE_CHECKER_NOTIFICATION} from "../../actions/checker";
import {CSVLink} from 'react-csv';
import AutoSizer from "react-virtualized-auto-sizer";
import { DataTable } from "@cosmos/runes";

export function Checker() {
    const dispatch = useDispatch();
    const searchResult = useSelector((state) => state.checker.searchResult);
    const [exportData, setExportData] = useState([]);
    const text = useSelector((state) => state.checker.text);
    const notify  = useSelector((state) => state.checker.notify);
    const notice  = useSelector((state) => state.checker.notice);
    const noticeType = useSelector((state) =>  state.checker.noticeType);
    const [msgType, setMsgType] = useState(noticeType);
    const [columns, setColumns] = useState([]);

    function handleClose() {
        dispatch({ type:CLOSE_CHECKER_NOTIFICATION }) 
    }

    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);
    
    useEffect(()=>{
        dispatch({type: LOAD_BASE_KEYWORDS});
    },[dispatch])

    useEffect(() => {
        const columns = [
            {
                title: "original",
                name: "query",
                sortBy: "query",
                width: "25%",
                cell: ({ rowData }) => <div>{rowData.query}</div>
            },
            {
                title: "Keyword Name",
                name: "name",
                sortBy: "name",
                width: "25%",
                cell: ({ rowData }) => <div>{rowData.keyword?.name}</div>
            },
            {
                // match status: 100%, partially, not match
                title: "Volume",
                name: "volume",
                width: "15%",
                cell: ({ rowData }) => <div>{rowData.volume}</div>
            },
            {
                // match status: 100%, partially, not match
                title: "Potential",
                name: "potential",
                width: "15%",
                cell: ({ rowData }) => <div>{rowData.potential}</div>
            },
            {
                // match status: 100%, partially, not match
                title: "Difficulty",
                name: "difficulty",
                width: "15%",
                cell: ({ rowData }) => <div>{rowData.difficulty}</div>
            },
            {
                // total num of content
                title: "No. of Content",
                name: "inventory",
                sortBy: "role",
                width: "25%",
                cell: ({ rowData }) => <div>{rowData.keyword?.totalInventory}</div>
            },
            {
                // cd.lived
                title: "No. of Live Content",
                name: "live content",
                sortBy: "role",
                width: "28%",
                cell: ({ rowData }) => <div>{rowData.keyword?.totalLived}</div>
            },
            {
                title: "Background Base",
                sortBy: "role",
                width: "28%",
                cell: ({ rowData }) => <div>{rowData?.backgroundKw?.name}</div>
            },
            {
                title: "Picture Base",
                sortBy: "role",
                width: "28%",
                cell: ({ rowData }) => <div>{rowData?.pictureKw?.name}</div>
            }
        ];
        setColumns(columns);
    }, [setColumns]);

    const handleInputChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        dispatch({ type: EDIT_CHECKER_INPUT_VALUE, payload: value });
    };

    const handleCheck = (e) => {
        e.preventDefault();
        dispatch({ type: SUBMIT_KEYWORD_CHECKER });
    };

    useEffect(()=>{
        if(searchResult.length > 0) {
            const data = searchResult.map(r=>({
                Original: r.query,
                Keyword: r.keyword && r.keyword.name,
                "Volume": r.volume,
                "Potential": r.potential,
                "Difficulty": r.difficulty,
                "No. of Content": r.keyword && r.keyword.totalInventory,
                "No. of Live Content": r.keyword && r.keyword.totalLived,
                "Background Base": r?.backgroundKw?.name,
                "Picture Base": r?.pictureKw?.name
            }))
            setExportData(data)
        }
    }, [searchResult, setExportData])

    const handleReload = (e) => {
        e.preventDefault();
        dispatch({ type: RELOAD_KEYWORD_CHECKER });
    };

    return (
        <Paper sx={{
            flex: 1,
            padding: "0.5em",
            display: "flex",
            flexDirection: "column"}}>
            <Box sx={{display:"flex", bgcolor:"rgb(100, 149, 237)", height:"70px", alignItems:"center"}}>
                <Box sx={{width:"50%", fontSize:"20px", marginLeft:"10px"}}>
                Keyword Checker
                </Box>
                <Box sx={{width:"50%",justifyContent:"flex-end", display:"flex", marginRight:"10px"}}>
                {searchResult.length > 0 &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReload}
                        >
                            Back
                        </Button>
                    }
                        {exportData.length > 0 &&
                            <CSVLink style={{textDecoration:"none"}} data={exportData}> 
                              <Button variant="contained" color="primary">
                                Export
                              </Button>
                            </CSVLink>
                        }
                </Box>
                
            </Box>
            <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={notify}
                    onClose={handleClose}
                    autoHideDuration={4000}
                >
                    <Alert variant="filled" severity={msgType}>
                        {notice}
                    </Alert>
                </Snackbar>
            
            {/* {loading && <LinearProgress />} */}

            {/* <Box className={styles.contentGrid}> */}
                {searchResult.length > 0 ? 
                 <AutoSizer>
                 {({ height, width }) => (
                     <DataTable
                         width={width}
                         height={height}
                         data={searchResult}
                         columns={columns}
                         size={"small"}
                     />
                 )}
             </AutoSizer> :   
             <div>
            <TextField
                onChange={handleInputChange}
                value={text}
                placeholder="Enter keyword"
                fullWidth
                multiline
                className={"textfield"}
            />
            <Box sx={{display:"flex", justifyContent:"center", marginTop:"10px"}}>
                <Button
                    sx={{ 
                    display: "flex",
                    justifyContent: "center"}}
                    variant="contained"
                    color="primary"
                    onClick={handleCheck}
                    disabled={!text}
                >
                    Check
                </Button>
            </Box>
        </div>}
            {/* </Box> */}
        </Paper>
    );
}