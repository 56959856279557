export const SET_CONTENT_UPLOAD_PRIMARY = "@@upload/SET_CONTENT_UPLOAD_PRIMARY";

export const PREPARE_CONTENT_IMAGE = "@@upload/PREPARE_CONTENT_IMAGE";
export const PREPARE_CONTENT_IMAGE_SUCCESS = "@@upload/PREPARE_CONTENT_IMAGE_SUCCESS";

export const UPLOAD_CONTENT_IMAGE = "@@upload/UPLOAD_CONTENT_IMAGE";
export const UPLOAD_CONTENT_IMAGE_SUCCESS = "@@upload/UPLOAD_CONTENT_IMAGE_SUCCESS";
export const UPLOAD_CONTENT_IMAGE_FAIL = "@@upload/UPLOAD_CONTENT_IMAGE_FAIL";

export const UPLOAD_CONTENT = "@@upload/UPLOAD_CONTENT";
export const UPLOAD_CONTENT_SUCCESS = "@@upload/UPLOAD_CONTENT_SUCCESS";
export const UPLOAD_CONTENT_FAIL = "@@upload/UPLOAD_CONTENT_FAIL";

export const UPLOAD_CLOSE_NOTIFICATION = "@@upload/UPLOAD_CLOSE_NOTIFICATION";

export const LOAD_UPLOAD_BASE_KW_LIST = "@@upload/LOAD_UPLOAD_BASE_KW_LIST";
export const LOAD_UPLOAD_BASE_KW_LIST_SUCCESS = "@@upload/LOAD_UPLOAD_BASE_KW_LIST_SUCCESS";

export const SET_UPLOAD_ISORIGINAL_FLAG = "@@upload/SET_UPLOAD_ISORIGINAL_FLAG";
export const SET_CONTENT_UPLOAD_ISZIP_FLAG = "@@upload/SET_CONTENT_UPLOAD_ISZIP_FLAG";

export const PREPARE_CONTENT_ZIP = "@@upload/PREPARE_CONTENT_ZIP";
export const PREPARE_CONTENT_ZIP_SUCCESS = "@@upload/PREPARE_CONTENT_ZIP_SUCCESS";

export const UNZIP_CONTENT = "@@upload/UNZIP_CONTENT";
export const UNZIP_CONTENT_SUCCESS = "@@upload/UNZIP_CONTENT_SUCCESS";
export const UNZIP_CONTENT_FAIL = "@@upload/UNZIP_CONTENT_FAIL";

export const UPLOAD_CONTENT_ZIP_TO_S3 = "@@upload/UPLOAD_CONTENT_ZIP_TO_S3";
export const UPLOAD_CONTENT_ZIP_TO_S3_SUCCESS = "@@upload/UPLOAD_CONTENT_ZIP_TO_S3_SUCCESS";