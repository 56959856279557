import {Paper, Box, Typography, Button, List, ListItem, ListItemText, InputLabel, Autocomplete, TextField, Chip} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { Authorization } from "@cosmos/runes/esm";
import {
    EDIT_TRANSLATION_INPUT_VALUE, SUBMIT_KD_TRANSLATION_EDIT, SUBMIT_KD_TRANSLATION_CREATE
} from "../../actions/keywordtranslation";
import slugify from 'react-slugify';

export function TranslationEditor() {
    const dispatch = useDispatch();
    const detail = useSelector((state) => state.translation.detail);
    const loading = useSelector((state) => state.translation.loading);
    const projects = useSelector((state) => state.system.projects);
    const selectedProject = useSelector((state) => state.system.selectedProject);
    const bases = useSelector((state) => state.system.bases);
    const [baseArr, setBaseArr] = useState([]);

    useEffect(() => {
        if(bases && Object.keys(bases).length > 0) {
            setBaseArr(Object.values(bases))
        }
    }, [bases]);


    const handleEditSave = (e) => {
        if(detail.id) {
            dispatch({ type: SUBMIT_KD_TRANSLATION_EDIT});
        }else {
            dispatch({ type: SUBMIT_KD_TRANSLATION_CREATE});
        }
    };

    return <Paper sx={{minHeight: "50%"}}>
        <Box sx={{display: 'flex', flex: 1, padding: "20px", marginBottom:"15px", alignItems: "center"}}>
            <Typography sx={{display: 'flex', flex: 1, justifyContent: "flex-start"}} variant="h4">Keyword Details
                {detail && detail.id ? ": " + detail.id : ""}
            </Typography>
            <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={!detail?.base || !detail.name || loading}
                onClick={handleEditSave}
            >
                SAVE
            </Button>
        </Box>
        <List>
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}} >Language:</InputLabel>
                <Autocomplete
                    sx={{display: "flex", flex: 1, marginRight: "20px", "& .MuiOutlinedInput-root": {paddingTop: "0px", paddingBottom: "0px"}}}
                    id="type-box-lang"
                    options={Object.values(projects) || []}
                    getOptionLabel={channel => {
                        let value = "";
                        if(channel && channel.id) {
                            value = channel.lang + " - " + channel.name
                        }
                        return value;
                    }}
                    disabled = {detail.id}
                    onChange={(event, value) => {
                        if (value) {
                            dispatch({ type: EDIT_TRANSLATION_INPUT_VALUE, payload: {channel: value.channel, lang: value.lang, channelName: value.name}});
                        }
                    }}
                    // value = {
                    //     detail && detail.id ? 
                    //         {id: detail?.channel, lang: detail?.language, name: detail?.channelName} : 
                    //         {id: projects[selectedProject]?.channel, lang: projects[selectedProject]?.lang, name: projects[selectedProject]?.name} 
                    //         || {}
                    // }
                    value = {
                        detail && detail.channel ? 
                            {id: detail?.channel, lang: detail?.lang, name: detail?.channelName}
                            : {id: projects[selectedProject]?.channel, lang: projects[selectedProject]?.lang, name: projects[selectedProject]?.name} 
                    }
                    renderInput={(params) => <TextField {...params} />}
                />
            </ListItem>
            {/* <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}}>Channel: </InputLabel>
                <ListItemText sx={{display: "flex", flex: 1, marginRight: "20px"}} fullWidth>
                    {detail && detail.id ? detail?.channelName : projects[selectedProject]?.name}
                </ListItemText>
            </ListItem> */}
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}} >Base:</InputLabel>
                <Autocomplete
                    sx={{display: "flex", flex: 1, marginRight: "20px", "& .MuiOutlinedInput-root": {paddingTop: "0px", paddingBottom: "0px"}}}
                    id="type-box-base"
                    options={baseArr}
                    getOptionLabel={base => base.name || ""}
                    disabled = {detail.id}
                    onChange={(event, value) => {
                        if (value) {
                            dispatch({ type: EDIT_TRANSLATION_INPUT_VALUE, payload: {baseData: value, base: value.id}});
                        }
                    }}
                    value={detail?.baseData || {}}
                    renderInput={(params) => <TextField {...params} />}
                />
            </ListItem>
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{width: "8%", alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}} >Name:</InputLabel>
                <TextField
                    sx={{marginRight: "20px", "& .MuiOutlinedInput-input": {paddingTop: "10px", paddingBottom: "10px"}}}
                    onChange={(e) => {
                        e.preventDefault();
                        const value = e.target.value;
                        const lowerCasevalue = value.toLowerCase();
                        const slugValue = lowerCasevalue ? slugify(lowerCasevalue) : "";
                        dispatch({ type: EDIT_TRANSLATION_INPUT_VALUE, payload: {name: value, slug: slugValue} });
                    }}
                    value={detail.name}
                    placeholder="Enter keyword"
                    fullWidth
                />
            </ListItem>
            <ListItem  sx={{display: "flex", flex: 1}}>
                <InputLabel sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "20px"}}>Slug: </InputLabel>
                <ListItemText sx={{display: "flex", flex: 1, marginRight: "20px"}} fullWidth>
                    {detail && detail.slug}
                </ListItemText>
            </ListItem>
        </List>
        
    </Paper>
}