import { useDispatch, useSelector } from "react-redux";
import { Card, Autocomplete, Box, Fab, Button, LinearProgress, Paper, Toolbar, Typography, TextField, Snackbar, Alert, Switch, CircularProgress} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
// import {
//     LOAD_KEYWORDS_LIST,
// } from "../../actions/content";
import {
    SET_SOURCING_UPLOAD_PRIMARY,
    PREPARE_SOURCING_IMAGE,
    UPLOAD_SOURCING,
    UPLOAD_SOURCING_CLOSE_NOTIFICATION,
    LOAD_SOURCING_BASE_KW_LIST,
    SET_SOURCING_ISORIGINAL_FLAG,
    PREPARE_SOURCING_ZIP,
    SET_SOURCING_UPLOAD_ISZIP_FLAG,
    UPLOAD_SOURCING_ZIP_TO_S3
} from "../../actions/sourcing";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Authorization } from "@cosmos/runes/esm";

export function SourcingPage() {
    const dispatch = useDispatch();
    const loadingReduxStore = useSelector(state=>state.sourcing.loading);
    const imgArr = useSelector(state=>state.sourcing.imgArr);
    const notify = useSelector(state=>state.sourcing.notify);
    const notice = useSelector(state=>state.sourcing.notice);
    const noticeType = useSelector(state=>state.sourcing.noticeType);
    const selectedKeyword = useSelector(state=>state.sourcing.keyword);
    const baseKeywords = useSelector(state=>state.sourcing.baseKeywords);
    const isOriginal = useSelector(state=>state.sourcing.isOriginal);
    const isZip = useSelector(state=>state.sourcing.isZip);
    const selectedPrimaryBaseKeyword = useSelector(state=>state.sourcing.primaryBaseKeyword);
    const failImgArr = useSelector(state=>state.sourcing.failImgArr);
    const duplicateImgArr = useSelector(state=>state.sourcing.duplicateImgArr);
    const opLoading = useSelector(state=>state.sourcing.opLoading);
    const [keywordsOption, setKeywordsOption] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    const inputRef = React.useRef(null);

    const [loading, setLoading] = useState(loadingReduxStore);
    const [selectedKeywordValue, setSelectedKeywordValue] = useState(selectedKeyword);

    useEffect(() => {
        if(loadingReduxStore) {
            setLoading(true);
        } else {
            if(isZip) {
                setLoading(false);
            }else {
                let inProgress = imgArr.filter(x => !x.uploaded).length;
                setLoading(false);
                if(inProgress > 0) {
                    setLoading(true);
                }
            }
        }
    }, [imgArr, loadingReduxStore]);

    // Load some base_keyword data
    useEffect(() => {
        dispatch({type: LOAD_SOURCING_BASE_KW_LIST});
        dispatch({type: SET_SOURCING_UPLOAD_ISZIP_FLAG, payload: false})
        dispatch({type: SET_SOURCING_UPLOAD_PRIMARY, payload: {data: {id: "", name: "", base_id: "", base: ""}}})
    }, [dispatch]);

    useEffect(() => {
        setSelectedKeywordValue(selectedKeyword);
    }, [selectedKeyword]);

    useEffect(()=>{
        const keyArr = Object.values(baseKeywords);
        setKeywordsOption(baseKeywords);
    }, [setKeywordsOption, baseKeywords]);

    const handleCreateContent= (e) => {
        if(isZip) {
            dispatch({ 
                type: PREPARE_SOURCING_ZIP 
            })   
        }else {
            dispatch({
                type: UPLOAD_SOURCING
            })
        }
    }

    const handleClose = () => {
        dispatch({ type: UPLOAD_SOURCING_CLOSE_NOTIFICATION });
    };
  
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
  
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if(isZip) {
                // dispatch({
                //     type: PREPARE_SOURCING_ZIP,
                //     payload: e.dataTransfer.files
                // })  
                dispatch({
                    type: UPLOAD_SOURCING_ZIP_TO_S3,
                    payload: e.dataTransfer.files
                })  
            }else {
                dispatch({
                    type: PREPARE_SOURCING_IMAGE,
                    payload: e.dataTransfer.files
                })  
            }      
        }
    };
    
    // // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files) {
            if(isZip) {
                // dispatch({
                //     type: PREPARE_SOURCING_ZIP,
                //     payload: e.target.files
                // })  
                dispatch({
                    type: UPLOAD_SOURCING_ZIP_TO_S3,
                    payload: e.target.files
                })  
            }else {
                dispatch({
                    type: PREPARE_SOURCING_IMAGE,
                    payload: e.target.files
                })  
            }
        }
    };
  
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    function ImageCell({ style, index, data }) {
        return <Card 
            style={style} sx={{ height: "40px", background: "rgba(0, 0, 0, 0.2)", padding: "3px", 
                display: "flex", alignItems: "center",
                ...(data[index].uploaded && {
                    background: "rgba(25, 135, 84, 0.4)",
                }),
                ...(data[index].unMatchFiles && data[index].unMatchFiles.length >0 && {
                    background: "red",
                })
            }}>
            <Box>
                <Typography sx={{paddingLeft: "5px"}}>{index + 1 }. {data[index].name}</Typography>
                <Typography sx={{paddingLeft: "5px", fontSize: "10px"}}>
                    {data[index]?.unMatchFiles?.length > 0 && "Error: Pls check the zip file for " + data[index]?.unMatchFiles + "records"}
                </Typography>
            </Box>
        </Card>
    }

    return <Paper sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{flexGrow: 1, width: "12rem", display: "flex", flexDirection: "column" }}>
            <Toolbar variant="dense" sx={{ bgcolor: "primary.light", ...(isZip && { bgcolor: "warning.light"}), display: "flex", justifyContent: "space-between" }}>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography variant={"h5"} sx={{paddingRight: "20px"}}>Sourcing</Typography>
                    <Button
                        sx={{marginRight: "10px"}}
                        color="inherit"
                        variant={isZip ? "contained" : "outlined"}
                        onClick={(e) => dispatch({
                            type: SET_SOURCING_UPLOAD_ISZIP_FLAG, payload: isZip ? false :true
                        })}
                    >
                        Zip
                    </Button>
                </Box>
                <Button
                    variant="contained"
                    color="info"
                    onClick={handleCreateContent}
                    disabled={imgArr.length <= 0 || !selectedKeyword.id || !selectedPrimaryBaseKeyword.id}
                >
                    Submit
                </Button>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{display: "flex", height: "100%"}}>
                <Box sx={{ flex:1, padding: "10px 20px 10px 20px"}}>
                    <Box sx={{display: "flex", alignItems: "center", paddingBottom: "15px"}}>
                        <Typography variant={"h6"} sx={{paddingRight: "10px"}}>Keyword:</Typography>
                        <Autocomplete
                            freeSolo
                            value={selectedKeywordValue}
                            onInputChange={(e, v, r) => {
                                if(e) {
                                    dispatch({type: LOAD_SOURCING_BASE_KW_LIST, search: v});
                                }
                            }}
                            fullWidth
                            sx={{
                                "& .MuiInputBase-formControl": {
                                    paddingTop: "0px",
                                    paddingBottom: "0px"
                                }
                            }}
                            onChange={(event, value) => {
                                if(value && value.id) {
                                    dispatch({ type: SET_SOURCING_UPLOAD_PRIMARY, payload: {data: value} });
                                }else {
                                    dispatch({ type: SET_SOURCING_UPLOAD_PRIMARY, payload: {data: {id: -1, name: value.inputValue}} });
                                }
                            }}
                            options={keywordsOption}
                            // getOptionLabel={(option) => option?.name || ""}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option?.name;
                            }}
                            isOptionEqualToValue={(option, value) => option?.id === value.id || null}
                            filterOptions={(options, params) => {
                                const filter = createFilterOptions();
                                const filtered = filter(options, params);
                        
                                // const { inputValue } = params;
                                // // Suggest the creation of a new value
                                // const isExisting = options.some((option) => inputValue.toUpperCase() === option?.name.toUpperCase());

                                // if (inputValue !== '' && !isExisting) {
                                //   filtered.push({
                                //     inputValue,
                                //     name: `Add "${inputValue}"`,
                                //   });
                                // }
                        
                                return filtered;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.name}</li>}
                            renderInput={
                                (params) => <TextField
                                    {...params}
                                    placeholder="Select primary keyword for sourcing"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment:(
                                            <>
                                                {opLoading ? (<CircularProgress size={20}/>) : null}
                                                {
                                                    params.InputProps
                                                    .endAdornment
                                                }
                                            </>
                                        )
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Authorization allowed={["pixel.content.approval"]} failback={<></>}>
                        <Box sx={{display: "flex", alignItems: "center", paddingBottom: "15px"}}>
                            <Typography variant={"h6"} sx={{paddingRight: "10px"}}>Original Content:</Typography>
                            <Switch
                                checked={isOriginal} 
                                onChange={() => {
                                    dispatch({ type: SET_SOURCING_ISORIGINAL_FLAG, payload: { original: isOriginal ? false : true } })
                                }}/>
                        </Box>
                    </Authorization>
                    <Box sx={{height: "100%", width: "80%", paddingLeft: "10px"}}>
                        {duplicateImgArr.length > 0 && <Typography sx={{color: "red"}}>Duplicate records: {duplicateImgArr}</Typography>}
                        {failImgArr.length > 0 && <Typography sx={{color: "red"}}>Fail upload records: {failImgArr}</Typography>}
                        <Typography variant={"h6"} sx={{paddingBottom: "10px"}}>{isZip ? "Uploaded Zip files": "Uploaded Images:" }</Typography>
                        <Box sx={{height: "100%", padding: "10px"}}>
                        {imgArr.length > 0 ? 
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        width={width}
                                        height={height - 130}
                                        itemSize={48}
                                        itemData={imgArr}
                                        itemCount={imgArr.length}
                                    >
                                        {ImageCell}
                                    </FixedSizeList>
                                )}
                            </AutoSizer> : "No uploaded image found"}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{flex:1, padding: "10px"}}>
                    <Box>   
                    <form style={{height: "16rem", textAlign: "center", position: "relative"}} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" accept={isZip ? ".zip" : null }style={{display: "none"}} multiple={true} onChange={handleChange} />
                        <Box sx={{height: "100%", display: "flex", alignItems: "center", justifyContent: "center", borderWidth: "2px", borderRadius: "1rem", 
                            borderStyle: "dashed", borderColor: "#9da1a5", backgroundColor: "#edeff0", ...(dragActive && { background: "#f8fafc"})}} htmlFor="input-file-upload">
                            <Box>
                            <Typography variant={"h6"} sx={{paddingBottom: "10px"}}>Drag and drop your file here or</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!selectedKeyword.id || (isZip && imgArr.length > 0) || loading}
                                onClick={onButtonClick}
                            >
                                Upload a {isZip && " zip "}file
                            </Button>
                            </Box> 
                        </Box>
                        { dragActive && <Box sx={{position: "absolute", width: "100%", height: "100%", borderRadius: "1rem", top: "0px", right: "0px", bottom: "0px", left: "0px"}} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></Box> }
                    </form>

                    </Box>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notify}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert variant="filled" severity={noticeType}>
                    {notice}
                </Alert>
            </Snackbar>
        </Box>
    </Paper>;
}