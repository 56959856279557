import {createReducer} from "@reduxjs/toolkit";
import {
    LOAD_TASK_USERS, LOAD_TASK_USERS_SUCCESS, LOAD_TASK_USERS_FAIL,
    SELECT_USER_TASKS, SELECT_USER_TASKS_SUCCESS, SELECT_USER_TASKS_FAIL,
    UPDATE_ISOPEN_DIALOG,
    LOAD_UNASSIGNED_KEYWORD, LOAD_UNASSIGNED_KEYWORD_SUCCESS, LOAD_UNASSIGNED_KEYWORD_FAIL,
    SET_SELECTED_TASK,
    ASSIGN_TASK_USER, ASSIGN_TASK_USER_SUCCESS, ASSIGN_TASK_USER_FAIL,
    TASK_CLOSE_NOTIFICATION
} from "../actions/task";
import {createVibes} from "@cosmos/vibes";
import moment from "moment";
import { flatten } from "../utils";

export const taskReducer = createReducer(
    {
        userData: {},
        filteredUsrIdx: [],
        taskData: {},
        filteredTaskIdx: [],
        loading: false,
        loadingTask: false,
        user: {},
        task: {},
        loadingDialog: false,
        unassignedKeyword: [],
        notice: "",
        notify: false,
        noticeType: "error",
        openDialog: false
    }
    ,
    builder => {
        builder.addCase(LOAD_TASK_USERS, (state) => {
            state.loading = true;
        }).addCase(LOAD_TASK_USERS_SUCCESS, (state, action) => {
            state.loading = false;
            state.userData = action.payload.data;
            state.filteredUsrIdx = action.payload.filteredUsrIdx;
        }).addCase(LOAD_TASK_USERS_FAIL, state => {
            state.loading = false;
        }).addCase(SELECT_USER_TASKS, (state, action) => {
            state.loadingTask = true;
            state.user = action.payload;
        }).addCase(SELECT_USER_TASKS_SUCCESS, (state, action) => {
            state.loadingTask = false;
            state.taskData = action.payload.data;
            state.filteredTaskIdx = action.payload.filteredTaskIdx;
        }).addCase(SELECT_USER_TASKS_FAIL, state => {
            state.loadingTask = false;
        }).addCase(UPDATE_ISOPEN_DIALOG, (state, action) => {
            state.openDialog = action.payload;
            state.task = {};
        }).addCase(LOAD_UNASSIGNED_KEYWORD, (state, action) => {
            state.loadingDialog = true;
        }).addCase(LOAD_UNASSIGNED_KEYWORD_SUCCESS, (state, action) => {
            state.loadingDialog = false;
            state.unassignedKeyword = action.payload.data;
        }).addCase(LOAD_UNASSIGNED_KEYWORD_FAIL, state => {
            state.loadingDialog = false;
        }).addCase(SET_SELECTED_TASK, (state, action) => {
            state.task = action.payload.data
        }).addCase(ASSIGN_TASK_USER, (state, action) => {
            state.loadingDialog = true;
            state.loadingTask = true;
        }).addCase(ASSIGN_TASK_USER_SUCCESS, (state, action) => {
            state.loadingDialog = false;
            state.loadingTask = false;
            if(action.payload.action === "ASSIGN") {
                const taskData = state.taskData;
                taskData[action.payload.taskId] = action.payload.obj;
                const newIdx = state.filteredTaskIdx;
                newIdx.push(action.payload.taskId);
                state.filteredTaskIdx = newIdx;
            }else if(action.payload.action === "REASSIGN") {
                const task = state.task;
                const taskData = state.taskData;
                delete taskData[task.taskId];
                state.taskData = taskData;
                const newIdx = state.filteredTaskIdx;
                state.filteredTaskIdx = newIdx.filter(x => x !== task.taskId);
            }
            state.notify = true;
            state.notice = "Assigned task successfully";
            state.noticeType = "success";
        }).addCase(ASSIGN_TASK_USER_FAIL, (state, action) => {
            state.loadingDialog = false;
            state.loadingTask = false;
            state.notify = true;
            state.notice = action.payload.notice;
            state.noticeType = "error";
        })
        .addCase(TASK_CLOSE_NOTIFICATION, state => {
            state.notify = false;
            state.noticeType = "error";
            state.notice = "";
        })
    }
)

export const loadTaskUsers = createVibes({
    type: LOAD_TASK_USERS,
    latest: true,
    processOptions: {
        successType: LOAD_TASK_USERS_SUCCESS,
        failType: LOAD_TASK_USERS_FAIL
    },
    async process({ getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;

        const { data: responseData } = await axios.request({
            method: "post",
            data: {
                query: `query retervalChannle($channel: String) {
                            users(channel: $channel) {
                                userId name manager approval amend provision 
                                tasks{
                                    taskId type targetId channel assignee manager 
                                    status startedAt dueDate finishedAt
                                }
                            }
                            countReportNo{
                                userId manager noOfAmend noOfApprove noOfProvision
                                noOfPastApprove noOfPastProvision noOfPastAmend
                            }                       
                    }`,
                variables: {
                    channel,
                },
            },
        });

        let { users, countReportNo } = responseData;
        if(users) {
            
            users.map((x) => {
                let startOn = "-";
                //if it is a manager
                if (!x.manager) {
                    const managerId = x.userId;
                    let vaTaskList = [];

                    users.filter((y) => {
                        if (managerId === y.manager) {
                            if (y.tasks) {
                                vaTaskList = _.concat(vaTaskList, y.tasks);
                            }
                        }
                        return vaTaskList;
                    });

                    x.vaTaskList = vaTaskList;
                    x.managerTaskCount = vaTaskList.length;

                    if (vaTaskList.length > 0) {
                        // sorting by earliest time for task assigned
                        vaTaskList.sort((a, b) => {
                            if (a.startedAt && b.startedAt) {
                                return (
                                    moment(a.startedAt) - moment(b.startedAt)
                                );
                            }
                            return false;
                        });

                        // format date
                        if (vaTaskList.length > 0) {
                            startOn =
                                vaTaskList[0] &&
                                vaTaskList[0].startedAt &&
                                moment(vaTaskList[0].startedAt).format(
                                    "YYYY-MM-DD"
                                )
                                    ? moment(vaTaskList[0].startedAt).format(
                                        "YYYY-MM-DD"
                                    )
                                    : "-";
                        }
                    }

                    x.taskStartOn = startOn;
                }else {
                    let dateArr = [];
                    const tasks = x.tasks;
                    dateArr = tasks.sort((a, b) => {
                        if (a.startedAt && b.startedAt) {
                            return (
                                moment(a.startedAt) -
                                moment(b.startedAt)
                            );
                        }
                    });
                    if (dateArr && dateArr.length > 0) {
                        startOn =
                            dateArr[0] &&
                            dateArr[0].startedAt &&
                            moment(dateArr[0].startedAt).format(
                                "YYYY-MM-DD"
                            )
                                ? moment(dateArr[0].startedAt).format(
                                        "YYYY-MM-DD"
                                    )
                                : "-";
                    }
                    x.taskStartOn = startOn;
                }

                return x;
            });

            const indexedReport = _.keyBy(countReportNo, "userId");
            users = users.map((t) => {
                t.reportNo = indexedReport[t.userId];
                return t;
            })

            return {
                data: _.keyBy(users, "userId"),
                filteredUsrIdx: users.map((user) => user.userId),
            }

        } else {
            throw new Error("Fail to load users", responseData);
        }
    }
});

export const selectUserTasks = createVibes({
    type: SELECT_USER_TASKS,
    latest: true,
    processOptions: {
        successType: SELECT_USER_TASKS_SUCCESS,
        failType: SELECT_USER_TASKS_FAIL
    },
    async process({ getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const user = state.task.user;

        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query($channel: String, $userId: Int) {
                    tasks:userTasks(channel: $channel, userId: $userId) {
                        taskId
                        channel
                        keyword { id name slug }
                        status
                        assignee
                        startedAt
                    }
                }`,
                variables: {
                    channel,
                    userId: user.userId
                }
            }
        });

        if (data && data.tasks) {
            const tasks = data.tasks.map(task => flatten(task));
            return {
                data: _.keyBy(tasks, "taskId"),
                filteredTaskIdx: tasks.map((task) => task.taskId)
            }
        } else {
            throw new Error("Fail to load tasks", data);
        }
    }
});

export const loadUnassignedKeyword = createVibes({
    type: LOAD_UNASSIGNED_KEYWORD,
    latest: true,
    processOptions: {
        successType: LOAD_UNASSIGNED_KEYWORD_SUCCESS,
        failType: LOAD_UNASSIGNED_KEYWORD_FAIL
    },
    async process({ getState, axios, action }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;

        const { data: responseData } = await axios.request({
            method: "post",
            data: {
                query: 
                `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                        id
                        name
                        taskAssigned
                    }
                }
                `,
                variables: {
                    filter: {
                        channel,
                        taskAssigned: -1,
                        search: action.search
                    },
                },
            },
        });

        let { keywords } = responseData;
        if(keywords) {
            return {
                data: keywords
            }
        } else {
            throw new Error("Fail to load users", responseData);
        }
    }
});

export const assignTaskUser = createVibes({
    type: ASSIGN_TASK_USER,
    latest: true,
    async process({ getState, axios, action }, dispatch, done) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const openDialog = state.task.openDialog;
        const keywordId = state.task.task.id;
        const keywordName = state.task.task.name;
        const userId = action.payload.userId;

        const { data: responseData } = await axios.request({
            method: "post",
            data: {
                query: `mutation task($keyword: Int, $assignee: Int, $channel: String) {
                            assignKeywordToUser(keyword: $keyword, assignee: $assignee, channel: $channel) }`,
                variables: {
                    keyword: keywordId,
                    assignee: userId,
                    channel,
                },
            },
        });

        let { assignKeywordToUser } = responseData;

        if(assignKeywordToUser) {
            const newObj = {taskId: assignKeywordToUser, id: keywordId, name: keywordName, assignee: userId, status: 1, startedAt: moment(new Date()).format("YYYY-MM-DD")};
   
            dispatch({ type: ASSIGN_TASK_USER_SUCCESS, payload: {action: action.payload.action, taskId: assignKeywordToUser, obj: newObj} });

            if(openDialog) {
                dispatch({ type: LOAD_UNASSIGNED_KEYWORD });
                dispatch({ type: UPDATE_ISOPEN_DIALOG, payload: false });
            }
            
        } else {
            dispatch({ type: ASSIGN_TASK_USER_FAIL,  
                payload: {
                    notice: "Fail to assign task",
                },
            });
        }
        done();
    }
});