import {createReducer} from "@reduxjs/toolkit";
import {createVibes} from "@cosmos/vibes";
import { LOAD_KEYWORDS,LOAD_KEYWORDS_SUCCESS } from "../../src/actions/keyword";

export const keywordReducer = createReducer(
    {
      data: [],
      loading: false
    }
    ,
    builder => {
        builder.addCase(LOAD_KEYWORDS, (state, action) => {
            state.loading = true;
        }).addCase(LOAD_KEYWORDS_SUCCESS, (state, action) => {
            state.data = action.payload.data
        })
    })

export const loadKeywords = createVibes({
    type: LOAD_KEYWORDS,
    processOptions: {
        successType: LOAD_KEYWORDS_SUCCESS
    },
    async process({getState, axios}) {
        try {
            const data = await axios.request({
                method: "post",
                data: {
                    query: 
                    `query loadKeywords($filter: KeywordFilter) {
                        keywords(filter: $filter) {
                            id
                            name
                            taskAssigned
                            keywordDetails {
                                id
                                provisioned
                                available
                                lived
                                lastSyncAt
                            }
                        }
                        tasks {
                            taskId
                            targetId
                            startedAt
                            assignee
                        }
                        users {
                            userId
                            name
                        }
                    }
                    `,
                    variables: {
                        filter: {
                            channel: "100001",
                            taskAssigned: -1,
                            take: 10000,
                            totalActive: 40
                        },
                    },
                },
            });

            
            const {keywords, users, tasks } = data.data

            const usersObj = _.keyBy(users, "userId");
            const tasksObj = _.keyBy(tasks, "assignee");
          
            const keywordList = keywords.map((x)=> {
                 x.assignee = usersObj[x.taskAssigned]?.name
                 x.startedAt = tasksObj[x.taskAssigned]?.startedAt
                 return x
                
            })

            return {
                    data: keywordList
            }
        }
        catch(error){
            console.log(error)
        }
    }
})