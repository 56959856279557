import { 
    Alert, Button,
    Box, LinearProgress, Paper, Snackbar, 
    Typography , Autocomplete, TextField, CircularProgress,
    Toolbar, Select, MenuItem
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import {
    CREATE_LISTING_CLOSE_NOTIFICATION, CLEAR_EDIT_SELECT_LISTING,
    SELECT_LISTING_BASE
} from "../../actions/listing";
import {ListingTable} from './listingTable';
import {ListingEditor} from './listingEditor';

export function Listing() {
    const dispatch = useDispatch();
    const notify = useSelector((state) => state.listing.notify);
    const notice = useSelector((state) => state.listing.notice);
    const noticeType = useSelector((state) => state.listing.noticeType);
    const loading = useSelector((state) => state.listing.loading);
    const selectedBase = useSelector((state) => state.listing.selectedBase);
    const bases = useSelector((state) => state.system.bases);
    const [msgType, setMsgType] = useState(noticeType);
    // const opOpen = useSelector((state) => state.preview.opOpen);
    // const opOptions = useSelector((state) => state.preview.opOptions);
    // const opSelected = useSelector((state) => state.preview.opSelected);
    // const opLoading = opOpen && opOptions.length === 0;
    // const filters = useSelector((state) => state.preview.filters);

    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);

    const handleClose = () => {
        dispatch({ type: CREATE_LISTING_CLOSE_NOTIFICATION });
    };

    return <Paper sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{flexGrow: 1, width: "12rem", display: "flex", flexDirection: "column" }}>
            <Toolbar variant="dense" sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between" }}>
                <Typography variant={"h5"} sx={{paddingRight: "20px"}}>Listing</Typography>
                {/* <Box sx={{width: "300px", display: "flex", alignItems: "center"}}>
                    <SearchIcon/>
                    <Autocomplete
                        freeSolo
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        fullWidth
                        onInputChange={(e, v, r) => {
                            if(e) {
                                // dispatch({type: MANAGER_SEARCH_KEYWORDS, search: v});
                            }
                        }}
                        onChange={(e, value)=>{
                            e.preventDefault();
                            // if(value) {
                            //     setSelectedKeyword(value);
                            //     if(value.keywordDetailData) {
                            //         // setOnAssignDialog(true);
                            //         dispatch({type: UPDATE_MANAGER_ASSIGN_DIALOG, status: true});
                            //         const kd = value.keywordDetailData ? value.keywordDetailData : {};
                            //         dispatch({type: SET_MANAGER_SEARCHED_KD, payload: {keywordDetail: kd, keywordId: value.id}});
                            //     }else {
                            //         dispatch({type: UPDATE_MANAGER_NEW_KW_DIALOG, status: true});
                            //     }      
                            // }
                        }}
                        // disableClearable
                        // options={keywordsOpt || []}
                        options={[]}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option?.id === value.id}
                        renderInput={
                            (params) => 
                            <TextField
                                sx={{bgcolor: "rgba(255, 255, 255, 0.15)"}}
                                {...params}
                                placeholder="Search for Listing"
                                InputProps={
                                    {
                                        ...params.InputProps,
                                    }}
                            />
                        }
                    />
                </Box> */}
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{paddingRight: "10px"}}>Base: </Typography>
                    <Select size={"small"} sx={{ width: "8rem", marginRight: "25px"}} 
                        value={selectedBase} onChange={event => dispatch({ type: SELECT_LISTING_BASE, payload: {id: event.target.value} })}>
                        {Object.values(bases).map(x => { return <MenuItem value={x.id} key={"alt-kw-"+x.id}>{x.name}</MenuItem>})}
                    </Select>
                    <Button
                        sx={{marginRight: "10px"}}
                        color="info"
                        variant="contained"
                        onClick={()=> dispatch({ type: CLEAR_EDIT_SELECT_LISTING})}
                    >
                        Clear
                    </Button>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{flex: 1, display: "flex"}}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={notify}
                    onClose={handleClose}
                    autoHideDuration={6000}
                >
                    <Alert variant="filled" severity={msgType}>
                        {notice}
                    </Alert>
                </Snackbar>
                <Box sx={{flex: 1}}>
                    <ListingTable/>
                </Box>
                <Box sx={{flex: 1, backgroundColor: "#F3F9FF"}}>
                    <ListingEditor />
                </Box>
            </Box>
        </Box>
    </Paper>;
}