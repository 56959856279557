import {
    Box,
    Fab,
    LinearProgress,
    Paper,
    Toolbar,
    Typography
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ADMIN_DASH_LOAD } from "../../actions/admin";
import { Board } from "../../components";

export function AdminDash() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.admin.loading);
    const boards = useSelector(state => state.admin.boardsIdx);
    const project = useSelector(state=>state.system.selectedProject);
    useEffect(() => {
        dispatch({ type: ADMIN_DASH_LOAD });
    }, [dispatch, project]);
    return <Paper sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "secondary.light" }}
                     variant="dense">
                <Typography variant="h5">Dashboard</Typography>
                <Box sx={{ flexGrow: 1 }}>
                    {/*<SearchBox/>*/}
                </Box>
                <Box>
                    <Fab size="small"
                         color="info"
                         variant={"extended"}
                         onClick={event => dispatch({ type: ADMIN_DASH_LOAD })}
                    >
                        <RefreshIcon sx={{ marginRight: "0.25rem" }}/>Refresh
                    </Fab>
                </Box>
            </Toolbar>
            <Box sx={{ flexGrow: 0, height: "4px" }}>
                {loading && <LinearProgress variant="indeterminate" color="primary"/>}
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex", justifyItems: "even-spacing" }}>
                {
                    boards.map(board => (
                        <Board key={`b-${board}`} selector={`admin.boardsData.${board}`} itemHeight={120}/>
                    ))
                }
            </Box>
        </Box>
    </Paper>;
}