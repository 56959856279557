export const LOAD_LISTINGS = "@@listing/LOAD_LISTINGS";
export const LOAD_LISTINGS_SUCCESS = "@@listing/LOAD_LISTINGS_SUCCESS";
export const SELECT_LISTING_PROFILE = "@@listing/SELECT_LISTING_PROFILE";
export const EDIT_LISTING_VALUE = "@@listing/EDIT_LISTING_VALUE";

export const SUBMIT_LISTING= "@@listing/SUBMIT_LISTING";
export const SUBMIT_LISTING_SUCCESS= "@@listing/SUBMIT_LISTING_SUCCESS";
export const SUBMIT_LISTING_FAIL= "@@listing/SUBMIT_LISTING_FAIL";

export const CLEAR_EDIT_SELECT_LISTING = "@@listing/CLEAR_EDIT_SELECT_LISTING";
export const CREATE_LISTING_CLOSE_NOTIFICATION= "@@listing/CREATE_LISTING_CLOSE_NOTIFICATION";

export const UPDATE_LISTING_STATUS= "@@listing/UPDATE_LISTING_STATUS";
export const UPDATE_LISTING_STATUS_SUCCESS= "@@listing/UPDATE_LISTING_STATUS_SUCCESS";
export const UPDATE_LISTING_STATUS_FAIL= "@@listing/UPDATE_LISTING_STATUS_FAIL";

export const SELECT_LISTING_BASE= "@@listing/SELECT_LISTING_BASE";

export const SEARCH_LISTING= "@@listing/SEARCH_LISTING";
export const LISTING_SEARCH_KEYWORD = "@@keyword/LISTING_SEARCH_KEYWORD";
export const LISTING_SEARCH_KEYWORD_SUCCESS = "@@keyword/LISTING_SEARCH_KEYWORD_SUCCESS";

