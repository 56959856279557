import { useDispatch, useSelector } from "react-redux";
import { 
    Box, Fab, LinearProgress, Paper, Toolbar, Typography, Autocomplete, TextField,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
    Snackbar, Alert, CircularProgress
} from "@mui/material";
import { useEffect, useState } from "react";
import { UploadProcess } from "./UploadProcess";
import {
    TRANSLATION_SEARCH_KEYWORDS, TRANSLATION_LOAD_KEYWORD_DETAILS, CLEAR_TRANSLATION_LIST,
    CREATE_TRANSLATION_CLOSE_NOTIFICATION, CLEAR_SELECT_TRANSLATION
} from "../../actions/keywordtranslation";
import SearchIcon from '@mui/icons-material/Search';
import {TranslationTable} from './translationTable';
import {TranslationEditor} from './translationEditor';

export function KeywordTranslationPage() {
    const dispatch = useDispatch();
    const keywordsOpt = useSelector(state=>state.translation.keywordsOpt);
    const loading = useSelector(state=>state.translation.loading);
    const opLoading = useSelector(state=>state.translation.opLoading);
    const notify = useSelector((state) => state.translation.notify);
    const notice = useSelector((state) => state.translation.notice);
    const noticeType = useSelector((state) => state.translation.noticeType);
    const translationList = useSelector(state=>state.translation.data);

    const [msgType, setMsgType] = useState(noticeType);
    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);

    useEffect(() => {
        dispatch({ type: CLEAR_TRANSLATION_LIST });
    }, []);

    const handleClose = () => {
        dispatch({ type: CREATE_TRANSLATION_CLOSE_NOTIFICATION });
    };

    return <Paper sx={{flexGrow: 1, display: "flex"}}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between"}}
                     variant="dense">
                <Typography variant="h5">Keyword Translation</Typography>
                <Box sx={{width: "300px", display: "flex", alignItems: "center"}}>
                    <SearchIcon/>
                    <Autocomplete
                        freeSolo
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        fullWidth
                        onInputChange={(e, v, r) => {
                            if(e) {
                                dispatch({type: TRANSLATION_SEARCH_KEYWORDS, search: v});
                            }
                        }}
                        onChange={(e, value)=>{
                            e.preventDefault();
                            if(value) {
                                dispatch({type: TRANSLATION_LOAD_KEYWORD_DETAILS, payload: value})
                            }
                        }}
                        // disableClearable
                        options={keywordsOpt || []}
                        getOptionLabel={(option) => option?.name || ""}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                placeholder="Search keyword by name"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment:(
                                        <>
                                            {opLoading ? (<CircularProgress size={20}/>) : null}
                                            {
                                                params.InputProps
                                                .endAdornment
                                            }
                                        </>
                                    )
                                }}
                            />
                        }
                    />
                </Box>
                <Box>
                    <Button
                        sx={{marginRight: "10px"}}
                        color="info"
                        variant="contained"
                        onClick={()=> dispatch({ type: CLEAR_SELECT_TRANSLATION})}
                    >
                        Clear
                    </Button>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{flex: 1, display: "flex"}}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={notify}
                    onClose={handleClose}
                    autoHideDuration={6000}
                >
                    <Alert variant="filled" severity={msgType}>
                        {notice}
                    </Alert>
                </Snackbar>
                <Box sx={{flex: 1}}>
                    <TranslationTable/>
                </Box>
                <Box sx={{flex: 1, backgroundColor: "#F3F9FF"}}>
                    <TranslationEditor />
                </Box>
            </Box>
        </Box>
    </Paper>
}