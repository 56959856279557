export const LOAD_TASK_USERS = "@@task/LOAD_TASK_USERS";
export const LOAD_TASK_USERS_SUCCESS = "@@task/LOAD_TASK_USERS_SUCCESS";
export const LOAD_TASK_USERS_FAIL = "@@task/LOAD_TASK_USERS_FAIL";

export const SELECT_USER_TASKS = "@@task/SELECT_USER_TASKS";
export const SELECT_USER_TASKS_SUCCESS = "@@task/SELECT_USER_TASKS_SUCCESS";
export const SELECT_USER_TASKS_FAIL = "@@task/SELECT_USER_TASKS_FAIL";

export const LOAD_UNASSIGNED_KEYWORD = "@@task/LOAD_UNASSIGNED_KEYWORD";
export const LOAD_UNASSIGNED_KEYWORD_SUCCESS = "@@task/LOAD_UNASSIGNED_KEYWORD_SUCCESS";
export const LOAD_UNASSIGNED_KEYWORD_FAIL = "@@task/LOAD_UNASSIGNED_KEYWORD_FAIL";

export const SET_SELECTED_TASK = "@@task/SET_SELECTED_TASK";
export const UPDATE_ISOPEN_DIALOG = "@@task/UPDATE_ISOPEN_DIALOG";

export const ASSIGN_TASK_USER = "@@task/ASSIGN_TASK_USER";
export const ASSIGN_TASK_USER_SUCCESS = "@@task/ASSIGN_TASK_USER_SUCCESS";
export const ASSIGN_TASK_USER_FAIL = "@@task/ASSIGN_TASK_USER_FAIL";

export const TASK_CLOSE_NOTIFICATION = "@@task/TASK_CLOSE_NOTIFICATION";