import {Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { DataTable } from '@cosmos/runes';
import {
    LOAD_LISTINGS,
    SELECT_LISTING_PROFILE
} from "../../actions/listing";
import moment from "moment";

export function ListingTable() {
    const dispatch = useDispatch();
    const selectedProject = useSelector(state => state.system.selectedProject);
    const projects = useSelector(state => state.system.projects);
    const bases = useSelector(state => state.system.bases);
    const listing = useSelector((state) => state.listing.data);
    const selectedListing = useSelector((state) => state.listing.listingDetail);
    const index = useSelector(state => state.listing.index);
    const filters = useSelector((state) => state.listing.filters);

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [channelData, setChannelData] = useState({});

    const [channel, setChannel] = useState(null);

    useEffect(() => {
        setChannel(projects[selectedProject]?.channel);
    }, [setChannel, projects, selectedProject]);

    useEffect(() => {
        if (channel !== "-1") {
            dispatch({ type: LOAD_LISTINGS, search: "" });
        };
    }, [channel, dispatch]);

    useEffect(() => {
        let ListingArr = index.map(idx => listing[idx]);
        if(filters.text) {
            ListingArr = Object.entries(listing).reduce((set, [k, v]) => {
                const regEx = new RegExp("^" + filters.text, "i");
                const match = regEx.test(v?.type);
                return [...set, ...(match ? [v] : [])];
            }, []);
        }
        
        setData(ListingArr);
    }, [listing, index, filters]);

    const handleListingSelect = (rowData) => {
        dispatch({ type: SELECT_LISTING_PROFILE , payload: rowData })
    }

    useEffect(() => {
        const columns = [
            {
                title: "Id",
                name: "id",
                sortBy: "id",
                width: "8%"
            },
            {
                title: "Channel",
                name: "channelName",
                sortBy: "channelName",
                // width: "15%",
                // cell: ({ rowData }) => {
                //     let channelName = rowData.channel;
                //     if (rowData && rowData.channel && Object.keys(channelData).length > 0) {
                //         if(channelData.id === channel) {
                //             channelName = channelData.name
                //         }
                //     }
                //     return channelName;
                // },
            },
            {
                title: "Base",
                name: "base",
                sortBy: "base",
                // width: "15%",
                cell: ({ rowData }) => {
                    let baseName = "-";
                    if (rowData && rowData?.baseData && rowData?.baseData?.name) {
                        baseName = rowData?.baseData?.name;
                    }
                    return baseName;
                },
            },
            {
                title: "Type",
                name: "type",
                sortBy: "type",
                // width: "15%"
            },
            {
                title: "Status",
                name: "status",
                sortBy: "status",
                // width: "15%"
            },
            {
                title: "Valid From",
                name: "validFrom",
                sortBy: "validFrom",
                width: "17%",
                cell: ({ rowData }) => {
                    let validFrom = "-";
                    if (rowData && rowData.validFrom) {
                        validFrom = moment(rowData.validFrom).format(
                            "YYYY-MM-DD"
                        );
                    }
                    return validFrom;
                },
            },
            {
                title: "Valid To",
                name: "validTo",
                sortBy: "validTo",
                width: "15%",
                cell: ({ rowData }) => {
                    let validTo = "-";
                    // format the date to YYYY-MM-DD
                    if (rowData && rowData.validTo) {
                        validTo = moment(rowData.validTo).format(
                            "YYYY-MM-DD"
                        );
                        
                       if(moment(validTo).isSame("2038-01-19","day")){
                            validTo = "-";
                        }
                    }
                    return validTo;
                },
            }
        ];
        setColumns(columns);
    }, [setColumns , channel]);

    return <Box sx={{ height: "100%" }}>
        <AutoSizer>
            {({ height, width }) => (
                <DataTable
                    width={width}
                    height={height}
                    data={data}
                    columns={columns}
                    size={"exSmall"}
                    rowOnClick={handleListingSelect}
                    rowHeight={25}
                />
            )}
        </AutoSizer>
    </Box>
}