import {Box} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { DataTable } from '@cosmos/runes';
import {
    LOAD_SC_KW_LIST,
    SELECT_SC_KEYWORD,
    REMOVE_SEARCRH_SC_KEYWORD, LOAD_SC_LIST
} from "../../actions/preview";


export function PreviewTable() {
    const dispatch = useDispatch();
    const filteredIndex = useSelector((state) => state.preview.filteredMgrKwIdx);
    const keywords = useSelector((state) => state.preview.data);
    const selectedKeyword = useSelector((state) => state.preview.keyword);
    const [keywordData, setKeywordData] = useState([]);
    const [columns, setColumns] = useState([]);
    // const [selectedKeyword, setSelectedKeyword] = useState(selectedKeywordData);

    useEffect(() => {
        const keywordArr = filteredIndex.map((idx) => keywords[idx]);
        setKeywordData(keywordArr);
    }, [keywords, filteredIndex]);

    function handleKeywordSelect({rowData}) {
        if(rowData) {
            dispatch({ type: SELECT_SC_KEYWORD, payload: rowData });
            dispatch({ type: LOAD_SC_LIST });
        }
    }

    useEffect(() => {
        let columns = [
            {
                title: "ID",
                name: "id",
                width: "20%"
            },
            {
                title: "Name",
                name: "name",
            },
            {
                title: "Volume",
                name: "baseKeyword.volume",
                width: "20%",
                sortBy: "baseKeyword.volume",
                cell: ({ rowData }) =>{
                    return (
                        <Box>
                             {(rowData?.baseKeyword && rowData?.baseKeyword?.volume)? rowData?.baseKeyword?.volume : 0}
                        </Box>
                    );
                }   
            }
        ];
        setColumns(columns);
    }, [setColumns]);

    return <Box sx={{ height: "100%" }}>
        <AutoSizer>
            {({ height, width }) => (
                <DataTable
                    width={width}
                    height={height}
                    data={keywordData}
                    columns={columns}
                    size={"exSmall"}
                    rowOnClick={handleKeywordSelect}
                    rowHeight={22}
                />
            )}
        </AutoSizer>
    </Box>
}