import { createReducer } from "@reduxjs/toolkit";
import {
    APPROVE_CONTENT,
    CONTENT_DETAIL_LOADED,
    CONTENT_FILTERED,
    CONTENT_PROVISIONED,
    CONTENT_UPDATED,
    DISMISS_ALERT,
    FEATURED_UPDATED,
    LOAD_CONTENT_DETAIL,
    LOAD_CONTENTS,
    LOAD_CONTENTS_FAIL,
    LOAD_CONTENTS_SUCCESS,
    LOAD_TASKS,
    LOAD_TASKS_FAIL,
    LOAD_TASKS_SUCCESS,
    PROVISION_CONTENT,
    SAVE_CONTENT,
    SELECT_TASK,
    SET_FEATURE_CONTENT,
    SET_FEATURE_CONTENT_FAIL,
    SHOW_ALERT,
    SUBMIT_TASK,
    TASK_FILTERED,
    TASK_UPDATED,
    UPDATE_AUTHOR,
    UPDATE_CONTENT,
    UPDATE_CONTENT_ISEDIT,
    UPDATE_FILTER,
    SELECT_ALT_KEYWORD,
    SELECT_ALT_KEYWORD_SUCCESS,
    SELECT_ALT_KEYWORD_FAIL,
    LINK_REL_KEYWORD,
    LINK_REL_KEYWORD_SUCCESS, LINK_REL_KEYWORD_FAIL,
    DESELECT_CONTENT, DESELECT_CONTENT_SUCCESS,
    LOAD_KEYWORDS_LIST, LOAD_KEYWORDS_LIST_SUCCESS,
    LINK_CONTENT_TO_KEYWORD, LINK_CONTENT_TO_KEYWORD_SUCCESS, LINK_CONTENT_TO_KEYWORD_FAIL,
    UNLINK_KEYWORD, UNLINK_KEYWORD_SUCCESS, UNLINK_KEYWORD_FAIL,
    SET_CONTENT_PRIMARY, SET_CONTENT_PRIMARY_SUCCESS, SET_CONTENT_PRIMARY_FAIL,
    SET_CONTENT_ORIGINAL, SET_CONTENT_ORIGINAL_SUCCESS, SET_CONTENT_ORIGINAL_FAIL,
    SEARCH_CONTENT_BY_SLUG, SEARCH_CONTENT_BY_SLUG_SUCCESS, SEARCH_CONTENT_BY_SLUG_FAIL,
    SEARCH_TASK, SEARCH_TASK_SUCCESS, SELECT_CONTENT, UPDATE_SELECT_CONTENT, SELECT_ALL_CONTENT,
    SET_TASK_FROM_MANAGER, SET_TASK_FROM_MANAGER_SUCCESS,
    EDITOR_SEARCH_KEYWORDS, EDITOR_SEARCH_KEYWORDS_SUCCESS
} from "../actions/content";
import { createVibes } from "@cosmos/vibes";
import { flatten } from "../utils";
import { keyBy } from "lodash";
import { selectAllElement, selectElement } from "@cosmos/runes";

export const editorReducer = createReducer(
    {
        loading: false,
        taskLoading: false,
        processing: false,
        tasks: {},
        taskIdx: [],
        currentTask: {},
        currentItem: null,
        featured: null,
        contents: {},
        contentIdx: [],
        authors: {},
        selectedContents: [],
        filter: "A",
        amendItem: null,
        amendments: {},
        dialogTitle: "",
        dialogContent: "",
        dialogOpen: false,
        dialogConfirmType: null,
        isEdited: false,
        selectedAltKeyword: -1,
        altKeywords: [],
        linkingItem: -1,
        linkedItem: 0,
        keywords: {},
        keywordsIdx: [],
        searchText: ""
    },
    builder => {
        builder.addCase(LOAD_TASKS, state => {
            state.taskLoading = true;
            state.currentTask = {};
            state.contents = {};
            state.contentIdx = [];
        }).addCase(LOAD_TASKS_FAIL, (state, action) => {
            state.taskLoading = false;
        }).addCase(LOAD_TASKS_SUCCESS, (state, action) => {
            state.taskLoading = false;
            state.tasks = keyBy(action.payload, "taskId");
            state.taskIdx = action.payload.map(t => t.taskId);
        }).addCase(SELECT_TASK, (state, action) => {
            state.currentTask = action.payload;
            state.amendItem = null;
            state.amendments = {};
            state.selectedAltKeyword = -1;
            state.altKeywords = [];
            state.selectedContents = [];
        }).addCase(LOAD_CONTENTS, state => {
            state.loading = true;
        }).addCase(LOAD_CONTENTS_SUCCESS, (state, action) => {
            state.loading = false;
            state.contents = action.payload.contents;
            state.contentIdx = action.payload.contentIdx;
            state.authors = action.payload.authors;
            state.featured = action.payload.featured;
            state.altKeywords = action.payload.altKeywords;
        }).addCase(LOAD_CONTENTS_FAIL, state => {
            state.loading = false;
        }).addCase(UPDATE_CONTENT, (state, action) => {
            const { path, payload, target, base } = action;
            const amendments = state.amendments;
            amendments[path] = { op: "update", path, value: payload, base };
            state.amendments = amendments;
            state.amendItem = target;
        }).addCase(UPDATE_FILTER, (state, action) => {
            state.filter = action.payload;
        }).addCase(CONTENT_FILTERED, (state, action) => {
            state.contentIdx = action.payload;
        }).addCase(TASK_FILTERED, (state, action) => {
            state.taskIdx = action.payload;
        }).addCase(SHOW_ALERT, (state, action) => {
            const payload = action.payload;
            state.dialogOpen = true;
            state.dialogTitle = payload.title;
            state.dialogContent = payload.content;
            state.dialogConfirmType = payload.confirmType;
        }).addCase(DISMISS_ALERT, state => {
            state.dialogOpen = false;
            state.dialogTitle = "";
            state.dialogContent = "";
            state.dialogConfirmType = null;
        }).addCase(TASK_UPDATED, (state, action) => {
            const payload = action.payload;
            const tasks = state.tasks;
            tasks[payload.taskId].status = payload.status;
            state.tasks = tasks;
            state.currentTask.status = payload.status;
        }).addCase(CONTENT_UPDATED, (state, action) => {
            const { payload } = action;
            const contents = state.contents;
            contents[payload.id].detail = payload;
            state.contents = contents;
            state.amendments = {};
            state.amendItem = null;
            state.processing = false;
            state.currentItem = null;
            state.isEdited = false;
        }).addCase(CONTENT_PROVISIONED, (state, action) => {
            const { payload } = action;
            const contents = state.contents;
            contents[payload.id].detail.provision = 1;
            state.contents = contents;
            state.processing = false;
            state.currentItem = null;
        }).addCase(FEATURED_UPDATED, (state, action) => {
            state.featured = action.payload;
        }).addCase(UPDATE_AUTHOR, (state, action) => {
            const { target, author } = action;
            const amendments = state.amendments;
            amendments["author"] = { op: "update", path: "author", value: author.id };
            state.amendments = amendments;
            state.amendItem = target;
        }).addCase(SAVE_CONTENT, state => {
            state.currentItem = state.amendItem;
            state.processing = true;
        }).addCase(APPROVE_CONTENT, (state, action) => {
            state.currentItem = action.id;
            state.processing = true;
        }).addCase(PROVISION_CONTENT, (state, action) => {
            state.currentItem = action.id;
            state.processing = true;
        }).addCase(UPDATE_CONTENT_ISEDIT, (state, action) => {
            state.isEdited = action.payload;
        }).addCase(SELECT_ALT_KEYWORD, (state, action) => {
            state.loading = true;
            state.selectedAltKeyword = action.payload.id;
        }).addCase(SELECT_ALT_KEYWORD_SUCCESS, (state, action) => {
            state.loading = false;
            state.contents = action.payload.contents;
            state.contentIdx = action.payload.contentIdx;
        }).addCase(SELECT_ALT_KEYWORD_FAIL, state => {
            state.loading = false;
        }).addCase(LINK_REL_KEYWORD, (state, action) => {
            state.processing = true;
            state.linkingItem = action.content;
        }).addCase(LINK_REL_KEYWORD_SUCCESS, (state, action) => {
            state.processing = false;
            state.linkedItem = action.content;
        }).addCase(LINK_REL_KEYWORD_FAIL, state => {
            state.processing = false;
            state.linkedItem = -1;
        }).addCase(DESELECT_CONTENT, (state, action) => {
            state.processing = true;
        }).addCase(DESELECT_CONTENT_SUCCESS, (state, action) => {
            const contents = state.contents;
            contents[action.payload.id].detail.status = -10;
            state.currentItem = null;
            state.processing = false;
            const deselectArr = contents[action.payload.id].deselect;
            if(deselectArr && !deselectArr.includes(action.payload.channel)) {
                deselectArr.push(action.payload.channel);
                contents[action.payload.id].deselect = deselectArr;
            }else {
                contents[action.payload.id].deselect = [action.payload.channel]
            }
        })
        .addCase(LOAD_KEYWORDS_LIST_SUCCESS, (state, action) => {
            state.keywords = action.payload.keywords;
            state.keywordsIdx = action.payload.keywordsIdx;
        })
        .addCase(LINK_CONTENT_TO_KEYWORD, (state, action) => {
            state.loading = true;
        })
        .addCase(LINK_CONTENT_TO_KEYWORD_SUCCESS, (state, action) => {
            state.loading = false;
            const contents = state.contents;
            contents[action.payload.id].keywords = action.payload.arr;
        })
        .addCase(LINK_CONTENT_TO_KEYWORD_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(UNLINK_KEYWORD, (state, action) => {
            state.loading = true;
        })
        .addCase(UNLINK_KEYWORD_SUCCESS, (state, action) => {
            state.loading = false;
            const contents = state.contents;
            contents[action.payload.id].keywords = action.payload.arr;
        })
        .addCase(UNLINK_KEYWORD_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(SET_CONTENT_PRIMARY, (state, action) => {
            state.loading = true;
        })
        .addCase(SET_CONTENT_PRIMARY_SUCCESS, (state, action) => {
            state.loading = false;
            const contentIdx = state.contentIdx;
            const newContentIdx = contentIdx.filter(x => x !== action.payload.id)
            const contents = Object.values(state.contents);
            const newContents = contents.filter(x => x.id !== action.payload.id)
            state.contentIdx = newContentIdx;
            state.contents = keyBy(newContents, "id");
            state.dialogOpen = true;
            state.dialogTitle = "Update Primary Keyword";
            state.dialogContent = "Primary keyword for content "+action.payload.id+" was updated";
        })
        .addCase(SET_CONTENT_PRIMARY_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(SET_CONTENT_ORIGINAL, (state, action) => {
            state.loading = true;
        })
        .addCase(SET_CONTENT_ORIGINAL_SUCCESS, (state, action) => {
            const contents = state.contents;
            contents[action.payload.id].isOriginal = action.payload.newOriginal;
            state.contents = contents;
            state.loading = false;
        })
        .addCase(SET_CONTENT_ORIGINAL_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(SEARCH_CONTENT_BY_SLUG, (state, action) => {
            state.loading = true;
            state.searchText = action.payload;
        }).addCase(SEARCH_CONTENT_BY_SLUG_SUCCESS, (state, action) => {
            state.loading = false;
            state.contents = action.payload.contents;
            state.contentIdx = action.payload.contentIdx;
            state.authors = action.payload.authors;
            state.featured = action.payload.featured;
            state.altKeywords = [];
        }).addCase(SEARCH_CONTENT_BY_SLUG_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(SEARCH_TASK, (state, action) => {
            state.taskLoading = true;
        })
        .addCase(SEARCH_TASK_SUCCESS, (state, action) => {
            state.taskLoading = false;
            state.taskIdx = action.payload.map(t => t.taskId);
        })
        .addCase(CONTENT_DETAIL_LOADED, (state, action) => {
            const contents = state.contents;
            const channelArr = contents[action.payload.id].channels;
            if(channelArr && !channelArr.includes(action.payload.channel)) {
                channelArr.push(action.payload.channel);
                contents[action.payload.id].channels = channelArr;
            }else {
                contents[action.payload.id].channels = [action.payload.channel]
            }
            contents[action.payload.id].detail = {
                id: action.payload.id,
                title: action.payload.title,
                description: null,
                author: null,
                status: 0,
                lastSyncAt: null,
                pendingRevisions: [],
                provision: 0
            }
        })
        .addCase(SELECT_CONTENT, (state, action)=>{
            state.selectedContents = selectElement(action.payload, state.selectedContents)
        })
        .addCase(SELECT_ALL_CONTENT, (state, action) => {
            state.selectedContents = selectAllElement(action.payload, state.selectedContents)
        })
        .addCase(SET_TASK_FROM_MANAGER, (state, action) => {
            state.taskLoading = true;
        })
        .addCase(SET_TASK_FROM_MANAGER_SUCCESS, (state, action) => {
            state.taskLoading = false;
            state.tasks = action.payload.tasks;
            state.taskIdx = action.payload.taskIdx;
        }).addCase(EDITOR_SEARCH_KEYWORDS, state => {
            state.taskLoading = true;
            state.currentTask = {};
            state.contents = {};
            state.contentIdx = [];
        }).addCase(EDITOR_SEARCH_KEYWORDS_SUCCESS, (state, action) => {
            state.taskLoading = false;
            state.tasks = keyBy(action.payload, "keywordName");
            state.taskIdx = action.payload.map(t => t.keywordName);
        })
        .addCase(SET_FEATURE_CONTENT_FAIL, (state, action) => {
            state.dialogOpen = true;
            state.dialogTitle = "Feature Content";
            state.dialogContent = action.payload;
        })
        ;
    }
);

export const loadTasks = createVibes({
    type: LOAD_TASKS,
    latest: true,
    processOptions: {
        successType: LOAD_TASKS_SUCCESS,
        failType: LOAD_TASKS_FAIL
    },
    async process({ getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const bases = state.system.bases;
        const channelPrimary = project.primary;
        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query($channel: String) {
                    tasks:assignedTasks(channel: $channel) {
                        taskId
                        channel
                        keyword { id name slug }
                        status
                        base
                    }
                }`,
                variables: {
                    channel
                }
            }
        });

        if (data && data.tasks) {
            return data.tasks.map(task => flatten(task))
            .map(x => {
                // if(x.base) {
                //     x.baseName = bases[x.base].name
                // }
                if(!x.base) {
                    x.base = channelPrimary;
                }
                x.baseName = bases[x.base].name;
                return x;
            });
        } else {
            throw new Error("Fail to load tasks", data);
        }
    }
});

export const loadContents = createVibes({
    type: LOAD_CONTENTS,
    latest: true,
    processOptions: {
        successType: LOAD_CONTENTS_SUCCESS,
        failType: LOAD_CONTENTS_FAIL
    },
    async process({ action, axios, getState }) {
        const state = getState();
        const altKw = state.editor.selectedAltKeyword;
        const kwId = altKw > 0 ? altKw : action.payload.id;
        const base = action.payload.base;
        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query loadContents($input: FeatureContentInput, $filter: ContentFilter,$target:Int){
                    featureContent(input: $input) {keywordId contentId channel baseId}
                    contents(filter:$filter) {id primaryBaseKeyword title description keyword {id name} keywords {id name} isOriginal slug editFor applyFor provisionFor channels altDescription thumbnail fileToken assets createdBy type deselect}
                    contentDetails(filter:$filter) {id primaryBaseKeyword title description author status lastSyncAt pendingRevisions{id amendments {op path value base} status} provision production {liveId status} }
                    authors {id name}
                    alternativeKeywords(target: $target) {id name}
                }`,
                variables: {
                    input: {
                        keywordId: kwId,
                        channel: action.payload.channel,
                        baseId: base
                    },
                    filter: {
                        category: kwId,
                        channel: action.payload.channel,
                        take: 1000,
                        base
                    },
                    target: action.payload.id
                }
            }
        });
        if (data && data.contents) {
            const contents = keyBy(data.contents, "id");
            const contentIdx = data.contents.map(c => c.id);
            for (const cd of data.contentDetails) {
                const content = contents[cd.id];
                if(content) content.detail = cd;
                // contents[cd.id]["detail"] = cd;
            }
            const authors = keyBy(data.authors, "id");
            const altKeywords = data.alternativeKeywords;
            let featured = null;
            if(data.featureContent) {
                featured = data.featureContent?.contentId;
            }
            return { contents, contentIdx, authors, featured, altKeywords };
        } else {
            throw new Error("Fail to load content");
        }
    }
});

export const filterContents = createVibes({
    type: [UPDATE_FILTER, LOAD_CONTENTS_SUCCESS, SELECT_ALT_KEYWORD_SUCCESS, SEARCH_CONTENT_BY_SLUG_SUCCESS],
    latest: true,
    processOptions: {
        successType: CONTENT_FILTERED
    },
    async process({ getState }) {
        const state = getState();
        const selectedProject = state.system.selectedProject;
        const projects = state.system.projects;
        const channel = projects[selectedProject].channel;
        const filter = state.editor.filter;
        let contents = Object.values(state.editor.contents);
        const altKwId = state.editor.selectedAltKeyword;

        if(altKwId > 0) {
            contents = contents.filter(c => c.provisionFor?.includes(channel));
        }

        switch (filter) {
            case "AC":
                return contents.filter(c =>
                    !(c.editFor?.includes(channel) || c.detail?.pendingRevisions?.length > 0)
                    && !c.applyFor?.includes(channel) && !c.provisionFor?.includes(channel)
                ).map(c => c.id);
            case "PA":
                return contents.filter(c => c.editFor?.includes(channel) || c.detail?.pendingRevisions?.length > 0).map(c => c.id);
            case "PP":
                return contents.filter(c => c.applyFor?.includes(channel) && !c.provisionFor?.includes(channel)).map(c => c.id);
            case "PD":
                return contents.filter(c => c.provisionFor?.includes(channel)).map(c => c.id);
            default:
                return contents.map(c => c.id);
        }

    }
});
export const filterTask = createVibes({
    type: UPDATE_FILTER,
    latest: true,
    processOptions: {
        successType: TASK_FILTERED
    },
    async process({ getState }) {
        const state = getState();
        const filter = state.editor.filter;
        const tasks = Object.values(state.editor.tasks);

        switch (filter) {
            case "AC":
                return tasks.filter(c => c.status === 1).map(c => c.taskId);
            case "PA":
                return tasks.filter(c => c.status === 2).map(c => c.taskId);
            case "PP":
                return tasks.filter(c => c.status === 3).map(c => c.taskId);
            // case "PD":
            //     return tasks.filter(c => c.status === 0).map(c => c.taskId);
            default:
                return tasks.map(c => c.taskId);
        }
    }
});

export const loadContentDetail = createVibes({
    type: LOAD_CONTENT_DETAIL,
    latest: true,
    processOptions: {
        successType: CONTENT_DETAIL_LOADED
    },
    async process({ action, axios }) {
        console.log("Action", action);
        const { id, channel, base } = action.payload;
        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query content($id: Int, $filter:ContentFilter) {
                    contentDetail(id: $id, filter: $filter) {id title}
                }`,
                variables: {
                    id,
                    filter: {
                        channel,
                        base
                    }
                }
            }
        });
        if (data && data.contentDetail) {
            return {id: data.contentDetail.id, channel, title: data.contentDetail.title};
        }
    }
});

export const updateContent = createVibes({
    type: SAVE_CONTENT,
    processOptions: {
        successType: CONTENT_UPDATED
    },
    async process({ action, axios, getState }) {
        console.log("Action", action);
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const { amendItem, amendments } = getState().editor;
        const {primaryBaseKeyword} = action.payload;

        const payload = {
            method: "POST",
            data: {
                query: `mutation updateContent($input: AmendmentsInput){
                    detail: amendContentDetail(input: $input) {id title description author pendingRevisions{id amendments {op path value base} status} provision production {liveId status}}
                }`,
                variables: {
                    input: {
                        id: amendItem,
                        channel,
                        amendments: Object.values(amendments),
                        primaryBaseKeyword
                    }
                }
            }
        };
        console.log(payload);
        const { data } = await axios.request(payload);
        if (data && data.detail) {
            return data.detail;
        }
    }
});
export const approveContent = createVibes({
    type: APPROVE_CONTENT,
    processOptions: { successType: CONTENT_UPDATED },
    async process({ action, axios }) {
        const { id, channel, primaryBaseKeyword } = action.payload;
        const payload = {
            method: "POST",
            data: {
                query: `mutation approveContent($channel:String, $contentId: Int, $base: Int) {
                    detail: applyContentDetailAmendment(channel:$channel, contentId: $contentId, base:$base) {id title description author pendingRevisions{id amendments {op path value base} status} provision production {liveId status}}
                }`,
                variables: {
                    channel, contentId: id, base: primaryBaseKeyword
                }
            }
        };
        console.log(payload);
        const { data } = await axios.request(payload);
        if (data && data.detail) {
            return data.detail;
        }
    }
});
export const provisionContent = createVibes({
    type: PROVISION_CONTENT,
    processOptions: { successType: CONTENT_PROVISIONED },
    async process({ action, axios }) {
        const { id, channel, primaryBaseKeyword } = action.payload;
        const payload = {
            method: "POST",
            data: {
                query: `mutation provisionContent($id: Int, $channel: String, $base: Int) {
                    content:provisionContentDetail(id:$id, channel:$channel, base:$base) {id}
                }`,
                variables: {
                    id, channel, base: primaryBaseKeyword
                }
            }
        };
        console.log(payload);
        const { data } = await axios.request(payload);
        if (data && data.content) {
            return { id, provision: 1 };
        }
    }
});
export const submitTask = createVibes({
    type: SUBMIT_TASK,
    processOptions: {
        successType: TASK_UPDATED
    },
    async process({ action, axios, getState }) {
        console.log("Action", action);
        const { taskId, status } = action.payload;
        const items = getState().editor.selectedContents;
        const payload = {
            method: "POST",
            data: {
                query: `mutation submitTask($taskId: Int, $status: Int, $items: [Int]) {
                    updateTaskStatus(taskId: $taskId, status: $status, items: $items)
                }`,
                variables: {
                    taskId, status, items
                }
            }
        };
        console.log(payload);
        const { data } = await axios.request(payload);
        if (data && data.updateTaskStatus) {
            return { taskId, status: status + 1 };
        }
    }
});
export const setFeaturedContent = createVibes({
    type: SET_FEATURE_CONTENT,
    // processOptions: {
    //     successType: FEATURED_UPDATED
    // },
    async process({ action, axios, getState }, dispatch, done) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const task = state.editor.currentTask;
        const kwId = task.id;
        const baseId = task?.base;
        const contentId = action.payload;

        const { data, errors } = await axios.request({
            method: "POST",
            data: {
                query: `mutation setFeatureContent($input: FeatureContentInput) {
                    resp:setFeatureContent(input: $input) {keywordId contentId channel}}`,
                variables: {
                    input: {keywordId: kwId, contentId, channel, baseId}
                }
            }
        });
        if (data && data.resp) {
            // return contentId;
            dispatch({ type: FEATURED_UPDATED, payload: contentId });
        } else {
            let errMsg = "Failed to set feature content"
            if(errors && errors.length > 0 && errors[0].message) {
                errMsg = errors[0].message;
            }
            dispatch({ type: SET_FEATURE_CONTENT_FAIL, payload: errMsg });
        }
        done();
    }
});
export const deselectContent = createVibes({
    type: DESELECT_CONTENT,
    processOptions: {
        successType: DESELECT_CONTENT_SUCCESS
    },
    async process({ action, axios }) {
        const { id, channel, primary, primaryBaseKeyword } = action.payload;
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `mutation deselectContent($input: ContentDetailInput) {
                    deselectContent(contentDetail: $input)
                }`,
                variables: {
                    input: {
                        id,
                        channel,
                        primaryBaseKeyword
                    }
                }
            }
        });

        if (data && data.deselectContent) {
            return {id, channel};
        } else {
            throw new Error();
        }
    }
});
export const loadKeywordsList = createVibes({
    type: LOAD_KEYWORDS_LIST,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: LOAD_KEYWORDS_LIST_SUCCESS
    },
    async process({ action, axios }) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                    }
                  }
                  `,
                variables: {
                    filter: {
                        sort: "clicks",
                        take: 30,
                        search: action.search
                    }
                }
            }
        });

        if (data && data.keywords) {
            const keywords = data.keywords;
            return {
                loading: false,
                keywords: _.keyBy(keywords, "id"),
                keywordsIndex: keywords.map(k => k.id)
            };
        } else {
            throw new Error();
        }
    }
});
export const linkContentToKeyword = createVibes({
    type: LINK_CONTENT_TO_KEYWORD,
    processOptions: {
        successType: LINK_CONTENT_TO_KEYWORD_SUCCESS
    },
    async process({ action, axios }) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `mutation link($contentId: Int, $keywordId: Int, $baseId: Int) {
                    addContentToKeyword(contentId: $contentId, keywordId: $keywordId, baseId: $baseId)
                }`,
                variables: {
                    contentId: action.payload.content,
                    keywordId: action.payload.keyword,
                    baseId: action.payload?.base
                },
            },
        });

        if (data && data.addContentToKeyword) {
            return {
                id: action.payload.content,
                arr: action.payload.arr
            };
        } else {
            throw new Error();
        }
    }
});
export const unlinkContentFromKeyword = createVibes({
    type: UNLINK_KEYWORD,
    processOptions: {
        successType: UNLINK_KEYWORD_SUCCESS
    },
    async process({ action, axios }) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `mutation unlink($contentId: Int, $keywordId: Int, $baseId: Int) {
                    removeContentFromKeyword(contentId: $contentId, keywordId: $keywordId, baseId: $baseId)
                }`,
                variables: {
                    contentId: action.payload.content,
                    keywordId: action.payload.keyword,
                    baseId: action.payload?.base
                },
            },
        });

        if (data && data.removeContentFromKeyword) {
            return {
                id: action.payload.content,
                arr: action.payload.arr
            };
        } else {
            throw new Error();
        }
    }
});
export const setContentPrimary = createVibes({
    type: SET_CONTENT_PRIMARY,
    processOptions: {
        successType: SET_CONTENT_PRIMARY_SUCCESS
    },
    async process({ action, axios, getState }) {
        const state = getState();
        const keywordId = state.editor.currentTask.id;

        const { id, data } = action.payload;

        const res = await axios.request({
            method: "post",
            data: {
                query: `mutation updatePrimary($input: ContentDetailInput, $keywordId: Int) {
                    updateContentDetailPrimary(contentDetail: $input, keywordId: $keywordId)
                }`,
                variables: {
                    keywordId: keywordId,
                    input: {
                        id: id,
                        primary: data.id
                    }
                }
            }
        });

        if (res && res.data && res.data.updateContentDetailPrimary) {
            return {id};
        } else {
            throw new Error();
        }
    }
});

export const loadAltContents = createVibes({
    type: SELECT_ALT_KEYWORD,
    latest: true,
    processOptions: {
        successType: SELECT_ALT_KEYWORD_SUCCESS,
        failType: SELECT_ALT_KEYWORD_FAIL
    },
    async process({ action, getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;

        const kwId = action.payload.id > 0 ? action.payload.id : state.editor.currentTask.id;
        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query loadAltContents($filter: ContentFilter){
                    contents(filter:$filter) {id keyword {id name} keywords {id name} isOriginal slug editFor applyFor provisionFor channels altDescription thumbnail assets createdBy type}
                    contentDetails(filter:$filter) {id title description author status lastSyncAt pendingRevisions{id amendments {op path value base} status} provision production {liveId status} }
                }`,
                variables: {
                    filter: {
                        category: kwId,
                        channel,
                        take: 1000
                    }
                }
            }
        });

        if (data && data.contents) {
            const contents = keyBy(data.contents, "id");
            const contentIdx = data.contents.map(c => c.id);
            for (const cd of data.contentDetails) {
                if (contents[cd.id])
                    contents[cd.id]["detail"] = cd;
            }
            return { contents, contentIdx };
        } else {
            throw new Error("Fail to load content");
        }
    }
});

export const addContentToKeyword = createVibes({
    type: LINK_REL_KEYWORD,
    latest: true,
    processOptions: {
        successType: LINK_REL_KEYWORD_SUCCESS,
        failType: LINK_REL_KEYWORD_FAIL
    },
    async process({ action, axios }) {
        const { keyword, content } = action.payload;
        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query addContentToKeyword($keyword: Int, $content: Int) {
                    result: addContentToKeyword(contentId: $content, keywordId: $keyword)
                }`,
                variables: {
                    keyword,
                    content
                }
            }
        });
        if (data && data.result) {
            return { keyword, content };
        } else {
            throw new Error("Failed to Link");
        }
    }
});

export const setContentOriginal = createVibes({
    type: SET_CONTENT_ORIGINAL,
    processOptions: {
        successType: SET_CONTENT_ORIGINAL_SUCCESS
    },
    async process({ action, axios }) {
        const { id, primaryBaseKeyword, channel } = action.payload;
        const newOriginal = 10;//isOriginal === 10 ? null : 10;
        const res = await axios.request({
            method: "post",
            data: {
                query: `mutation setIsOriginalContent($id: Int, $isOriginal: Int, $channel: String, $base: Int) {
                    setIsOriginalContent(id: $id, isOriginal: $isOriginal, channel: $channel, base: $base)
                }`,
                variables: {
                    id,
                    isOriginal: newOriginal,
                    channel,
                    base: primaryBaseKeyword
                }
            }
        });

        if (res && res.data && res.data.setIsOriginalContent) {
            return {id, newOriginal};
        } else {
            throw new Error();
        }
    }
});

export const searchContentBySlug = createVibes({
    type: SEARCH_CONTENT_BY_SLUG,
    latest: true,
    processOptions: {
        successType: SEARCH_CONTENT_BY_SLUG_SUCCESS
    },
    async process({ action, getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        let featured = state.editor.featured;
        if(action.payload && action.payload.length > 0) {
            const { data } = await axios.request({
                method: "POST",
                data: {
                    query: `query loadContents($filter: ContentFilter){
                        contents(filter:$filter) {id keyword {id name} keywords {id name} featured { id channel featured} isOriginal slug editFor applyFor provisionFor channels altDescription thumbnail fileToken assets createdBy type
                            details {id channel title description author status lastSyncAt pendingRevisions{id amendments {op path value base} status} provision production {liveId status} }
                        }
                        authors {id name}
                    }`,
                    variables: {
                        filter: {
                            slug: action.payload,
                            take: 1000
                        }
                    }
                }
            });
    
            if (data && data.contents) {
                const contentsArr = data.contents.map(x => {
                    if(x.featured && x.featured.length > 0) {
                        const featuredArr = x.featured.filter(z => z.channel === channel);
                        if(featuredArr.length >0) {
                            featured = featuredArr[0].featured;
                        }
                    }
                    if(x.details && x.details.length > 0) {
                        const detail = x.details.filter(y => y.channel === channel);
                        x.detail = detail && detail.length > 0 ? detail[0] : null;
                    }
                    return x
                })
                const contents = keyBy(contentsArr, "id");
                const contentIdx = contentsArr.map(c => c.id);
                const authors = keyBy(data.authors, "id");
                return { contents, contentIdx, authors, featured };
            } else {
                throw new Error("Fail to load content");
            }     
        } else {
            return { contents:{}, contentIdx:[], authors: {} };
        }
    }
});

export const searchTask = createVibes({
    type: SEARCH_TASK,
    processOptions: {
        successType: SEARCH_TASK_SUCCESS
    },
    async process({ action, getState, axios }) {
        const state = getState();
        const tasks = Object.values(state.editor.tasks);

        const newTasks = tasks.filter((task) => {
            // return task.name?.startsWith(action.payload);
            const regEx = new RegExp('^' + action.payload, 'i')
            return regEx.test(task.name)
        })

        return newTasks;
    }
});

export const setTaskFromManager = createVibes({
    type: SET_TASK_FROM_MANAGER,
    debounce: 300,
    async process({ action, getState, axios  } , dispatch, done ) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const {id, name} = action.payload;

        const currentTask = {id, name, channel};
        dispatch({ type: SET_TASK_FROM_MANAGER_SUCCESS,  
            payload: {tasks: {[id]: {id, name, channel}}, taskIdx: [id]},
        });

        dispatch({ type: SELECT_TASK, payload: currentTask });
        dispatch({ type: LOAD_CONTENTS, payload: currentTask })
        done();
    }
});

export const editorSearchKeyword = createVibes({
    type: EDITOR_SEARCH_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: EDITOR_SEARCH_KEYWORDS_SUCCESS,
    },
    async process({getState, action, axios}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];
        const bases = getState().system.bases;

        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                    }
                  }
                  `,
                // query: `query loadKeywords($filter: KeywordFilter) {
                //     keywords(filter: $filter) {
                //       id
                //       name
                //       keywordDetailData {
                //         id
                //         provisioned
                //         available
                //         lived
                //         lastSyncAt
                //         channel
                //         taskAssigned
                //     }
                //     }
                //   }
                //   `,
                variables: {
                    filter: {
                        search: action.payload,
                        channel: project.channel
                    }
                }
            }
        });

        if (data && data.keywords) {
            // return data.keywords.map(keyword => 
            //     flatten(keyword)
            // ).map(x => {
            //     x.taskId = x.id; // set keyword id as taskId (Task onclick will check taskId)
            //     return x
            // });
            const combineKeywords = [];
            data.keywords.map(keyword => {
                Object.values(bases).map(base => {
                    combineKeywords.push({
                        keywordName: keyword.name + " [" + base.name + "]",
                        base: base.id,
                        baseName: base.name,
                        id: keyword.id,
                        name: keyword.name,
                        taskId: keyword.id, // set keyword id as taskId (Task onclick will check taskId),
                        channel: project.channel
                    })
                })
            })

        return combineKeywords;
            // return data.keywords.map(keyword => 
            //     flatten(keyword)
            // ).map(x => {
            //     x.taskId = x.id; // set keyword id as taskId (Task onclick will check taskId)
            //     return x
            // });
        }
    }
});

