import { useEffect, useState } from "react";
import { Box, CircularProgress, Skeleton } from "@mui/material";

export const ImageBox = ({ src, width, height, alt }) => {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setReady(true);
        };
    }, [src]);
    return <Box sx={{
        width,
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        border: '1px solid #ddd'
    }}>
        <Box sx={{
            zIndex: -10,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)" }}>
            <CircularProgress variant={"indeterminate"} color={"info"}/>
        </Box>
        <img src={src}
             style={{
                 height: "100%",
                 maxWidth: "100%",
                 objectFit: "contain"
             }}
             alt={alt}
        />
    </Box>;
};