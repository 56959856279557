import {createReducer} from "@reduxjs/toolkit";
import {GET_TOTAL_NUMBER, GET_TOTAL_NUMBER_SUCCESS} from "../actions/content";
import {createVibes} from "@cosmos/vibes";
import Axios from "axios";

export const contentReducer = createReducer(
    {
       total: {
           content: 0,
           keyword: 0
       },
        title: "",
        description: "",
        altDescription: "",
        metaTitle: "",
        metaDescription: "",
        links: [],
        loading: false,
        notify: false,
        notice: ""
    }
    ,
    builder => {
        builder.addCase(GET_TOTAL_NUMBER, (state) => {
            state.loading = true;
        }).addCase(GET_TOTAL_NUMBER_SUCCESS, (state, action) => {
            state.total.content=action.payload.total.content
            state.total.keyword=action.payload.total.keyword
        })
    })

export const getTotalNumber = createVibes({
    type: GET_TOTAL_NUMBER,
    processOptions: {
        successType: GET_TOTAL_NUMBER_SUCCESS
    },
    async process({getState, axios}) {
        try {
            const {data: responseData} = await axios.request({
                method: "post",
                data: {
                    query: 
                    `query getTotalNo{ 
                        countContentNo
                        countKeywordNo               
                    }`,
                },
            });
  
            return {
             
                    total: {
                        content: responseData.countContentNo,
                        keyword: responseData.countKeywordNo

                    }
            
            }
        }
        catch(error){
            console.log(error)
        }
    }
})