import {createReducer} from "@reduxjs/toolkit";
import {
    REL_KW_SEARCH_KEYWORDS, REL_KW_SEARCH_KEYWORDS_SUCCESS, REL_KW_SEARCH_KEYWORDS_FAIL,
    REL_KW_SELECT_KW,
    REL_KW_SEARCH_REL_KEYWORDS, REL_KW_SEARCH_REL_KEYWORDS_SUCCESS, REL_KW_SEARCH_REL_KEYWORDS_FAIL,
    REL_KW_ADD, REL_KW_ADD_SUCCESS, REL_KW_ADD_FAIL, 
    REL_KW_REMOVE, REL_KW_REMOVE_SUCCESS, REL_KW_REMOVE_FAIL,
    CLOSE_REL_KW_NOTIFICATION, CLEAR_REL_KW_DATA,
    REL_KW_CREATE_NEW_KEYWORD, REL_KW_CREATE_NEW_KEYWORD_SUCCESS, REL_KW_CREATE_NEW_KEYWORD_FAIL,
    UPDATE_REL_KW_DIALOG_FLAG
} from "../actions/relatedkeyword";
import {createVibes} from "@cosmos/vibes";

export const relatedKeywordReducer = createReducer(
    {
        keyword: {
            id: null,
            name: "",
            related: []
        },
        keywordsOpt: [],
        opLoading: false,
        relKwOpt: [],
        relKwOploading: false,
        loading:false, 
        notice: "",
        notify: false,
        noticeType: "error",
        dialogLoading: false,
        onDialog: false,
        newKeyword: ""
    }
    ,
    builder => {
        builder.addCase(REL_KW_SEARCH_KEYWORDS, state => {
            state.opLoading = true;
        }).addCase(REL_KW_SEARCH_KEYWORDS_SUCCESS, (state, action) => {
            state.opLoading = false;
            state.keywordsOpt = action.payload.keywordsOpt;
        }).addCase(REL_KW_SEARCH_KEYWORDS_FAIL, state => {
            state.opLoading = false;
        }) 
        .addCase(REL_KW_SELECT_KW, (state, action) => {
            state.keyword = action.payload;
        })
        .addCase(REL_KW_SEARCH_REL_KEYWORDS, state => {
            state.relKwOpLoading = true;
        }).addCase(REL_KW_SEARCH_REL_KEYWORDS_SUCCESS, (state, action) => {
            state.relKwOpLoading = false;
            state.relKwOpt = action.payload.relKwOpt;
        }).addCase(REL_KW_SEARCH_REL_KEYWORDS_FAIL, state => {
            state.relKwOpLoading = false;
        }).addCase(REL_KW_ADD, (state, action) => {
            state.loading = true;
        })
        .addCase(REL_KW_ADD_SUCCESS, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = "Linked related keyword successfully";
            state.noticeType = "success";
            const keyword = state.keyword;
            keyword.relatedKeywords = action.payload.arr;
        })
        .addCase(REL_KW_ADD_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice;
        })
        .addCase(REL_KW_REMOVE, (state, action) => {
            state.loading = true;
        })
        .addCase(REL_KW_REMOVE_SUCCESS, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = "Unlink related keyword successfully";
            state.noticeType = "success";
            const keyword = state.keyword;
            keyword.relatedKeywords = action.payload.arr;
        })
        .addCase(REL_KW_REMOVE_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice;
        })
        .addCase(CLOSE_REL_KW_NOTIFICATION, (state, action) => {
            state.notify = false;
            state.notice = "";
            state.noticeType = "error";
        })
        .addCase(CLEAR_REL_KW_DATA, (state, action) => {
            state.keyword = {
                id: null,
                name: "",
                related: []
            }
        })
        .addCase(REL_KW_CREATE_NEW_KEYWORD, (state, action) => {
            state.dialogLoading = true;
        })
        .addCase(REL_KW_CREATE_NEW_KEYWORD_SUCCESS, (state, action) => {
            state.dialogLoading = false;
            state.notify = true;
            state.notice = "New keyword created successfully";
            state.noticeType = "success"
        })
        .addCase(REL_KW_CREATE_NEW_KEYWORD_FAIL, (state, action) => {
            state.dialogLoading = false;
            state.notify = true;
            state.notice = action.payload.notice;
        })
        .addCase(UPDATE_REL_KW_DIALOG_FLAG, (state, action) => {
            state.onDialog = action.payload.onDialog;
            state.newKeyword = action.payload.newKeyword;
        })
        
    }
)

export const relKwSearchKeywords = createVibes({
    type: REL_KW_SEARCH_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: REL_KW_SEARCH_KEYWORDS_SUCCESS,
        failType: REL_KW_SEARCH_KEYWORDS_FAIL
    },
    async process({getState, action, axios}) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                      relatedKeywords {id name}
                    }
                  }
                  `,
                variables: {
                    filter: {
                        search: action.search
                    }
                }
            }
        });

        if (data && data.keywords) {
            const keywords = data.keywords;
            return {
                keywordsOpt: keywords,
            };
        } else {
            throw new Error();
        }
    }
});

export const searchRelKeywords = createVibes({
    type: REL_KW_SEARCH_REL_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: REL_KW_SEARCH_REL_KEYWORDS_SUCCESS,
        failType: REL_KW_SEARCH_REL_KEYWORDS_FAIL
    },
    async process({getState, action, axios}) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                      relatedKeywords {id name}
                    }
                  }
                  `,
                variables: {
                    filter: {
                        search: action.search
                    }
                }
            }
        });

        if (data && data.keywords) {
            const keywords = data.keywords;
            return {
                relKwOpt: keywords,
            };
        } else {
            throw new Error();
        }
    }
});

export const addRelatedKeyword = createVibes({
    type: REL_KW_ADD,
    async process({ getState, action, axios }, dispatch, done) {
        try {
            const res = await axios.request({
                method: "post",
                data: {
                    query: `mutation addRelated($keywordId: Int, $keywordToMap: Int) {
                        addRelatedKeyword(keywordId: $keywordId, keywordToMap: $keywordToMap)
                    }`,
                    variables: {
                        keywordId: action.payload.keywordId,
                        keywordToMap: action.payload.keywordToMap.id,
                    },
                },
            });

            if(res) {
                if(res.data && res.data.addRelatedKeyword) {
                    dispatch({
                        type: REL_KW_ADD_SUCCESS,
                        payload: {
                            arr: action.payload.arr
                        }
                    });
                }else {
                    let errMsg = "Unable to link related keyword";
                    if(res?.errors && res?.errors.length > 0 && res?.errors[0]?.message) {
                        errMsg = res?.errors[0]?.message
                    }
                    throw (errMsg);
                }
            }else {
                throw ("Failed to link keyword")
            }
        } catch (err) {
            console.log("111-error", err)
            dispatch({
                type: REL_KW_ADD_FAIL,
                payload: {
                    notice: err
                }
            }); 
        }
    }
});

export const removeRelatedKeyword = createVibes({
    type: REL_KW_REMOVE,
    async process({ getState, action, axios }, dispatch, done) {
        try {
            const res = await axios.request({
                method: "post",
                data: {
                    query: `mutation removeRelated($keywordId: Int, $keywordToUnMap: Int) {
                        removeRelatedKeyword(keywordId: $keywordId, keywordToUnMap: $keywordToUnMap)
                    }`,
                    variables: {
                        keywordId: action.payload.keywordId,
                        keywordToUnMap: action.payload.keywordToUnMap.id,
                    },
                },
            });

            if(res) {
                if(res.data && res.data.removeRelatedKeyword) {
                    dispatch({
                        type: REL_KW_REMOVE_SUCCESS,
                        payload: {
                            arr: action.payload.arr
                        }
                    });
                }else {
                    let errMsg = "Unable to unlink related keyword";
                    if(res?.errors && res?.errors.length > 0 && res?.errors[0]?.message) {
                        errMsg = res?.errors[0]?.message
                    }
                    throw (errMsg);
                }
            }else {
                throw ("Failed to unlink keyword")
            }
        } catch (err) {
            console.log("111-error", err)
            dispatch({
                type: REL_KW_REMOVE_FAIL,
                payload: {
                    notice: err
                }
            }); 
        }
    }
});

export const relKWCreateNewKeyword = createVibes({
    type: REL_KW_CREATE_NEW_KEYWORD,
    async process({ getState, action, axios }, dispatch, done) {
        try {
            const { name, keywordId } = action.payload;
            const res = await axios.request({
                method: "post",
                data: {
                    query: `mutation updateKeyword($input: KeywordInput) {
                        updateKeyword(input: $input)
                    }`,
                    variables: {
                        input: {
                            name
                        }
                    }
                },
            });

            if(res) {
                if(res.data && res.data.updateKeyword) {
                    dispatch({
                        type: REL_KW_CREATE_NEW_KEYWORD_SUCCESS,
                    });
                    dispatch({
                        type: UPDATE_REL_KW_DIALOG_FLAG,
                        payload: {onDialog: false, newKeyword:""}
                    });
                }else {
                    let errMsg = "Unable to create new keyword";
                    if(res?.errors && res?.errors.length > 0 && res?.errors[0]?.message) {
                        errMsg = res?.errors[0]?.message
                    }
                    throw (errMsg);
                }
            }else {
                throw ("Failed to create keyword")
            }
        } catch (err) {
            dispatch({
                type: REL_KW_CREATE_NEW_KEYWORD_FAIL,
                payload: {
                    notice: err
                }
            }); 
        }
    }
});

