import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Card, CardActions, CardContent, CardHeader, CircularProgress, Fab,
    LinearProgress, Button,
    Paper, TextField, Toolbar, Typography
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import {
    ADMIN_RESET,
    EDIT_USER_INFO,
    LOAD_USERS,
    SAVE_USER,
    UPDATE_USER_MANAGER,
    UPDATE_USER_PROJECTS,
    SELECT_USER_INFO,
    UPDATE_USER
} from "../../actions/admin";
import { DataTable } from "@cosmos/runes/esm";
import AutoSizer from "react-virtualized-auto-sizer";

export const UserPage = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.admin.loading);
    const processing = useSelector(state => state.admin.processing);
    const code = useSelector(state => state.admin.code);
    const users = useSelector(state => state.admin.users);
    const userIdx = useSelector(state => state.admin.userIdx);
    const projects = useSelector(state => state.system.projects);
    const userInfo = useSelector(state => state.admin.userInfo);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [managers, setManagers] = useState([]);
    useEffect(() => {
        dispatch({ type: LOAD_USERS });
    }, [dispatch]);
    useEffect(() => {
        setData(userIdx.map(idx => users[idx]));
        setManagers(Object.values(users).filter(u => (u.manager === 0)));
    }, [users, userIdx, setData]);
    useEffect(() => {
        setColumns([{
            title: "User ID",
            name: "userId",
            sortBy: "userId",
            width: "10%"
        }, {
            title: "Name",
            name: "name",
            sortBy: "name",
            width: "20%"
        },
            {
                title: "Manager",
                name: "",
                width: "20%",
                cell: ({ rowData, sx }) => {
                    return <Box sx={sx}>{rowData.manager === 0 ? "Yes" : ""}</Box>;
                }
            },
            {
                title: "Manage By",
                name: "manager",
                sortBy: "manager",
                cell: ({ rowData, sx }) => (<Box sx={sx}>{users[rowData.manager]?.name}</Box>)
            }]);
    }, [users, setColumns]);
    const handleSave = (event) => {
        if(code !== 0) {
            dispatch({type: ADMIN_RESET})
        } else {
            if(userInfo.userId > 0) {
                // call update user mutation
                dispatch({ type: UPDATE_USER })
            }else {
                // call create user mutation
                dispatch({ type: SAVE_USER })
            }
        }
    }

    function handleUserSelect({rowData}) {
        if(rowData) {
            dispatch({
                type: SELECT_USER_INFO,
                payload: rowData
            })
        }
    }
    return (
        <Paper sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column"
        }}>
            <Toolbar sx={{ bgcolor: "secondary.light" }} variant="dense">
                <Typography variant="h5">User Management</Typography>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant="indeterminate" color="primary"/>}
            </Box>
            <Box sx={{
                flex: 1,
                display: "flex"
            }}>
                <Box sx={{ flex: 2 }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <DataTable
                                width={width}
                                height={height}
                                data={data}
                                columns={columns}
                                size={"small"}
                                rowOnClick={handleUserSelect}
                            />
                        )}
                    </AutoSizer>
                </Box>
                <Box sx={{
                    flex: 1,
                    backgroundColor: "#F3F9FF"
                }}>
                    <Card>
                        <CardHeader title="User Info"/>

                        { userInfo.userId > 0 && 
                             <Box sx={{display: "flex", justifyContent: "space-between", padding: "0px 20px 0px 20px", alignItems: "center"}}>
                                <Box>
                                    ID: {userInfo.userId}
                                </Box>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={()=> dispatch({ type: ADMIN_RESET, isReset: true})}
                                >
                                    Clear
                                </Button>
                            </Box>
                            
                        } 
                        <CardContent sx={{ flexDirection: "column" }}>
                            {userInfo.userId < 0 && <TextField sx={{ marginBottom: "1em" }} fullWidth
                                       value={userInfo.username}
                                       onChange={(event) => dispatch({
                                           type: EDIT_USER_INFO,
                                           path: "username",
                                           payload: event.currentTarget.value
                                       })}
                                       label="login"
                                       helperText={"The login username"}/>}
                            <TextField sx={{ marginBottom: "1em" }} fullWidth
                                       value={userInfo.name}
                                       disabled={userInfo.userId > 0 ? true : false}
                                       onChange={(event) => dispatch({
                                           type: EDIT_USER_INFO,
                                           path: "name",
                                           payload: event.currentTarget.value
                                       })}
                                       label="Name"
                                       helperText={"The full name of this user"}/>
                            {userInfo.userId < 0 && <TextField sx={{ marginBottom: "1em" }} fullWidth
                                       value={userInfo.email}
                                       onChange={(event) => dispatch({
                                           type: EDIT_USER_INFO,
                                           path: "email",
                                           payload: event.currentTarget.value
                                       })}
                                       label="Email"
                                       helperText={"The email address of this user, password will send to this user by email"}/>}
                            {userInfo.userId < 0 && <Autocomplete sx={{ marginBottom: "1em" }}
                                          value={userInfo.manager}
                                          onChange={(event, value) => dispatch({
                                              type: UPDATE_USER_MANAGER,
                                              payload: value
                                          })}
                                          isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                                          options={managers}
                                          getOptionLabel={manager => manager.name}
                                          renderInput={(params) => <TextField {...params} label="Manage By"
                                                                              helperText={"Select Manager for this user, keep it empty if this is a manager"}/>}
                            />}
                            <Autocomplete
                                value={userInfo.projects}
                                onChange={(event, value) => dispatch({
                                    type: UPDATE_USER_PROJECTS,
                                    payload: value
                                })}
                                options={Object.values(projects)}
                                getOptionLabel={project => project.name}
                                multiple
                                renderInput={(params) => <TextField {...params} label="Projects"
                                                                    helperText={"Select the project this user will work on"}/>}
                            />
                        </CardContent>
                        <CardActions sx={{ justifyContent: "end" }}>
                            <Box sx={{ position: "relative" }}>
                                <Fab variant={"extended"} color={code > 0 ? "success" : "info"} disabled={processing}
                                     onClick={handleSave}
                                >{code > 0 ? <CheckIcon sx={{ marginRight: "0.5rem" }}/> :
                                    <SendIcon sx={{ marginRight: "0.5rem" }}/>} {userInfo.userId > 0 ? "Edit" : "Add"} User</Fab>
                                {/* {userInfo.userId > 0 && <Fab variant={"extended"} color={code > 0 ? "success" : "info"} disabled={processing}
                                     onClick={handleUpdate}
                                ><SendIcon sx={{ marginRight: "0.5rem" }}/> Update User</Fab>} */}
                                {processing && (<CircularProgress
                                    size={24}
                                    sx={{
                                        color: "info.main",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px"
                                    }}
                                />)}</Box>
                        </CardActions>
                    </Card>
                </Box>
            </Box>
        </Paper>
    );
};