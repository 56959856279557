import {createReducer} from "@reduxjs/toolkit";
import {
    LOAD_LISTINGS, LOAD_LISTINGS_SUCCESS,
    SELECT_LISTING_PROFILE, EDIT_LISTING_VALUE,
    SUBMIT_LISTING, SUBMIT_LISTING_SUCCESS, SUBMIT_LISTING_FAIL,
    CLEAR_EDIT_SELECT_LISTING, CREATE_LISTING_CLOSE_NOTIFICATION,
    UPDATE_LISTING_STATUS, UPDATE_LISTING_STATUS_SUCCESS, UPDATE_LISTING_STATUS_FAIL,
    SELECT_LISTING_BASE
} from "../actions/listing";
import {createVibes} from "@cosmos/vibes";
import moment from "moment";

export const listingReducer = createReducer(
    {
        data: {},
        filters: {
            text: ""
        },
        selectedBase: 1,
        listingDetail: {
            id: null,
            type: "",
            validFrom: moment(new Date()).format("YYYY-MM-DD"),
            validTo: moment(new Date()).add(14, 'days').format("YYYY-MM-DD"),
            items: [],
            baseData: {
                id: "",
                name: ""
            },
            keywords: []
        },
        loading:false, 
        index:[],
        notice: "",
        notify: false,
        noticeType: "error" 
    }
    ,
    builder => {
        builder.addCase(LOAD_LISTINGS, (state) => {
            state.loading = true;
        })
        .addCase(LOAD_LISTINGS_SUCCESS, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.index = action.payload.index;
        })
        .addCase(SELECT_LISTING_PROFILE, (state, action) => {
            state.listingDetail = action.payload.rowData;
        }).addCase(EDIT_LISTING_VALUE, (state, action) => {
            state.listingDetail = {
                ...state.listingDetail,
                ...action.payload
            }
        })
        .addCase(SUBMIT_LISTING, (state, action) => {
            state.loading = true;
        })
        .addCase(SUBMIT_LISTING_SUCCESS, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice;
            state.noticeType = "success";
        })
        .addCase(SUBMIT_LISTING_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload;
            state.noticeType = "error";
        })
        .addCase(CLEAR_EDIT_SELECT_LISTING, (state, action) => {
            state.listingDetail = {
                id: null,
                type: "",
                validFrom: moment(new Date()).format("YYYY-MM-DD"),
                validTo: moment(new Date()).add(14, 'days').format("YYYY-MM-DD"),
                items: [],
                baseData: {
                    id: "",
                    name: ""
                },
                keywords: []
            }
        })
        .addCase(CREATE_LISTING_CLOSE_NOTIFICATION, (state, action) => {
            state.notify = false;
            state.notice = "";
            state.noticeType = "error";
        })
        .addCase(UPDATE_LISTING_STATUS, (state, action) => {
            state.loading = true;
        })
        .addCase(UPDATE_LISTING_STATUS_SUCCESS, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice;
            state.noticeType = "success";
        })
        .addCase(UPDATE_LISTING_STATUS_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice;
            state.noticeType = "error";
        })
        .addCase(SELECT_LISTING_BASE, (state, action) => {
            state.loading = true;
            state.selectedBase = action.payload.id;
        })
    }
)

export const loadListings = createVibes({
    type: [LOAD_LISTINGS, SELECT_LISTING_BASE],
    latest: true,
    processOptions: {
        successType: LOAD_LISTINGS_SUCCESS,
    },
    async process({ getState, action, axios }) {
        try {			
            const selectedProject = getState().system.selectedProject;
            const projects = getState().system.projects;
            const project = projects[selectedProject];
            const channel = project?.channel;
            const bases = getState().system.bases;
            const selectedBase = getState().listing.selectedBase;
			const { data } = await axios.request({
                method: "post",
                data: {
                    query: `query loadListings($filter: ListingFilter) { 
  						listings(filter: $filter) { 
    						id 
							channel 
                            base
							status 	
							type 
							validFrom 
							validTo 
							createdBy 
							createdAt 
							approvedBy 
							approvedAt 
							syncedAt 
							items
							keywords {
								id 
								name
							}
  						}
                    } `,
                    variables: {
                        filter:{
							channel:channel,
                            base: selectedBase
						}
                    },
                },
            });

			let { listings } = data;
            const projectArr = Object.values(projects);
            const channelProject = _.keyBy(projectArr,'channel');

            listings = listings.map(x => {
                if(x.channel) {
                    x.channelName = channelProject[x.channel].name;
                }

                x.baseData = {id: "", name: ""};
                if(x.base) {
                    x.baseData = bases[x.base];
                }
                
                return x;
            })

			return {
				data: _.keyBy(listings,'id'),
				index: listings.map(list=>list.id)
			}
		} catch (err) {
            throw err;
        }
    },    
});

export const submitListing = createVibes({
    type: SUBMIT_LISTING,
    async process({ getState, action, axios }, dispatch, done) {
        try {	
            const selectedProject = getState().system.selectedProject;
            const projects = getState().system.projects;
            const project = projects[selectedProject];
            const channel = project?.channel;
			const listingDetail = getState().listing.listingDetail;

			const { data } = await axios.request({
                method: "post",
                data: {
                    query: `mutation ($input: ListingInput) { 
                        upsertListing(input: $input) { id channel type keywords {id name} } }
                    `,
                    variables: {
						input: {
                            id: listingDetail?.id,
                            type: listingDetail.type,
                            channel,
                            base: listingDetail.base,
                            validFrom: listingDetail.validFrom,
                            validTo: listingDetail.validTo,
                            items: listingDetail?.items ? listingDetail?.items : []
                        }
                    }
                },
            });

            const { upsertListing } = data;

            if (upsertListing && upsertListing.id) {
				const action = upsertListing && upsertListing.id ? "updated" : "created";
                dispatch({ type: SUBMIT_LISTING_SUCCESS,  
                    payload: {
                        notice: "Listing "+action+" successfully",
                    },
                });
                dispatch({ type: CLEAR_EDIT_SELECT_LISTING });
                dispatch({ type: LOAD_LISTINGS });
            }else {
                const msg = "Failed to insert/update listing"
                dispatch({
                    type: SUBMIT_LISTING_FAIL,
                    payload: msg,
                });
            }
		} catch (error) {
            console.log("error", error)
            if (error?.response) {
                dispatch({
                    type: SUBMIT_LISTING_FAIL,
                    payload: "Fail to insert/update listing",
                });
            } else {
                dispatch({
                    type: SUBMIT_LISTING_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
    },     
});

export const updateListingStatus = createVibes({
    type: UPDATE_LISTING_STATUS,
    async process({ getState, action, axios }, dispatch, done) {
        try {	
            const selectedProject = getState().system.selectedProject;
            const projects = getState().system.projects;
            const project = projects[selectedProject];
            const channel = project?.channel;
			const { id, status, base } = action.payload;
			
			const { data } = await axios.request({
                method: "post",
                data: {
                    query: `mutation updateListingStatus($channel: String,$listingId: Int, $status: Int, $base: Int) {
						updateListingStatus(channel: $channel, listingId: $listingId, status: $status, base: $base) }
                    `,
                    variables: {
						channel,
						listingId: id,
						status,
                        base
                    }
                },
            });

            const { updateListingStatus } = data;

            if (updateListingStatus) {
                dispatch({ type: UPDATE_LISTING_STATUS_SUCCESS,  
                    payload: {
                        notice: "Status updated successfully",
                    },
                });
                dispatch({ type: CLEAR_EDIT_SELECT_LISTING });
                dispatch({ type: LOAD_LISTINGS });
            }

		} catch (error) {
            console.log("Update Listing Status Error", error)
            dispatch({
                type: UPDATE_LISTING_STATUS_FAIL,
                payload: "Failed to update status"
            });
        }
    },     
});

