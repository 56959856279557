import {createReducer} from "@reduxjs/toolkit";
import {EDIT_CHECKER_INPUT_VALUE, SUBMIT_KEYWORD_CHECKER,SUBMIT_KEYWORD_CHECKER_SUCCESS,
    SUBMIT_KEYWORD_CHECKER_FAIL, RELOAD_KEYWORD_CHECKER,LOAD_BASE_KEYWORDS,LOAD_BASE_KEYWORDS_SUCCESS, CLOSE_CHECKER_NOTIFICATION} from "../actions/checker";
import {createVibes} from "@cosmos/vibes";

export const checkerReducer = createReducer(
    {
        text: "",
        searchResult: [],
        baseKeywords : [],
        loading: false,
        notice: false,
        notify: false,
        noticeType: ""
    }
    ,
    builder => {
        builder.addCase(SUBMIT_KEYWORD_CHECKER, (state) => {
            state.loading = true;
        }).addCase(SUBMIT_KEYWORD_CHECKER_SUCCESS, (state, action) => {
            state.searchResult=action.payload.searchResult
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice
            state.noticeType = 'success'
        }).addCase(SUBMIT_KEYWORD_CHECKER_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload.notice
            state.noticeType = 'error'
        }).addCase(EDIT_CHECKER_INPUT_VALUE, (state, action) => {
            state.text=action.payload
        }).addCase(RELOAD_KEYWORD_CHECKER, (state, action) => {
            state.text=''
            state.searchResult=[]
        }).addCase(LOAD_BASE_KEYWORDS, (state, action) => {
        }).addCase(LOAD_BASE_KEYWORDS_SUCCESS, (state, action) => {
            state.baseKeywords=action.payload
        }).addCase(CLOSE_CHECKER_NOTIFICATION, (state, action) => {
            state.notify=false
        })
    })

    export const loadBaseKeywords = createVibes({
        type: LOAD_BASE_KEYWORDS,
        async process({ getState, axios  } , dispatch, done ) {
            const state = getState();
            const selected = state.system.selectedProject;
            const project = state.system.projects[selected];
            const channel = project.channel;
         
            try {
                const data = await axios.request({
                    method: "post",
                    data: {
                        query: 
                        `query loadKeywords($filter: KeywordFilter) {
                            baseKeywords(filter: $filter) {
                                id
                                volume
                                difficulty
                            }
                        }
                        `,
                        variables: {
                            filter: {
                                channel: channel
                            },
                        },
                    },
                });
    
                const {baseKeywords} = data.data

                dispatch({type: LOAD_BASE_KEYWORDS_SUCCESS,
                          payload:baseKeywords})
    
            } catch (error) {
                console.log(error)
            }
            done();
        },
    })

export const submitKeywordChecker = createVibes({
    type: SUBMIT_KEYWORD_CHECKER,
    async process({ getState, axios  } , dispatch, done ) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const { text, baseKeywords } = state.checker;
       
        const keyword = text.match(new RegExp(/(\w.*)/, "g"));
     
        try {
            const { data } = await axios.request({
                method: "post",
                data: {
                    query: `query keywordChecker($channel: String, $keywords:[String]) {
                        keywordChecker(channel: $channel, keywords:$keywords) {
                         query
                         status
                         keyword {
                                id
                                name
                                totalInventory
                                totalLived
                         }
                         baseChannelKws {
                            id channel base name
                        }
                       }}`,
                    variables: {
                        channel: channel,
                        keywords: keyword
                    },
                },
            });

            const baseKeyObj = _.keyBy(baseKeywords, "id");
            
            const { keywordChecker } = data

            if (keywordChecker.length ===0) {
                dispatch({ type: SUBMIT_KEYWORD_CHECKER_FAIL,  
                    payload: {
                        notice: "Fail to check keyword",
                    },
                });
            } else {

                keywordChecker.map(x => {
                    if(x.baseChannelKws) {
                        x.baseChannelKws.map(y=> {
                            // background base = 4
                            if(y.base === 4) {
                                x.backgroundKw = y;
                            }
                            // background base = 5
                            if(y.base === 5) {
                                x.pictureKw = y;
                            }
                        })
                        return x
                    }
                })

                dispatch({ type: SUBMIT_KEYWORD_CHECKER_SUCCESS,  
                    payload: {
                        notice: "Check keyword Successfully",
                        searchResult: keywordChecker
                    },
                });
                
            }
        } catch (error) {
           console.log(error)
        }
        done();
    },
})