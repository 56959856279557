import { createReducer } from "@reduxjs/toolkit";
import { LINK_CONTENT_TO_KEYWORD, LOAD_TASKS_FAIL, LOAD_TASKS_SUCCESS } from "../actions/content";

export const tagCloudReducer = createReducer(
    {
        tags: {},
        items: [],
    },
    builder => {
        builder
            .addCase(LINK_CONTENT_TO_KEYWORD, (state, action) => {
                const keyword = action.payload.obj;
                const tags = state.tags;
                const key = '' + keyword.id;
                const items = state.items;
                items.unshift(key);
                if(tags.hasOwnProperty(key)) {
                    tags[key].count ++;
                } else {
                    tags[key] = {
                        value: keyword.name,
                        count: 1,
                        key: keyword.id
                    }
                }
                if(Object.keys(tags).length > 9) {
                    const kk = items.pop();
                    const tag = tags[kk];
                    tag.count--;
                    if(tag.count === 0) {
                        delete tags[kk];
                    }
                }
            });
    }
);



