import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Card,
    Container, Divider,
    IconButton, Link,
    Menu,
    MenuItem, MenuList, Tab, Tabs,
    Toolbar,
    Typography,
    Paper,
    Grow,
    Popper,
    ListItemText
} from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import MenuIcon from "@mui/icons-material/Menu";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import moment from "moment";
import { features } from "../system";
import { LinkBehavior } from "../support";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { LOAD_PROJECTS, SET_PROJECT } from "../actions/system";
import { createSelector } from "@reduxjs/toolkit";
import { AUTH_LOGOUT, isAuthorized, Authorization } from "@cosmos/runes";

function MiniDateTime() {
    const [date, setDate] = useState(moment().format("YYYY/MM/DD"));
    const [time, setTime] = useState(moment().format("HH:mm:ss"));
    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment();
            setDate(now.format("YYYY-MM-DD"));
            setTime(now.format("HH:mm:ss"));
        }, 1000);
        return () => clearInterval(interval);
    });
    const sxDisplay = {
        fontSize: "100%",
        fontWeight: "500",
        lineHeight: 1.2
    };
    return <Card sx={{
        display: "flex",
        heigth: "42px",
        bgcolor: "primary.light",
        alignItems: "center",
        padding: 1,
        gap: 2
    }}>
        <AccessTimeTwoToneIcon fontSize={"large"}/>
        <Box>
            <Typography sx={sxDisplay}>{date}</Typography>
            <Typography sx={sxDisplay}>{time}</Typography>
        </Box>
    </Card>;
}

function MiniProfile() {

    const dispatch = useDispatch();
    const username = useSelector(state => state.auth.username);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [project, setProject] = useState({});
    const [pList, setPList] = useState([]);
    const projects = useSelector(state => state.system.projects);
    const selected = useSelector(state => state.system.selectedProject);
    const permissions = useSelector(state => state.auth.permissions);
    useEffect(() => {
        if(selected > 0) {
            setProject(projects[selected]);
        }
    }, [setProject, projects, selected]);
    useEffect(() => {

        if (isAuthorized(permissions, ["pixel.admin"])) {
            setPList(Object.values(projects));
        } else {
            const permitted = permissions.filter(p => p.startsWith("pixel.project")).map(p => p.substr(14));
            setPList(Object.values(projects).filter(p => permitted.includes(p.code.toLowerCase())));
        }
    }, [setPList, projects, permissions]);

    const selectProject = (projectId) => {
        dispatch({ type: SET_PROJECT, payload: { projectId } });
    };

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return <Box sx={{ minWidth: "12rem" }}>
        <Box sx={{ bgcolor: "" }}>
            <Card
                sx={{
                    ml: 2,
                    minWidth: "8rem",
                    height: "42px",
                    bgcolor: "primary.light",
                    color: "common.black",
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    pl: 2
                }}
                onClick={handleToggle}
            >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center", bgcolor: "" }}>
                    <AccountCircleTwoToneIcon fontSize={"large"}/>
                    <Box>
                        <Typography sx={{
                            fontSize: "100%",
                            fontWeight: "500",
                            lineHeight: 1.2,
                            marginTop: "6px",
                            textTransform: "capitalize"
                        }}>{username}</Typography>
                        <Typography>{project?.name}</Typography>
                    </Box>
                </Box>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {pList.map(proj => (
                        <MenuItem key={`p-${proj.channel}`} onClick={(event) => {
                            selectProject(proj.channel);
                            handleClose(event);
                        }}>{proj.name}</MenuItem>))}
                    <Divider/>
                    <MenuItem onClick={() => {
                        dispatch({ type: AUTH_LOGOUT });
                    }}>Logout</MenuItem>
                </Menu>
            </Card>
            <IconButton
                sx={{ display: { xs: "flex", md: "none" } }}
                size="large"
                color="inherit"
            >
                <AccountCircleTwoToneIcon/>
            </IconButton>
        </Box>
    </Box>;
}

function NavTabs({ items }) {
    const [current, setCurrent] = useState(0);
    const changeTab = (event, newValue) => {
        setCurrent(newValue);
    };
    const location = useLocation();
    useEffect(() => {
        const idx = location.pathname.indexOf("/", 1);
        const path = (idx > -1) ? location.pathname.substr(0, idx) : location.pathname;
        const index = features.findIndex(f => f.path === path);
        setCurrent(index > -1 ? index : 0);
    });

    return (
        <Tabs sx={{
            "& .MuiTabs-indicator": {
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent"
            },
            "& .MuiTabs-indicatorSpan": {
                maxWidth: 40,
                width: "100%",
                backgroundColor: "rgba(145,202,249, 0.9)"
            }
        }}
              TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan"/> }}
              value={current}
              onChange={changeTab}>
            {items.map(item => (
                <Authorization key={item.name} allowed={item.permission} failback={<></>}>
                    <Tab sx={{
                        color: "rgba(180,180,180, 0.9)",
                        "&.active": {
                            color: "rgba(180, 240, 250, 0.8)"
                        },
                        "&.Mui-selected": {
                            color: "rgba(180, 240, 250, 0.8)"
                        }

                    }} component={LinkBehavior} label={item.name} to={item.path}/>
                </Authorization>
            ))}
        </Tabs>
    );
}

export function Navigation() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: LOAD_PROJECTS });
    }, [dispatch]);

    const [anchorElNav, setAnchorElNav] = useState(null);
    const openNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const closeNavMenu = (event) => {
        setAnchorElNav(null);
    };

    return <AppBar position="static" sx={{
        color: "common.white",
        bgcolor: "primary.dark"
    }}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: { xs: "none", md: "flex" },
                        alignItems: "baseline",
                        fontWeight: 700,
                        color: "inherit",
                        textDecoration: "none",
                        "& span": {
                            fontSize: "65%",
                            marginLeft: "0.5rem"
                        }
                    }}>
                    Pixel Synopsis <span>{process.env.REACT_APP_VERSION}</span>
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                    <IconButton
                        aria-controls="appbar-menu"
                        aria-haspopup="true"
                        size="large"
                        color="inherit"
                        onClick={openNavMenu}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="appbar-menu"
                        anchorEl={anchorElNav}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={closeNavMenu}
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        {features.map(feature => (
                            <MenuList sx={theme => ({ borderTop: `1px solid ${theme.palette.divider}` })}
                                      key={`mm-${feature.name}`}>
                                <MenuItem key={`mm-${feature.name}-major`}>
                                    <Link to={feature.path} sx={{ color: "common.black", textDecoration: "none" }}
                                          onClick={closeNavMenu}>
                                        {feature.name.toUpperCase()}
                                    </Link>
                                </MenuItem>
                                <Divider/>
                                {feature.children &&
                                    feature.children.map(f => (
                                        <MenuItem key={`mm-${feature.name}-${f.name}`}>
                                            <Link to={`${feature.path}${f.path}`}
                                                  sx={{ color: "common.black", textDecoration: "none", paddingLeft: 4 }}
                                                  onClick={closeNavMenu}>
                                                {f.name.toUpperCase()}
                                            </Link>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        ))}
                    </Menu>
                </Box>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                        mr: 2,
                        display: { xs: "flex", md: "none" },
                        flexGrow: 1,
                        fontWeight: 700,
                        color: "inherit",
                        textDecoration: "none"
                    }}>
                    Cosmos
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, ml: 8 }}>
                    <NavTabs items={features}/>
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                    <MiniDateTime/>
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
                    <MiniProfile/>
                </Box>
            </Toolbar>
        </Container>
    </AppBar>;
}