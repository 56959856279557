import {createReducer} from "@reduxjs/toolkit";
import {
    KW_RLS_SEARCH_KEYWORDS, KW_RLS_SEARCH_KEYWORDS_SUCCESS, KW_RLS_SEARCH_KEYWORDS_FAIL,
    KW_RLS_LOAD_KW, KW_RLS_LOAD_KW_SUCCESS, KW_RLS_LOAD_KW_FAIL,
    CLEAR_KW_RLS_DATA
} from "../actions/keywordrelationship";
import {createVibes} from "@cosmos/vibes";
import fetch from 'node-fetch';

export const keywordRelationshipReducer = createReducer(
    {
        keyword: {
            id: null,
            name: "",
        },
        keywordsOpt: [],
        opLoading: false,
        loading:false,
        downStreamKwArr: [],
        upStreamKwArr: []
    }
    ,
    builder => {
        builder.addCase(KW_RLS_SEARCH_KEYWORDS, state => {
            state.opLoading = true;
        }).addCase(KW_RLS_SEARCH_KEYWORDS_SUCCESS, (state, action) => {
            state.opLoading = false;
            state.keywordsOpt = action.payload.keywordsOpt;
        }).addCase(KW_RLS_SEARCH_KEYWORDS_FAIL, state => {
            state.opLoading = false;
        }) 
        .addCase(KW_RLS_LOAD_KW, (state, action) => {
            state.loading = true;
            state.keyword = action.payload;
            state.downStreamKwArr = [],
            state.upStreamKwArr = []
        })
        .addCase(KW_RLS_LOAD_KW_SUCCESS, (state, action) => {
            state.loading = false;
            const downStreamKeywords  = state.downStreamKwArr;
            const upStreamKeywords  = state.upStreamKwArr;
            let combinedKws = []
            // display related result (grey color) first, then suggestion result (green)
            if(action.payload.isRelated) {
                combinedKws = [...action.payload.downStreamKwArr, ...downStreamKeywords];
            }else {
                combinedKws = [...downStreamKeywords, ...action.payload.downStreamKwArr];
            }
            state.downStreamKwArr = combinedKws;
            state.upStreamKwArr = action.payload.upStreamKwArr ? action.payload.upStreamKwArr : upStreamKeywords;
        })
        .addCase(KW_RLS_LOAD_KW_FAIL, (state, action) => {
            state.loading = false;
        })
        .addCase(CLEAR_KW_RLS_DATA, (state, action) => {
            state.keyword = {
                id: null,
                name: "",
            };
            state.downStreamKwArr = [];
            state.upStreamKwArr = [];
        })
    }
)

export const kwRlsSearchKeywords = createVibes({
    type: KW_RLS_SEARCH_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: KW_RLS_SEARCH_KEYWORDS_SUCCESS,
        failType: KW_RLS_SEARCH_KEYWORDS_FAIL
    },
    async process({getState, action, axios}) {
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                    }
                  }
                  `,
                variables: {
                    filter: {
                        search: action.search
                    }
                }
            }
        });

        if (data && data.keywords) {
            const keywords = data.keywords;
            return {
                keywordsOpt: keywords,
            };
        } else {
            throw new Error();
        }
    }
});

export const loadSelectedKwRls = createVibes({
    type: KW_RLS_LOAD_KW,
    latest: true,
    processOptions: {
        successType: KW_RLS_LOAD_KW_SUCCESS,
        failType: KW_RLS_LOAD_KW_FAIL
    },
    async process({getState, action, axios}) {
        const {id} = getState().keywordRelationship.keyword;
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadSingleKeyword($filter: KeywordFilter) {
                    keyword(filter: $filter) {
                        id
                        name
                        relatedKeywords {id name}
                        upStreamRelatedKeywords {id name}
                    }
                  }
                  `,
                variables: {
                    filter: {
                        id
                    }
                }
            }
        });
        if (data && data.keyword) {
            const keyword = data.keyword;
            const relatedKeywords =  keyword.relatedKeywords.map(x => {
                x.related = true;
                return x;
            })

            const upStreamRelatedKeywords =  keyword.upStreamRelatedKeywords.map(x => {
                x.related = true;
                return x;
            })

            return {
                downStreamKwArr: relatedKeywords,
                upStreamKwArr: upStreamRelatedKeywords,
                isRelated: true  // set isRelated flag to true, so data will show on top 
            };
        } else {
            throw new Error();
        }
    }
});

export const loadSelectedKwSuggestion = createVibes({
    type: KW_RLS_LOAD_KW,
    processOptions: {
        successType: KW_RLS_LOAD_KW_SUCCESS,
        failType: KW_RLS_LOAD_KW_FAIL
    },
    async process({getState, action, axios}) {
        try {	
            const {id} = getState().keywordRelationship.keyword;
            const target = `${process.env.REACT_APP_NOMAD_DOMAIN}/related/keyword/${id}`;

            const suggestRes = await fetch(target);
            const suggestData = await suggestRes.json();

            return {
                downStreamKwArr: suggestData,
                isRelated: false  // set isRelated flag to false, so data will show at bottom 
            };

        } catch (err) {
            console.log(err)
            throw err;
        }
    }
});
