import {
    Autocomplete,
    Box,
    Button, Checkbox,
    Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, FormControl, FormControlLabel, InputLabel,
    LinearProgress, MenuItem,
    Paper, Select,
    Skeleton, Switch,
    TextField,
    Toolbar, Tooltip,
    Typography,
    InputAdornment
} from "@mui/material";
import { ImageBox, SearchBox } from "../../components";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    APPROVE_CONTENT,
    DISMISS_ALERT, LOAD_CONTENT_DETAIL,
    LOAD_CONTENTS,
    LOAD_TASKS, PROVISION_CONTENT, SAVE_CONTENT,
    SELECT_TASK, SET_FEATURE_CONTENT, SHOW_ALERT, SUBMIT_TASK, UPDATE_AUTHOR,
    UPDATE_CONTENT, UPDATE_CONTENT_ISEDIT,
    UPDATE_FILTER, SELECT_ALT_KEYWORD, EDITOR_SEARCH_KEYWORDS,
    DESELECT_CONTENT, LOAD_KEYWORDS_LIST,
    LINK_CONTENT_TO_KEYWORD, UNLINK_KEYWORD, SET_CONTENT_PRIMARY, SEARCH_TASK, SELECT_CONTENT, SELECT_ALL_CONTENT,
    SET_CONTENT_ORIGINAL
} from "../../actions/content";
import { Authorization } from "@cosmos/runes/esm";
import { TagCloud } from "react-tagcloud";
import { isAllSelected } from "@cosmos/runes";
import { orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { SET_CONTENT_UPLOAD_PRIMARY } from "../../actions/upload";
import CancelIcon from '@mui/icons-material/Cancel';

function KeywordCell({ style, index, data }) {
    const dispatch = useDispatch();
    const [item, setItem] = useState({});
    const handleSelect = (event) => {
        dispatch({ type: SELECT_TASK, payload: item });
    };
    useEffect(() => {
        setItem(data[index]);
    }, [index, data, setItem]);
    const handleSubmit = (event) => {
        dispatch({ type: SUBMIT_TASK, payload: { taskId: item.taskId, status: item.status } });
    };
    return <Box style={style} sx={{ display: "flex", borderBottom: "1px solid #DDD", alignItems: "center" }}>
        {item ?
            <>
                <Box sx={{ flexGrow: 1, padding: "0.25rem" }} onClick={handleSelect}>
                    <Typography variant={"h6"}>{item.name}</Typography>
                    <Typography sx={{fontSize: "8px"}}>{item?.baseName}</Typography>
                </Box>
                <Box>
                    {item.status === 1 &&
                        <Tooltip title={"Submit to Approve"}><Button variant={"outlined"}
                                                                     onClick={handleSubmit}>Active</Button></Tooltip>
                    }
                    {item.status === 2 &&
                        <Authorization allowed={["pixel.content.approval"]} failback={<Box
                            sx={{
                                bgcolor: "warning.light",
                                borderRadius: "0.25rem",
                                padding: "0.5rem"
                            }}>Pending</Box>}>
                            <Tooltip title={"Pending for Approval"}>
                                <Button variant={"outlined"}
                                        onClick={handleSubmit}>
                                    Pending
                                </Button>
                            </Tooltip>
                        </Authorization>}
                    {item.status === 3 &&
                        <Authorization allowed={["pixel.content.provision"]} failback={<Box
                            sx={{ bgcolor: "info.light", borderRadius: "4px", padding: "0.5rem" }}>Approved</Box>}>
                            <Tooltip title={"Pending for Provision"}>
                                <Button variant={"outlined"}
                                        onClick={handleSubmit}>
                                    Approved
                                </Button>
                            </Tooltip>
                        </Authorization>}
                    {item.status === 4 &&
                        <Box sx={{ bgcolor: "success.light", borderRadius: "4px", padding: "0.5rem 0.2rem", fontSize: "smaller" }}>POST PROCESSING</Box>
                    }
                    {item.status === 0 &&
                        <Box sx={{ bgcolor: "success.light", borderRadius: "4px", padding: "0.5rem" }}>Completed</Box>
                    }
                </Box></>
            : <Box></Box>}
    </Box>;
}

function ContentEditor({ style, index, data }) {
    const dispatch = useDispatch();
    const selectedProject = useSelector(state => state.system.selectedProject);
    const featured = useSelector(state => state.editor.featured);
    const projects = useSelector(state => state.system.projects);
    const amendItem = useSelector(state => state.editor.amendItem);
    const currentTask = useSelector(state => state.editor.currentTask);
    const authors = useSelector(state=>state.editor.authors);
    const processing = useSelector(state=>state.editor.processing);
    const currentItem = useSelector(state=>state.editor.currentItem);
    const amendments = useSelector(state=>state.editor.amendments);
    const isEdited = useSelector(state=>state.editor.isEdited);
    const selectedAltKeyword = useSelector(state=>state.editor.selectedAltKeyword);
    const linkingItem  = useSelector(state=>state.editor.linkingItem);
    const linkedItem  = useSelector(state=>state.editor.linkedItem);
    const [editMode, setEditMode] = useState(0);
    const [focusOn, setFocusOn] = useState(false);
    const keywords = useSelector(state=>state.editor.keywords);
    const [codes, setCodes] = useState({});
    const [item, setItem] = useState({});
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [channel, setChannel] = useState(null);
    const [title, setTitle] = useState("");
    const [orgTitle, setOrgTitle] = useState("");
    const [description, setDescription] = useState("");
    const [orgDescription, setOrgDescription] = useState("");
    const [author, setAuthor]= useState(null);
    const [altTxt, setAltTxt] = useState("");
    const [titleUpdated, setTitleUpdated] = useState(false);
    const [descUpdated, setDescUpdated] = useState(false);
    const [authorUpdated, setAuthorUpdated] = useState(false);
    const [onPreview, setOnPreview] = useState(false);
    const [authorOption, setAuthorOption] = useState([]);
    const [keywordsOption, setKeywordsOption] = useState([]);
    const [primaryKw, setPrimaryKw] = useState("");
    const [relatedKw, setRelatedKw] = useState("");
    const tags = useSelector(state=>Object.values(state.tagCloud.tags));
    const selectedContents = useSelector(state => state.editor.selectedContents);

    useEffect(()=>{
        if(selectedAltKeyword > 0) {
            setEditMode(1);
        } else {
            setEditMode(0);
        }
    }, [setEditMode, selectedAltKeyword]);

    useEffect(() => {
        const item = data[index];
        if(!item.hasOwnProperty('keywords')) item.keywords = [];
        setItem(item);
        setTitleUpdated(false);
        setDescUpdated(false);
        setAuthorUpdated(false);
        const thumbnail = (item.thumbnail ? item.thumbnail.replace("thumb", "thumbnail") : item.fileToken.replace(/svg|png|source/, "thumbnail")) + (item.fileToken.includes('svg') ? '' : '.webp');
        if (thumbnail) setImage(`https://synopsis.pixel.ieplsg.com/imagez/${thumbnail}`);
        const preview = (item.thumbnail ? item.thumbnail : item.fileToken.replace(/svg|png|source/, "high")) + (item.fileToken.includes('svg') ? '' : '.webp');
        setPreview(`https://synopsis.pixel.ieplsg.com/imagez/${preview}`)
        // set Alt txt for Reference
        setAltTxt(item.altDescription ? item.altDescription : "");
        // show current value
        setOrgTitle(item.title ? item.title : "");
        setOrgDescription(item.description ? item.description : "");
        setAuthor(authors[item.detail?.author])
        // show applied value
        const updates = { title: item.detail?.title || "", description: item.detail?.description || "" };
        if (item.detail?.pendingRevisions?.length > 0) {
            for (const revision of item.detail.pendingRevisions) {
                for (const amendment of revision.amendments) {
                    switch (amendment.path) {
                        case "title":
                            updates.title = amendment.value;
                            setTitleUpdated(true);
                            break;
                        case "description":
                            updates.description = amendment.value;
                            setDescUpdated(true);
                            break;
                        case "author":
                            setAuthor(authors[amendment.value])
                            setAuthorUpdated(true);
                            break;
                    }
                }
            }
        }

        setTitle(updates.title);
        setDescription(updates.description);

    }, [index, data, setItem]);
    useEffect(() => {
        setChannel(projects[selectedProject].channel);
    }, [setChannel, projects, selectedProject]);
    useEffect(() => {
        const codes = {};
        for (const project of Object.values(projects)) {
            codes[project.channel] = project.code;
        }
        setCodes(codes);
    }, [setCodes, projects]);
    useEffect(()=>{
        setAuthorOption(Object.values(authors));
    }, [setAuthorOption, authors]);
    useEffect(()=>{
        // filter out data not equal to primary data
        const keyArr = Object.values(keywords).filter(x => x.id !== currentTask.id);
        setKeywordsOption(keyArr);
    }, [setKeywordsOption, keywords, currentTask]);
    const handleFocus = (event) => {
        if (amendItem && amendItem !== item.id && Object.keys(amendments).length > 0 && projects[selectedProject].mode > 0) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Unsaved updates",
                    content: `There are some updates on the content #${amendItem}. Do you want to save it now?`,
                    confirmType: SAVE_CONTENT
                }
            });
            return;
        }
        if (!item.detail) {
            dispatch({type: LOAD_CONTENT_DETAIL, payload: {id: item.id, channel, base: item?.primaryBaseKeyword} })
        }
        setFocusOn(true);
    };

    const handleProvision = (event) => {
        event.stopPropagation();
        if (item.detail?.provision > 0) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Content Provision",
                    content: "This content already provisioned"
                }
            });
        } else if (item.detail?.provision < 0) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Content Provision",
                    content: "This content is DMCA case, cannot provision"
                }
            });
        } else if(item && !item.primaryBaseKeyword) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Provision Content",
                    content: "Primary Base Keyword is required"
                }
            });
        }else if(item.deselect?.includes(channel)) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Provision Content",
                    content: "Content already deselected"
                }
            });
        }else if (!item.detail?.title) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Content Provision",
                    content: "H1 is required!"
                }
            });
        }else {
            dispatch({ type: PROVISION_CONTENT, payload: {id: item.id, channel, primaryBaseKeyword: item.primaryBaseKeyword} });
        }
    };
    const handleApproval = (event) => {
        event.stopPropagation();
        if(item && !item.primaryBaseKeyword) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Approve Content",
                    content: "Primary Base Keyword is required"
                }
            });
        }
        dispatch({type: APPROVE_CONTENT, payload: {id: item.id, channel, primaryBaseKeyword: item.primaryBaseKeyword}});

    };
    const handleSave = (event) => {
        event.stopPropagation();
        if(item && !item.primaryBaseKeyword) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Save Content",
                    content: "Primary Base Keyword is required"
                }
            });
        }
        dispatch({type: SAVE_CONTENT, payload: {id: item.id, channel, primaryBaseKeyword: item.primaryBaseKeyword}});
    };
    const handleDeselect = (event) => {
        event.stopPropagation();
        if(item && !item.detail) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Deselect Content",
                    content: "Content Detail is not exist"
                }
            });
        }else if(item && !item.primaryBaseKeyword) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Deselect Content",
                    content: "Primary Base Keyword is not exist"
                }
            });
        }
        // else if(item && item.detail && item.detail?.status === -10) {
        //     // Check content detail already deselect
        //     dispatch({
        //         type: SHOW_ALERT, payload: {
        //             title: "Deselect Content",
        //             content: "Content Detail already deselected"
        //         }
        //     });
        // }
        // else if(item && item.detail && !(item.detail?.lastSyncAt)) {
        //     // Check content detail is sync or not
        //     dispatch({
        //         type: SHOW_ALERT, payload: {
        //             title: "Deselect Content",
        //             content: "Content Detail not yet sync"
        //         }
        //     });
        // }
        else {
            dispatch({type: DESELECT_CONTENT, payload: {id: item.id, channel, primary: currentTask.id, primaryBaseKeyword: item.primaryBaseKeyword}});
        }
    };
    const handlePreview = (event) => {
        setOnPreview(true)
    }
    const offPreview = (event)=>{
        event.stopPropagation();
        setOnPreview(false)
    }

    const setFeatured = (event) => {
        event.stopPropagation();
        if(item.deselect && item.deselect.includes(channel)) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Feature content",
                    content: "Failed to set feature content because content was deselected"
                }
            }); 
        }else {
            dispatch({type: SET_FEATURE_CONTENT, payload: item.id})
        }
    }

    const handleSetIsOriginal = (event) => {
        event.stopPropagation();
        if(item.isOriginal > 0) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Set Original Content ",
                    content: "Content already set as original"
                }
            });
        }else if(item && !item.primaryBaseKeyword) {
            // Check content detail is exist
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Set Original Content",
                    content: "Primary Base Keyword is not exist"
                }
            });
        }else {
            dispatch({type: SET_CONTENT_ORIGINAL, payload: {id: item.id, channel, primaryBaseKeyword: item.primaryBaseKeyword }})
        }
    }

    // const handleClear = (event) => {
    //     event.stopPropagation();
    //     dispatch({
    //         type: UPDATE_CONTENT,
    //         target: item.id,
    //         path: "description",
    //         base: orgDescription,
    //         payload: ""
    //     })

    //     dispatch({
    //         type: UPDATE_CONTENT,
    //         target: item.id,
    //         path: "title",
    //         base: orgTitle,
    //         payload: ""
    //     })

    //     dispatch({type: SAVE_CONTENT, payload: {id: item.id, channel, primaryBaseKeyword: item.primaryBaseKeyword}});
    // }

    const handleSetSelectedContents = (event) => {
        event.stopPropagation();
        if(item.deselect && item.deselect.includes(channel)) {
            dispatch({
                type: SHOW_ALERT, payload: {
                    title: "Select Content Failed",
                    content: "Content already deselected"
                }
            }); 
        }else {
            dispatch({type:SELECT_CONTENT, payload: item.id })
        }
    }

    return <Box style={style} sx={{ display: "flex", position: "relative", borderBottom: "1px solid #ddd" }}>
        <Box sx={{ width: "320px", display: "flex", flexDirection: "column", padding: "0.5rem" }}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Checkbox checked={selectedContents.includes(item.id)} onChange={handleSetSelectedContents} color="secondary"/>
                <Typography sx={{ fontSize: "120%" }}>ID: {item.id}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex", position: "relative" }}
                onClick={handlePreview}
            >
                <ImageBox src={image} width={"240px"} height={"180px"} alt={"image"}/>
                <Dialog open={onPreview} onClose={offPreview}>
                    <DialogTitle title={item.title}/>
                    <DialogContent>
                        <DialogContentText>{item.description}</DialogContentText>
                        <Box>
                            <ImageBox src={preview} width={"600px"} height={'400px'}/>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={offPreview}>Dismiss</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                <Box sx={{marginRight: '0.5rem'}}><Typography>Author:</Typography></Box>
                <Box sx={{flexGrow: 1}}>
                    {/* Only manager can update author */}
                    {/* <Authorization allowed={["pixel.content.approval"]} failback={
                        <Autocomplete
                            disabled={true}
                            value={author}
                            disablePortal
                            size={"small"}
                            sx={{backgroundColor: authorUpdated ? "#a0cffa" : "none" }}
                            getOptionLabel={option=>option.name}
                            fullWidth
                            options={authorOption}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    }> */}
                        <Autocomplete
                            readOnly={editMode > 0}
                            value={author}
                            disablePortal
                            size={"small"}
                            sx={{backgroundColor: authorUpdated ? "#a0cffa" : "none" }}
                            getOptionLabel={option=>option.name}
                            fullWidth
                            onChange={(event, newValue)=>{
                                setAuthor(newValue)
                            }}
                            onBlur={event=>{
                                dispatch({type: UPDATE_AUTHOR, target: item.id, author})
                            }}
                            options={authorOption}
                            renderInput={(params)=><TextField {...params}/>}
                        />
                    {/* </Authorization> */}
                </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: '4px'}}>
                <Box sx={{ marginRight: "0.5rem" }}><Typography>Source:</Typography></Box>
                <Box><Typography>{item.createdBy ? "Upload" : "Scrape"}</Typography></Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: '4px' }}>
                {Object.keys(codes).map(k => {
                    if (item.channels?.includes(k)) {
                        return <Chip variant={`${channel === k ? "filled" : "outlined"}`} color={"info"} size={"small"}
                                     key={`c-k-${item.id}-${k}`} label={codes[k]}/>;
                    }
                })}
            </Box>
        </Box>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column",
            '&:hover': {
                '& .tag-cloud-box': {
                    display: 'flex'
                }
            } }} onClick={handleFocus}>
            <Toolbar variant={"dense"} sx={{ backgroundColor: item.channels?.includes(channel) ? "#9ae2a2" : "#dedede" }} disableGutters>
                <Box sx={{ marginRight: "0.5rem" }}><Switch checked={featured === item.id} onChange={setFeatured}/>Featured</Box>
                <Box sx={{ marginRight: "0.5rem", bgcolor: "info.light", padding: "0.25rem" }}>{item.type}</Box>
                {item.detail?.production?.status && <Box sx={{
                    marginRight: "0.5rem",
                    bgcolor: "success.light",
                    padding: "0.25rem",
                    textTransform: "uppercase"
                }}>
                    {item.detail?.production?.status}
                </Box>}
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box>{(item.id === currentItem && processing) && <CircularProgress color={"info"}/>}</Box>
                {editMode > 0 ?
                    <Box sx={{position: 'related'}}>
                        {processing && item.id === linkingItem && <CircularProgress color={'info'}/>}
                        <Button color={item.id === linkingItem ? "info" : item.id === linkedItem ? 'success' : 'primary'} variant={'contained'}>Related to {currentTask.name}</Button>
                    </Box>
                    :
                <Box>
                    {/* <Authorization allowed={["pixel.content.edit"]} failback={<></>}>
                        <Button
                            onClick={handleClear}
                            variant={amendItem === item.id ? "outlined" : "text"}
                        >
                            Clear
                        </Button>
                    </Authorization> */}
                    <Authorization allowed={["pixel.content.approval"]} failback={<></>}>
                        <Button
                            onClick={handleSetIsOriginal}
                            color="info"
                            variant={(item.isOriginal ? "contained" : "outlined")}
                            sx={{marginRight: "0.5em", marginLeft: "0.5em"}}
                        >
                            Original
                        </Button>
                    </Authorization>
                    <Authorization allowed={["pixel.content.provision"]} failback={<></>}>
                        <Button
                            onClick={handleProvision}
                            variant={(item.detail?.provision > 0 ? "contained" : "outlined")}
                        >
                            Provision
                        </Button>
                    </Authorization>
                    <Authorization allowed={["pixel.content.approval"]} failback={<></>}>
                        <Button
                            onClick={handleApproval}
                            variant={(item.detail?.pendingRevisions?.length > 0 ? "outlined" : "text")}
                            sx={{marginRight: "0.5em", marginLeft: "0.5em"}}
                        >
                            Approve
                        </Button>
                    </Authorization>
                    <Authorization allowed={["pixel.content.edit"]} failback={<></>}>
                        <Button
                            onClick={handleDeselect}
                            variant={item.deselect?.includes(channel) ? "outlined" : "text"}
                        >
                            Deselect
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant={amendItem === item.id ? "outlined" : "text"}
                        >
                            Save
                        </Button>
                    </Authorization>
                </Box>}
            </Toolbar>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", padding: "0.5rem" }}>
                    <Box sx={{ display: "flex", marginBottom: "0.5rem", alignItems: "center" }}>
                        <Typography sx={{ width: "6rem" }}>H1</Typography>
                        <TextField
                            sx={{ 
                                maxWidth: "26rem", backgroundColor: titleUpdated ? "#a0cffa" : "none", 
                                "& .css-twnruf-MuiInputBase-root-MuiOutlinedInput-root": {
                                    paddingRight: "5px"
                                }
                            }}
                            disabled={editMode>0}
                            value={title}
                            size={"small"}
                            fullWidth
                            // helperText={orgTitle}
                            onChange={event => {
                                if(!isEdited) {
                                    dispatch({
                                        type: UPDATE_CONTENT_ISEDIT,
                                        payload: true
                                    })
                                }
                                setTitle(event.currentTarget.value);
                            }}
                            onBlur={() =>{
                                isEdited && (!amendItem || amendItem === item.id) &&
                                dispatch({
                                    type: UPDATE_CONTENT,
                                    target: item.id,
                                    path: "title",
                                    base: orgTitle,
                                    payload: title
                                })
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            sx={{
                                                minWidth: "50px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                                marginRight: "3px"
                                            }}
                                            variant="outlined"
                                            size="small"
                                            disabled={!currentTask?.taskId}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                // ( {keyword.name} {width} x {height} {base keyword})
                                                const currentBase = currentTask?.base;
                                                const bKW = currentTask?.baseName;

                                                const cWidth = item?.assets?.file?.width;
                                                const cHeight = item?.assets?.file?.height;
                                                
                                                let assetInfo = cWidth + " x " + cHeight;
                                                const kName = currentTask?.name;
                                                // If base is svg take size instead of dimension
                                                if(currentBase === 7) {
                                                    assetInfo = item?.assets?.file?.size;
                                                }

                                                const defaultContentTitle = kName + " " + assetInfo +  " " + bKW;

                                                setTitle(defaultContentTitle);
                                                dispatch({
                                                    type: UPDATE_CONTENT,
                                                    target: item.id,
                                                    path: "title",
                                                    base: orgTitle,
                                                    payload: defaultContentTitle
                                                })
                                            }}
                                        >
                                        Reset
                                        </Button>
                                        <CancelIcon sx={{
                                            size: "small",
                                            '&:hover': {
                                                    cursor: "pointer"
                                                }
                                            }} 
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setTitle("");
                                                dispatch({
                                                    type: UPDATE_CONTENT,
                                                    target: item.id,
                                                    path: "title",
                                                    base: orgTitle,
                                                    payload: ""
                                                })
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", marginBottom: "0.5rem", alignItems: "center" }}>
                        <Typography sx={{ width: "6rem" }}></Typography>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                        <Button 
                            variant="contained"
                            size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const copyTitle = orgTitle;  
                                    setTitle(copyTitle);
                                    dispatch({
                                        type: UPDATE_CONTENT,
                                        target: item.id,
                                        path: "title",
                                        base: orgTitle,
                                        payload: copyTitle
                                    })
                                }}
                            >Copy
                        </Button>
                        <Typography sx={{ 
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: 400,
                            fontSize: "0.6428571428571428rem",
                            lineHeight: 1.66,
                            textAlign: "left",
                            marginRight: "14px",
                            marginBottom: 0,
                            marginLeft: "14px",
                            }}>{orgTitle}
                        </Typography>
                        </Box>
                    </Box>
                    
                    <Box sx={{ display: "flex", marginBottom: "0.5rem" }}>
                        <Typography sx={{ width: "6rem" }}>Description</Typography>
                        <TextField
                            sx={{ maxWidth: "26rem", backgroundColor: descUpdated ? "#a0cffa" : "none" }}
                            disabled={editMode>0}
                            value={description}
                            size={"small"}
                            multiline
                            rows={3}
                            fullWidth
                            helperText={orgDescription}
                            onChange={event => {
                                if(!isEdited) {
                                    dispatch({
                                        type: UPDATE_CONTENT_ISEDIT,
                                        payload: true
                                    })
                                }
                                setDescription(event.currentTarget.value);
                            }}
                            onBlur={() => {
                                isEdited && (!amendItem || amendItem === item.id) &&
                                dispatch({
                                    type: UPDATE_CONTENT,
                                    target: item.id,
                                    path: "description",
                                    base: orgDescription,
                                    payload: description
                                })
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CancelIcon sx={{
                                            size: "small",
                                            '&:hover': {
                                                    cursor: "pointer"
                                                }
                                            }} 
                                            onClick={() => {
                                                setDescription("");
                                                dispatch({
                                                    type: UPDATE_CONTENT,
                                                    target: item.id,
                                                    path: "description",
                                                    base: orgDescription,
                                                    payload: ""
                                                })
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Divider sx={{ flexGrow: 1 }}/>
                    <Box sx={{ display: "flex", marginTop: "0.5rem", alignItems: "center" }}>
                        <Typography sx={{ width: "6rem" }}>Alt Text</Typography>
                        <Typography sx={{ maxWidth: "26rem" }}>{altTxt}</Typography>
                    </Box>
                </Box>
                <Box sx={{
                    width: "320px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.5rem",
                    position: "relative"
                }}>
                    <Box sx={{ display: "flex", marginBottom: "0.5rem", alignItems: "center" }}>
                        <Typography sx={{ width: "6rem" }}>Primary</Typography>
                        <Authorization allowed={["pixel.content.approval"]}
                            failback={<TextField size={"small"} disabled value={currentTask.name} fullWidth/>}
                        >
                            <Autocomplete
                                onInputChange={(e, v, r) => {
                                    if(e) {
                                        dispatch({type: LOAD_KEYWORDS_LIST, search: v});
                                    }
                                }}
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-formControl": {
                                        paddingTop: "0px",
                                        paddingBottom: "0px"
                                    }
                                }}
                                onChange={(e, value)=>{
                                    if(value) {
                                        dispatch({ type: SET_CONTENT_PRIMARY, payload: {id: item.id, data: value} });
                                    }
                                }}
                                options={keywordsOption}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value.id}
                                value={
                                    item.keyword || {}
                                }
                                renderInput={
                                    (params) => <TextField
                                        {...params}
                                        placeholder="Select primary keyword"
                                        InputProps={
                                            {
                                                ...params.InputProps,
                                            }}
                                    />
                                }
                            />
                        </Authorization>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography sx={{ width: "6rem", paddingTop: "7px" }}>Related</Typography>
                        <Box sx={{flexGrow: 1, width: "100%"}}>
                            <Autocomplete
                                sx={{
                                    "& .MuiInputBase-formControl": {
                                        paddingTop: "0px",
                                        paddingBottom: "0px"
                                    }
                                }}
                                onInputChange={(e, v, r) => {
                                    dispatch({ type: LOAD_KEYWORDS_LIST, search: v });
                                }}
                                onChange={(e, v, r) => {
                                    if(r === "selectOption") {
                                        const keywordObj = v[v.length - 1];
                                        dispatch({
                                            type: LINK_CONTENT_TO_KEYWORD,
                                            payload: { keyword: keywordObj.id, content: item.id, obj: keywordObj, arr: v, base:item?.primaryBaseKeyword }
                                        });
                                    } else if(r === "removeOption") {
                                        const difference = item.keywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                        dispatch({
                                            type: UNLINK_KEYWORD,
                                            payload: { keyword: difference[0].id, content: item.id, arr: v, base:item?.primaryBaseKeyword}
                                        });
                                    }
                                }}
                                multiple
                                id="linked-items"
                                options={keywordsOption}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value.id}
                                value={item.keywords || []}
                                renderTags={() => { }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        variant="outlined"
                                        placeholder="Linked Keywords"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box>
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </Box>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                onChange={(e, v, r) => {
                                    if (r === "selectOption") {
                                        const keywordObj = v[v.length - 1];
                                        dispatch({
                                            type: LINK_CONTENT_TO_KEYWORD,
                                            payload: { keyword: keywordObj.id, content: item.id, obj: keywordObj, arr: v, base:item?.primaryBaseKeyword}
                                        });
                                    } else if(r === "removeOption") {
                                        const difference = item.keywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                        dispatch({
                                            type: UNLINK_KEYWORD,
                                            payload: { keyword: difference[0].id, content: item.id, arr: v, base:item?.primaryBaseKeyword}
                                        });
                                    }
                                }}
                                multiple
                                id="linked-items"
                                options={keywordsOption}
                                getOptionLabel={(option) => option?.id}
                                getOptionSelected={(option, value) => option?.id === value.id}
                                value={item.keywords || []}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{height: "150px", overflowY: "auto"}}
                                        fullWidth
                                        {...params}
                                        variant="outlined"
                                        placeholder="Keywords"
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { display: 'none' }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: null
                                        }}
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        // hide primary chip
                                        if(option.id !== currentTask.id) {
                                            return (
                                                <Chip
                                                    size="small"
                                                    label={option?.name}
                                                    {...getTagProps({ index })}
                                                />
                                            )
                                        }
                                    })
                                }
                            />
                        </Box>
                    </Box>
                    <Box className={"tag-cloud-box"} sx={{display: "none", background: '#FBFBFE', height: "6em", position: 'absolute', bottom: 0, left: 0, right: 0}}>
                        <TagCloud
                            tags={tags}
                            // minSize={8}
                            // maxSize={16}
                            minSize={12}
                            maxSize={12}
                            disableRandomColor={true}
                            shuffle={false}
                            onClick={tag => {
                                let arr;
                                if(!!item.keywords) {
                                    arr = [...item.keywords, {id: tag.key, name: tag.value}];
                                } else {
                                    arr = [{id: tag.key, name: tag.value}];
                                }
                                dispatch({
                                    type: LINK_CONTENT_TO_KEYWORD,
                                    payload: {
                                        keyword: tag.key,
                                        content: item.id,
                                        obj: {id: tag.key, name: tag.value},
                                        arr,
                                        base: item?.primaryBaseKeyword
                                    }
                                })
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>;
}

export function EditorDash() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const project = useSelector(state => state.system.selectedProject);
    const allProjects = useSelector(state => state.system.projects);
    const loading = useSelector(state => state.editor.loading);
    const taskLoading = useSelector(state => state.editor.taskLoading);
    const processing = useSelector(state => state.editor.processing);
    const currentTask = useSelector(state => state.editor.currentTask);
    const tasks = useSelector(state => state.editor.tasks);
    const taskIdx = useSelector(state => state.editor.taskIdx);
    const [taskList, setTaskList] = useState([]);
    const contents = useSelector(state => state.editor.contents);
    const contentIdx = useSelector(state => state.editor.contentIdx);
    const authors = useSelector(state => state.editor.authors);
    const [contentList, setContentList] = useState([]);
    const filter = useSelector(state => state.editor.filter);
    const dialogTitle = useSelector(state => state.editor.dialogTitle);
    const dialogContent = useSelector(state => state.editor.dialogContent);
    const dialogOpen = useSelector(state => state.editor.dialogOpen);
    const dialogConfirmType = useSelector(state => state.editor.dialogConfirmType);
    const altKeywords = useSelector(state => state.editor.altKeywords);
    const selectedAltKeyword = useSelector(state => state.editor.selectedAltKeyword);
    const selectedContents = useSelector(state=>state.editor.selectedContents);
    const [channel, setChannel] = useState(null);
    const [isDesc, setIsDesc] = useState(false);
    // Load some keyword data for Related Keyword
    useEffect(() => {
        dispatch({type: LOAD_KEYWORDS_LIST});
    }, [dispatch]);

    const handleDialogClose = () => {
        dispatch({ type: DISMISS_ALERT });
    };
    const handleDialogConfirm = () => {
        dispatch({ type: dialogConfirmType });
        dispatch({ type: DISMISS_ALERT });
    };
    useEffect(() => {
        if(project > 0) {
            dispatch({ type: LOAD_TASKS });
        }
    }, [dispatch, project]);

    useEffect(() => {
        if(allProjects[project]) {
            setChannel(allProjects[project].channel);
        }
    }, [setChannel, allProjects, project]);

    useEffect(() => {
        setTaskList(taskIdx.map(idx => tasks[idx]));
    }, [tasks, taskIdx, setTaskList]);
    useEffect(() => {
        if (currentTask.taskId) dispatch({ type: LOAD_CONTENTS, payload: currentTask });
    }, [dispatch, currentTask]);

    useEffect(() => {
        let sortContentIdx = orderBy(contentIdx, [(o) => o]);
        if(isDesc) {
            sortContentIdx = orderBy(contentIdx, [(o) => o], ["desc"]);
        }
        setContentList(sortContentIdx.map(c => contents[c]));
    }, [contents, contentIdx, setContentList, isDesc]);

    const handleSubmit = (event) => {
        dispatch({ type: SUBMIT_TASK, payload: { taskId: currentTask.taskId, status: currentTask.status } });
    };

    return <Paper sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{ flexGrow: 1, width: "12rem", display: "flex", flexDirection: "column" }}>
            <Toolbar variant="dense" sx={{ bgcolor: "primary.light" }}>
                <Typography variant={"h6"}>Keyword:</Typography>
                <Box sx={{ marginLeft: "0.5rem" }}>
                    <Typography sx={{fontSize:'15px'}}>{currentTask.name} - {currentTask?.baseName}</Typography>
                    <Typography sx={{fontSize:'10px'}}>#{currentTask.id}</Typography>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {taskLoading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{ height:  "3em", padding: '0.5em 0.25em 0.5em 0.25em'}}>
                <TextField
                    fullWidth
                    variant="standard"
                    onChange={event => dispatch({type:SEARCH_TASK, payload: event.target.value})}
                />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <FixedSizeList
                            width={width}
                            height={height}
                            itemData={taskList}
                            itemCount={taskList.length}
                            itemSize={48}
                        >
                            {KeywordCell}
                        </FixedSizeList>)}
                </AutoSizer>
            </Box>
        </Box>
        <Box sx={{ flexGrow: 4, display: "flex", flexDirection: "column" }}>
            <Toolbar variant="dense" sx={{ bgcolor: "primary.light" }}>
                <Box>
                    {currentTask.status === 1 &&
                        <Tooltip title={"Submit to Approve"}><Button variant={"contained"}
                                                                     onClick={handleSubmit}>Active</Button></Tooltip>
                    }
                    {currentTask.status === 2 &&
                        <Authorization allowed={["pixel.content.approval"]} failback={<Box
                            sx={{
                                bgcolor: "warning.light",
                                borderRadius: "0.25rem",
                                padding: "0.5rem"
                            }}>Pending</Box>}>
                            <Tooltip title={"Pending for Approval"}>
                                <Button variant={"contained"}
                                        onClick={handleSubmit}>
                                    Pending
                                </Button>
                            </Tooltip>
                        </Authorization>}
                    {currentTask.status === 3 &&
                        <Authorization allowed={["pixel.content.provision"]} failback={<Box
                            sx={{ bgcolor: "info.light", borderRadius: "4px", padding: "0.5rem" }}>Approved</Box>}>
                            <Tooltip title={"Pending for Provision"}>
                                <Button variant={"contained"}
                                        onClick={handleSubmit}>
                                    Approved
                                </Button>
                            </Tooltip>
                        </Authorization>}
                    {currentTask.status === 4 &&
                        <Box sx={{ bgcolor: "success.light", borderRadius: "4px", padding: "0.4rem", textAlign: "center", fontSize: "smaller"}}>POST PROCESSING</Box>
                    }
                    {currentTask.status === 0 &&
                        <Box sx={{ bgcolor: "success.light", borderRadius: "4px", padding: "0.5rem" }}>Completed</Box>
                    }
                </Box>
                <Box><SearchBox/></Box>
                <Box><FormControlLabel label={"Select All"} control={
                    <Checkbox 
                        checked={isAllSelected(selectedContents, Object.keys(contents))}
                        indeterminate={selectedContents.length > 0} 
                        color="warning"
                        onChange={()=>{
                            // Remove deselected content detail
                            const validIdx = contentIdx.filter(c => {
                                if(!contents[c].deselect || (contents[c].deselect && !contents[c].deselect.includes(channel))) {
                                    return c
                                }
                            })
                            dispatch({type: SELECT_ALL_CONTENT, payload: validIdx})
                        }}
                    />
                }/></Box>
                <Box>{selectedContents.length > 0 && `${selectedContents.length} items`}</Box>
                <Box>  
                <TextField
                    sx={{ padding: "0 5px 0 3px", maxWidth: "28rem" }}
                    placeholder="Search keyword name"
                    size={"small"}
                    fullWidth
                    onChange={event => {
                        dispatch({
                            type: EDITOR_SEARCH_KEYWORDS,
                            payload: event.currentTarget.value
                        })
                    }}
                    InputProps={{
                        endAdornment: (
                           <>{taskLoading ? (<CircularProgress color={'info'} size={20}/>) : null}</>
                        ),
                      }}
                />
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={{ marginRight: "1rem", display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: "0.2rem" }}>Alt. Keyword:</Box>
                    <Select size={"small"} sx={{ width: "8rem" }} value={selectedAltKeyword} disabled={altKeywords.length === 0}
                            onChange={event => dispatch({ type: SELECT_ALT_KEYWORD, payload: {id: event.target.value} })}>
                        <MenuItem value={-1}>None</MenuItem>
                        {altKeywords.map(x => { return <MenuItem value={x.id} key={"alt-kw-"+x.id}>{x.name}</MenuItem>})}
                    </Select>
                </Box>
                <Box sx={{ marginRight: "1rem" }}>Total: {contentIdx.length} / {Object.keys(contents).length}</Box>
                <Box>
                    <Select size={"small"} sx={{ width: "10rem" }} value={filter} label="Filter"
                            onChange={event => dispatch({ type: UPDATE_FILTER, payload: event.target.value })}>
                        <MenuItem value="A">All</MenuItem>
                        <MenuItem value="AC">Active</MenuItem>
                        <MenuItem value="PA">Pending Approval</MenuItem>
                        <MenuItem value="PP">Pending Provision</MenuItem>
                        <MenuItem value="PD">Provisioned</MenuItem>
                    </Select>
                </Box>
                <Box><FormControlLabel sx={{ margin: "0 0.3em 0 0.2em"}}label={"Sort Desc"} control={
                    <Checkbox 
                        checked={isDesc}
                        color="default"
                        onChange={()=>{
                            if(isDesc) {
                                setIsDesc(false);
                            }else {
                                setIsDesc(true);
                            }
                        }}
                    />
                }/></Box>
                <Box>
                    <Button 
                    variant="contained"
                    color="info"
                    disabled={currentTask?.taskId && currentTask?.base ? false : true}
                    onClick={(e) => {
                        e.preventDefault();
                        const value = {
                            id: currentTask.id, 
                            name: currentTask?.name + " " + currentTask?.baseName, 
                            base_id: currentTask.base, 
                            base: currentTask?.baseName
                        }
                        dispatch({ type: SET_CONTENT_UPLOAD_PRIMARY, payload: {data: value} });
                        navigate('/editor/upload-content');
                    }}
                    >Upload</Button>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{ flexGrow: 1, paddingLeft: "0.5rem"}}>
                {contentList.length === 0
                    ? <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Typography variant="h4">Please select a Keyword to start</Typography></Box>
                    :<AutoSizer>
                    {({ width, height }) => (
                        <FixedSizeList
                            width={width}
                            height={height}
                            itemData={contentList}
                            itemCount={contentList.length}
                            itemSize={330}
                        >
                            {ContentEditor}
                        </FixedSizeList>)}
                </AutoSizer>}
            </Box>
        </Box>
        <Dialog onClose={handleDialogClose} open={dialogOpen}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Dismiss</Button>
                {dialogConfirmType && <Button onClick={handleDialogConfirm}>Confirm</Button>}
            </DialogActions>
        </Dialog>
    </Paper>;
};