import { useDispatch, useSelector } from "react-redux";
import { 
    Box, Fab, LinearProgress, Paper, Toolbar, Typography, Autocomplete, TextField,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from "@mui/material";
import { useEffect, useState } from "react";
import { UploadProcess } from "./UploadProcess";

export function KeywordUploadPage() {
    const dispatch = useDispatch();
    const [start, setStart] = useState(false);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        setStart(true);
        setLoading(true);
    }

    const handleComplete = (response) => {
        setResponse(response);
        setLoading(false);
        // if(response && response.items > 0) {
        //     if(filters && filters.keyword) {
        //         dispatch({ type: SEARCH_KEYWORD, word: "" });
        //     }
        //     dispatch({ type: LOAD_KEYWORDS_DETAILED });
        //     dispatch({ type: LOAD_ALL_KEYWORDS });
        // }
    }

    useEffect(() => {
        return () => setStart(false);
    }, [])

    return <Paper sx={{flexGrow: 1, display: "flex"}}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between"}}
                     variant="dense">
                <Typography variant="h5">Keyword Upload</Typography>
                <Button 
                    variant="contained"
                    color="primary" 
                    onClick={handleSubmit}
                >
                    SUBMIT
                </Button>
            </Toolbar>
            <Box sx={{flexGrow: 0, height: "4px"}}>
                {loading && <LinearProgress variant="indeterminate" color="primary"/>}
            </Box>
            <UploadProcess process={start} onComplete={handleComplete} />
            {response &&
                <Box>
                    Summary: {response.items} records found and imported
                </Box>
            }
        </Box>
    </Paper>
}