import { Outlet } from "react-router-dom";
import { Paper } from "@mui/material";

export function ManagerPage() {
    return <Paper sx={{
        flexGrow: 1,
        display: "flex"
    }}>
        <Outlet/>
    </Paper>;
}