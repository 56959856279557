import {
    lighten,
    Input,
    Box
} from "@mui/material";
// import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subject, of } from "rxjs";
import { bufferTime, delay, concatMap } from 'rxjs/operators';
import Papa from "papaparse";
import Axios from "axios";

const initSubj = () => (new Subject().pipe(
    bufferTime(10000, null, 500), //500 per batch
    delay(2000),
    concatMap(rpc)
));

const initCount = () => (new Subject().pipe(concatMap(v => of(v))));

const rpc = async dataArr => {
    // TODO : Need to restructure this part
    const inputs = [];
    //map header to correct field in server
    if (dataArr.length === 0) return;

    let token, channel;

    dataArr.forEach(async ({ data, channel: c, token: t }) => {
        channel = c;
        token = t;

        const input = changeHeader({
            data, iterate: map => ([header, value], idx) => {

                if (header.match(/^keyword$/i)) map.set("name", value);
                else if (header.match(/difficult/i)) map.set("difficulty", value);
                else if (header.match(/global volume/i)) map.set("globalVolume", value);
                else if (header.match(/volume/i)) map.set("volume", value);
                else if (header.match(/cps/i)) map.set("cps", value);
                else if (header.match(/return rate|returnRate/i)) map.set("returnRate", value);

            }
        });
        inputs.push({ ...input, type: "sub" });

    });

    const axios = Axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` });

    axios.interceptors.request.use((config) => {
        config.headers.common["Authorization"] = `Bearer ${token}`;
        return config;
    });

    try {
        const { data: responseData } = await axios.request({
            method: "post",
            data: {
                query: `mutation updateKeywords($inputs: [KeywordInput]) {
                                        updateKeywords(inputs: $inputs)
                                    }`,
                variables: {
                    inputs
                }
            }
        });

        return responseData.data.updateKeywords;
    } catch (e) {
        console.log("111-e", e)
        return 0;
    }

}

export const changeHeader = ({ iterate, data }) => {

    const headers = Object.entries(data);
    const map = new Map();
    headers.forEach(iterate(map));
    return Object.fromEntries(map)

}

export const UploadProcess = ({ process, onComplete }) => {
    const dispatch = useDispatch();
    // const canonical = 

    const [upsertStatus, setUpsertStatus] = useState({ items: 0, completed: false });
    const channel = useSelector((state) => state.system.selectedProject);
    const token = useSelector(state => state.auth.token);

    const [subject$, setSubject$] = useState(initSubj());
    const [count$, setCount$] = useState(initCount());
    const [file, setFile] = React.useState(null);

    const triggerUpload = React.useCallback(() => {
        if (file) {
            uploadFile({ file, token, channel })
        }
        //toggleUpsertDialog(false);

    }, [file , token, channel]);

    useEffect(() => {
        if (process) {
            triggerUpload();
        }
    }, [process , triggerUpload])
    useEffect(() => {
        subject$.subscribe(
            {
                next: (val) => {
                    if (val) {
                        count$.next(val);
                    }
                },
                complete: () => {
                    count$.complete();
                }
            });
        count$.subscribe({
            next: (num = 0) => {
                setUpsertStatus(prev => {
                    return { ...prev, items: prev.items + num };
                })
            },
            complete: () => {
                console.log('completed')
                setUpsertStatus(prev => ({ ...prev, completed: true }));
            }
        });

        return () => {
            subject$.unsubscribe();
            count$.unsubscribe();
        }
    }, [count$, subject$]);

    useEffect(() => {
        if (upsertStatus.completed) onComplete(upsertStatus);
    }, [upsertStatus.completed , onComplete])

    

    const uploadFile = ({ file, token, channel }) => {

        Papa.parse(file, {
            delimitersToGuess: [',', ' ', '\t'],
            header: true,
            skipEmptyLines: false,
            dynamicTyping: true,
            quoteChar: '"',
            escapeChar: '"',
            quotes: true,
            transformHeader: (word) => word.toLowerCase(),
            //chunkSize: 1024 * 1024 * .5, // 1MB, //chunk has issue with quotes
            step: async (row) => {
                const { data, errors } = row;
                if (errors.length > 0) {
                    //why didnt parse
                    console.log(errors, 'errors')
                } else {
                    subject$.next({ data, token, channel });
                }


            },
            complete: () => {
                subject$.complete();
            }
        });
    }

    

    // useEffect(() => {
    //     if (canonical.hasOwnProperty("id") && canonical.hasOwnProperty("links")) canonical.links.forEach(
    //         item => {
    //             if (item.linkType === 'C')
    //                 dispatch({
    //                     type: LOAD_CATEGORY_CONTENTS,
    //                     category: item.id
    //                 })
    //         });
    // }, [canonical, dispatch])

    return (
        <>
            <Box>
                <Input
                    type="file"
                    fullWidth
                    onChange={(e) => setFile(e.target.files[0])}
                    inputProps={{ accept: ".xls,.xlsx,.csv" }}
                />
            </Box>
        </>
    );
};