import { 
    Alert, Button,
    Box, LinearProgress, Paper, Snackbar, 
    Typography , Autocomplete, TextField, CircularProgress,
    Toolbar, Select, MenuItem, Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import {
    LOAD_SOURCE_TASKS, SELECT_ST_BASE
} from "../../actions/sourcetask";
import AutoSizer from "react-virtualized-auto-sizer";
import { DataTable } from '@cosmos/runes';

export function SourceTaskTable() {
    const dispatch = useDispatch();
    // const notify = useSelector((state) => state.listing.notify);
    // const notice = useSelector((state) => state.listing.notice);
    const selectedProject = useSelector(state => state.system.selectedProject);
    const projects = useSelector(state => state.system.projects);
    const noticeType = useSelector((state) => state.sourceTask.noticeType);
    const loading = useSelector((state) => state.sourceTask.loading);
    const st = useSelector((state) => state.sourceTask.data);
    const stIndex = useSelector(state => state.sourceTask.index);
    const selectedBase = useSelector((state) => state.sourceTask.selectedBase);
    const bases = useSelector((state) => state.system.bases);
    // const [msgType, setMsgType] = useState(noticeType);

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [channel, setChannel] = useState(null);

    useEffect(() => {
        setChannel(projects[selectedProject]?.channel);
    }, [setChannel, projects, selectedProject]);

    useEffect(() => {
        if (channel !== "-1") {
            dispatch({ type: LOAD_SOURCE_TASKS });
        };
    }, [channel, dispatch]);

    useEffect(() => {
        const stArr = stIndex.map(idx => st[idx]);
        setData(stArr);
    }, [st, stIndex]);

    // useEffect(() => {
    //     if (noticeType) {
    //         setMsgType(noticeType);
    //     }
    // }, [noticeType]);

    // const handleClose = () => {
    //     dispatch({ type: CREATE_LISTING_CLOSE_NOTIFICATION });
    // };
    
    useEffect(() => {
        const columns = [
            {
                title: "Keyword",
                name: "keyword",
                sortBy: "keyword.name",
                cell: ({ rowData }) => {
                    let name = "-";
                    if (rowData && rowData?.keyword && rowData?.keyword?.name) {
                        name = rowData?.keyword?.name;
                    }
                    return name;
                },
            },
            {
                title: "Payload",
                name: "payload",
                sortBy: "payload",
                // width: "15%"
            },
            {
                title: "Assignee",
                name: "assignee",
                sortBy: "assignee",
                cell: ({ rowData }) => {
                    let name = "-";
                    if (rowData && rowData?.assigneeProfile && rowData?.assigneeProfile?.name) {
                        name = rowData?.assigneeProfile?.name;
                    }
                    return name;
                },
            },
            {
                title: "Status",
                name: "status",
                sortBy: "status",
                cell: ({ rowData }) => {
                    let status = "-";
                  
                        if(rowData?.status === 1) {
                            status = "Submitted"
                        }
                        else if(rowData?.status === 2) {
                            status = "In progress"
                        }
                        else if(rowData?.status == 0) {
                            status = "Completed"
                        }
                        else if(rowData?.status == -1) {
                            status = "Failed"
                        }
                
                    return status;
                },
            },
            {
                title: "No. Of success",
                sortBy: "result.success",
                cell: ({ rowData }) => {
                    let successCount = "-";
                    if (rowData && rowData.status === 0) {
                        successCount = 0;
                        if(rowData.result && rowData.result.success) {
                            successCount = rowData.result.success;
                        }
                    }
                    return successCount;
                },
            },
            {
                title: "No. Of dupplicate",
                sortBy: "result.duplicate",
                cell: ({ rowData }) => {
                    let duplicateCount = "-";
                    let text = "-";
                    if (rowData && rowData.status === 0) {
                        duplicateCount = 0;
                        if(rowData.result && rowData.result.duplicate) {
                            duplicateCount = rowData.result.duplicate;

                            const result = rowData.result;
                            let duplicateObjKey = []
                            for (const [key, value] of Object.entries(result)) {
                                if(value === "duplicated") {
                                    duplicateObjKey.push(key)
                                }
                            }
                            text = "Duplicate records: " + duplicateObjKey.join();

                            return <Tooltip title={text}>
                                <Box>
                                    {duplicateCount}
                                </Box>
                            </Tooltip>;
                        }else {
                            return <Box>{duplicateCount}</Box>
                        }
                    }
                    
                },
            },
        ];
        setColumns(columns);
    }, [setColumns , channel]);

    return <Paper sx={{ flexGrow: 1, display: "flex" }}>
        <Box sx={{flexGrow: 1, width: "12rem", display: "flex", flexDirection: "column" }}>
            <Toolbar variant="dense" sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between" }}>
                <Typography variant={"h5"} sx={{paddingRight: "20px"}}>Source Task</Typography>
                <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{paddingRight: "10px"}}>Base: </Typography>
                    <Select size={"small"} sx={{ width: "8rem", marginRight: "25px"}} 
                        value={selectedBase} onChange={event => dispatch({ type: SELECT_ST_BASE, payload: {id: event.target.value} })}>
                            <MenuItem value={-1} key={"alt-kw-all"}>All</MenuItem>
                        {Object.values(bases).map(x => { return <MenuItem value={x.id} key={"alt-kw-"+x.id}>{x.name}</MenuItem>})}
                    </Select>
                </Box>    
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <AutoSizer>
                {({ height, width }) => (
                    <DataTable
                        width={width}
                        height={height}
                        data={data}
                        columns={columns}
                        size={"exSmall"}
                        rowHeight={25}
                    />
                )}
            </AutoSizer>
            {/* <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notify}
                onClose={handleClose}
                autoHideDuration={6000}
            >
                <Alert variant="filled" severity={msgType}>
                    {notice}
                </Alert>
            </Snackbar> */}
        </Box>
    </Paper>;
}