export const SET_SOURCING_UPLOAD_PRIMARY = "@@sourcing/SET_SOURCING_UPLOAD_PRIMARY";

export const PREPARE_SOURCING_IMAGE = "@@sourcing/PREPARE_SOURCING_IMAGE";
export const PREPARE_SOURCING_IMAGE_SUCCESS = "@@sourcing/PREPARE_SOURCING_IMAGE_SUCCESS";

export const UPLOAD_SOURCING_IMAGE = "@@sourcing/UPLOAD_SOURCING_IMAGE";
export const UPLOAD_SOURCING_IMAGE_SUCCESS = "@@sourcing/UPLOAD_SOURCING_IMAGE_SUCCESS";
export const UPLOAD_SOURCING_IMAGE_FAIL = "@@sourcing/UPLOAD_SOURCING_IMAGE_FAIL";

export const UPLOAD_SOURCING = "@@sourcing/UPLOAD_SOURCING";
export const UPLOAD_SOURCING_SUCCESS = "@@sourcing/UPLOAD_SOURCING_SUCCESS";
export const UPLOAD_SOURCING_FAIL = "@@sourcing/UPLOAD_SOURCING_FAIL";

export const UPLOAD_SOURCING_CLOSE_NOTIFICATION = "@@sourcing/UPLOAD_SOURCING_CLOSE_NOTIFICATION";

export const LOAD_SOURCING_BASE_KW_LIST = "@@sourcing/LOAD_SOURCING_BASE_KW_LIST";
export const LOAD_SOURCING_BASE_KW_LIST_SUCCESS = "@@sourcing/LOAD_SOURCING_BASE_KW_LIST_SUCCESS";

export const SET_SOURCING_ISORIGINAL_FLAG = "@@sourcing/SET_SOURCING_ISORIGINAL_FLAG";
export const SET_SOURCING_UPLOAD_ISZIP_FLAG = "@@sourcing/SET_SOURCING_UPLOAD_ISZIP_FLAG";

export const PREPARE_SOURCING_ZIP = "@@sourcing/PREPARE_SOURCING_ZIP";
export const PREPARE_SOURCING_ZIP_SUCCESS = "@@sourcing/PREPARE_SOURCING_ZIP_SUCCESS";

export const UNZIP_SOURCING = "@@sourcing/UNZIP_SOURCING";
export const UNZIP_SOURCING_SUCCESS = "@@sourcing/UNZIP_SOURCING_SUCCESS";
export const UNZIP_SOURCING_FAIL = "@@sourcing/UNZIP_SOURCING_FAIL";

export const UPLOAD_SOURCING_ZIP_TO_S3 = "@@sourcing/UPLOAD_SOURCING_ZIP_TO_S3";
export const UPLOAD_SOURCING_ZIP_TO_S3_SUCCESS = "@@sourcing/UPLOAD_SOURCING_ZIP_TO_S3_SUCCESS";