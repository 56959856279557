import {createReducer} from "@reduxjs/toolkit";
import {
    TRANSLATION_SEARCH_KEYWORDS, TRANSLATION_SEARCH_KEYWORDS_SUCCESS, TRANSLATION_SEARCH_KEYWORDS_FAIL,
    TRANSLATION_LOAD_KEYWORD_DETAILS, TRANSLATION_LOAD_KEYWORD_DETAILS_SUCCESS, TRANSLATION_LOAD_KEYWORD_DETAILS_FAIL,
    CREATE_TRANSLATION_CLOSE_NOTIFICATION, SELECT_TRANSLATION_KEYWORD_DETAIL,
    CLEAR_TRANSLATION_LIST, EDIT_TRANSLATION_INPUT_VALUE,
    SUBMIT_KD_TRANSLATION_EDIT, SUBMIT_KD_TRANSLATION_EDIT_SUCCESS, SUBMIT_KD_TRANSLATION_EDIT_FAIL,
    CLEAR_SELECT_TRANSLATION,
    SUBMIT_KD_TRANSLATION_CREATE, SUBMIT_KD_TRANSLATION_CREATE_SUCCESS, SUBMIT_KD_TRANSLATION_CREATE_FAIL
} from "../actions/keywordtranslation";
import {createVibes} from "@cosmos/vibes";
import moment from "moment";

export const keywordTranslationReducer = createReducer(
    {
        data: [],
        keywordsOpt: [],
        detail: {
            channel: "",
            id: null,
            name: "",
            baseData: {
                id: "",
                name: ""
            },
            slug: ""
        },
        opLoading: false,
        loading:false, 
        notice: "",
        notify: false,
        noticeType: "error",
        selectedBase: -1
    }
    ,
    builder => {
        builder.addCase(TRANSLATION_SEARCH_KEYWORDS, state => {
            state.opLoading = true;
        }).addCase(TRANSLATION_SEARCH_KEYWORDS_SUCCESS, (state, action) => {
            state.opLoading = false;
            state.keywordsOpt = action.payload.keywordsOpt;
        }).addCase(TRANSLATION_SEARCH_KEYWORDS_FAIL, state => {
            state.opLoading = false;
        }).addCase(TRANSLATION_LOAD_KEYWORD_DETAILS, (state, action) => {
            state.loading = true;
        }).addCase(TRANSLATION_LOAD_KEYWORD_DETAILS_SUCCESS, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
        }).addCase(TRANSLATION_LOAD_KEYWORD_DETAILS_FAIL, (state, action) => {
            state.loading = false;
        }).addCase(CREATE_TRANSLATION_CLOSE_NOTIFICATION, (state, action) => {
            state.notify = false;
            state.notice = "";
            state.noticeType = "error";
        }).addCase(CLEAR_TRANSLATION_LIST, (state, action) => {
            state.data = [];
            state.detail = {
                channel: "",
                id: null,
                name: "",
                baseData: {
                    id: "",
                    name: ""
                },
                slug: ""
            };
        }).addCase(CLEAR_SELECT_TRANSLATION, (state, action) => {
            state.detail = {
                channel: "",
                id: null,
                name: "",
                baseData: {
                    id: "",
                    name: ""
                },
                slug: ""
            };
        })
        .addCase(SELECT_TRANSLATION_KEYWORD_DETAIL, (state, action) => {
            state.detail = action.payload;
        }).addCase(EDIT_TRANSLATION_INPUT_VALUE, (state, action) => {
            state.detail = {
                ...state.detail,
                ...action.payload
            }
        }) 
        .addCase(SUBMIT_KD_TRANSLATION_EDIT, state => {
            state.loading = true
        }) 
        .addCase(SUBMIT_KD_TRANSLATION_EDIT_SUCCESS, (state, action) => {
            state.loading = false
            state.notify = true;
            state.notice = "Update keyword success";
            state.noticeType = "success";
        }) 
        .addCase(SUBMIT_KD_TRANSLATION_EDIT_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload;
            state.noticeType = "error";
        }) 
        .addCase(SUBMIT_KD_TRANSLATION_CREATE, (state, action) => {
            state.loading = true;
        })
        .addCase(SUBMIT_KD_TRANSLATION_CREATE_SUCCESS, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = "Create keyword success";
            state.noticeType = "success";
        })
        .addCase(SUBMIT_KD_TRANSLATION_CREATE_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload;
            state.noticeType = "error";
        })    
    }
)

export const translationSearchKeywords = createVibes({
    type: TRANSLATION_SEARCH_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: TRANSLATION_SEARCH_KEYWORDS_SUCCESS,
        failType: TRANSLATION_SEARCH_KEYWORDS_FAIL
    },
    async process({getState, action, axios}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        // const project = projects[selectedProject];
        // const bases = getState().system.bases;

        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                    }
                  }
                  `,
                variables: {
                    filter: {
                        search: action.search
                    }
                }
            }
        });

        if (data && data.keywords) {
            const keywords = data.keywords;
            // const combineKeywords = [];
            // data.keywords.map(keyword => {
            //     Object.values(bases).map(base => {
            //         combineKeywords.push({
            //             name: keyword.name + " [" + base.name + "]",
            //             base: base.id,
            //             baseName: base.name,
            //             id: keyword.id,
            //             keywordName: keyword.name,
            //         })
            //     })
            // })

            return {
                keywordsOpt: keywords,
            };
        } else {
            throw new Error();
        }
    }
});

export const translationLoadKeywordDetails = createVibes({
    type: TRANSLATION_LOAD_KEYWORD_DETAILS,
    latest: true,
    processOptions: {
        successType: TRANSLATION_LOAD_KEYWORD_DETAILS_SUCCESS,
        failType: TRANSLATION_LOAD_KEYWORD_DETAILS_FAIL
    },
    async process({getState, action, axios}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const bases = getState().system.bases;
        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query keywordDetailsTranslation($filter: KeywordFilter) {
                    keywordDetailsTranslation(filter: $filter) {
                      id
                      base
                      channel
                      name
                      slug
                    }
                  }
                  `,
                variables: {
                    filter: {
                        id: action.payload?.id
                    }
                }
            }
        });
        
        if (data && data.keywordDetailsTranslation) {
            let keywords = data.keywordDetailsTranslation;
            const projectArr = Object.values(projects);
            const channelProject = _.keyBy(projectArr,'channel');

            keywords = keywords.map(x => {
                if(x.channel) {
                    x.channelName = channelProject[x.channel]?.name;
                    x.lang = channelProject[x.channel]?.lang;
                }
                x.baseData = {id: "", name: ""};
                if(x.base) {
                    x.baseData = bases[x.base];
                }
                return x;
            })
            return {
                data: keywords
            };
        } else {
            throw new Error();
        }
    }
});

export const updateKeywordDetailTranslation = createVibes({
    type: SUBMIT_KD_TRANSLATION_EDIT,
    latest: true,
    async process({ getState, action, axios }, dispatch, done) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const bases = getState().system.bases;
        const {id, channel, base, name} = getState().translation.detail;
       
        try {	
            if(!base) {
                throw new Error("Base is required")
            }
            if(!channel) {
                throw new Error("Channel is required")
            }
            if(!id) {
                throw new Error("Keyword ID is required")
            }
            if(!name) {
                throw new Error("Name is required")
            }
            const { data, errors } = await axios.request({
                method: "post",
                data: {
                    query: `mutation ($input: KeywordDetailTranslationInput) { 
                        updateKeywordDetailTranslation(input: $input) { 
                            id channel name base
                        } 
                    }`,
                    variables: {
                        input: {
                            id,
                            channel,
                            base,
                            name,
                        }
                    }
                }
            });

            if(errors && errors.length > 0) {
                let msg = "Fail to update keyword data";
                if(errors[0] && errors[0].message) {
                    msg = errors[0].message;
                }
                throw new Error(msg)
            }

            if(data && data.updateKeywordDetailTranslation && data.updateKeywordDetailTranslation.id) {
                const id = data.updateKeywordDetailTranslation.id;
                dispatch({type: TRANSLATION_LOAD_KEYWORD_DETAILS, payload: {id}});
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_EDIT_SUCCESS
                });
                dispatch({
                    type: CLEAR_SELECT_TRANSLATION
                });
            }
        } catch (error) {
            if (error?.response) {
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_EDIT_FAIL,
                    payload: "Fail to update keyword data",
                });
            } else {
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_EDIT_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
    }
});

export const createKeywordDetailTranslation = createVibes({
    type: SUBMIT_KD_TRANSLATION_CREATE,
    latest: true,
    async process({ getState, action, axios }, dispatch, done) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const bases = getState().system.bases;
        const project = projects[selectedProject];
        const {base, name} = getState().translation.detail;
        let channel = getState().translation.detail?.channel;
        if(!channel) {
            channel = project.channel;
        }

        try {	
            if(!base) {
                throw new Error("Base is required")
            }
            if(!name) {
                throw new Error("Name is required")
            }
            
            const { data, errors } = await axios.request({
                method: "post",
                data: {
                    query: `mutation ($input: KeywordDetailTranslationInput) { 
                        createKeywordDetailTranslation(input: $input) { 
                            id channel name base
                        } 
                    }`,
                    variables: {
                        input: {
                            channel,
                            base,
                            name,
                        }
                    }
                }
            });

            if(errors && errors.length > 0) {
                let msg = "Fail to create keyword data";
                if(errors[0] && errors[0].message) {
                    msg = errors[0].message;
                }
                throw new Error(msg)
            }

            if(data && data.createKeywordDetailTranslation && data.createKeywordDetailTranslation.id) {
                const id = data.createKeywordDetailTranslation.id;
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_CREATE_SUCCESS
                });
                dispatch({
                    type: CLEAR_SELECT_TRANSLATION
                });
            }
        } catch (error) {
            if (error?.response) {
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_CREATE_FAIL,
                    payload: "Fail to update keyword data",
                });
            } else {
                dispatch({
                    type: SUBMIT_KD_TRANSLATION_CREATE_FAIL,
                    payload: typeof error === "string" ? error : error?.message,
                });
            }
        }
    }
});
