import { useDispatch, useSelector } from "react-redux";
import { 
    Box, Fab, LinearProgress, Paper, Toolbar, Typography, Autocomplete, TextField,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
    Snackbar, Alert, CircularProgress, InputLabel,
    List, ListItem, Chip
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    REL_KW_SEARCH_KEYWORDS, REL_KW_SELECT_KW,
    REL_KW_SEARCH_REL_KEYWORDS, REL_KW_ADD, REL_KW_REMOVE,
    CLOSE_REL_KW_NOTIFICATION, CLEAR_REL_KW_DATA,
    REL_KW_CREATE_NEW_KEYWORD, UPDATE_REL_KW_DIALOG_FLAG
} from "../../actions/relatedkeyword";
import SearchIcon from '@mui/icons-material/Search';
import { createFilterOptions } from '@mui/material/Autocomplete';

export function RelatedKeywordPage() {
    const dispatch = useDispatch();
    const keywordsOpt = useSelector(state=>state.relatedKeyword.keywordsOpt);
    const loading = useSelector(state=>state.relatedKeyword.loading);
    const opLoading = useSelector(state=>state.relatedKeyword.opLoading);
    const keyword = useSelector(state=>state.relatedKeyword.keyword);
    const notify = useSelector((state) => state.relatedKeyword.notify);
    const notice = useSelector((state) => state.relatedKeyword.notice);
    const noticeType = useSelector((state) => state.relatedKeyword.noticeType);
    const relKwOpt = useSelector(state=>state.relatedKeyword.relKwOpt);
    const relKwOpLoading = useSelector(state=>state.relatedKeyword.relKwOpLoading);
    const onDialog = useSelector(state=>state.relatedKeyword.onDialog);
    const newKeyword = useSelector(state=>state.relatedKeyword.newKeyword);
    const dialogLoading = useSelector(state=>state.relatedKeyword.dialogLoading);

    useEffect(() => {
        dispatch({type: REL_KW_SEARCH_REL_KEYWORDS});
    }, [dispatch]);

    const [msgType, setMsgType] = useState(noticeType);
    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);

    useEffect(() => {
        dispatch({ type: CLEAR_REL_KW_DATA });
    }, []);

    const handleClose = () => {
        dispatch({ type: CLOSE_REL_KW_NOTIFICATION });
    };

    const offDialog = (event)=>{
        event.preventDefault();
        dispatch({
            type: UPDATE_REL_KW_DIALOG_FLAG,
            payload: { onDialog: false, newKeyword: ""}
        });
    }

    return <Paper sx={{flexGrow: 1, display: "flex"}}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between"}}
                     variant="dense">
                <Typography variant="h5">Related Keyword</Typography>
                <Box sx={{width: "300px", display: "flex", alignItems: "center"}}>
                    <SearchIcon/>
                    <Autocomplete
                        freeSolo
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        fullWidth
                        onInputChange={(e, v, r) => {
                            if(e) {
                                dispatch({type: REL_KW_SEARCH_KEYWORDS, search: v});
                            }
                        }}
                        onChange={(e, value)=>{
                            e.preventDefault();
                            if(value) {
                                dispatch({type: REL_KW_SELECT_KW, payload: value})
                            }
                        }}
                        // disableClearable
                        options={keywordsOpt || []}
                        getOptionLabel={(option) => option?.name || ""}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                placeholder="Search keyword by name"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment:(
                                        <>
                                            {opLoading ? (<CircularProgress size={20}/>) : null}
                                            {
                                                params.InputProps
                                                .endAdornment
                                            }
                                        </>
                                    )
                                }}
                            />
                        }
                    />
                </Box>
                <Box>
                </Box>
            </Toolbar>
            <Box sx={{ height: "4px" }}>
                {loading && <LinearProgress variant={"indeterminate"} color={"info"}/>}
            </Box>
            <Box sx={{flex: 1, display: "flex"}}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={notify}
                    onClose={handleClose}
                    autoHideDuration={6000}
                >
                    <Alert variant="filled" severity={msgType}>
                        {notice}
                    </Alert>
                </Snackbar>
                <Box sx={{flex: 1}}>
                    <List>
                        <ListItem  sx={{display: "flex", flex: 1}}>
                            <Typography sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "15px"}} >Keyword ID:</Typography>
                            {keyword.id ? keyword.id : "-"}
                        </ListItem>
                        <ListItem  sx={{display: "flex", flex: 1}}>
                            <Typography sx={{alignItems: "center", display:"flex", fontSize: "16px", marginLeft: "20px", marginRight: "15px"}} >Keyword Name:</Typography>
                            {keyword.name ? keyword.name : "-"}
                        </ListItem>
                        <ListItem >
                            <Typography sx={{fontSize: "16px", marginLeft: "20px", marginRight: "15px", alignSelf: "flex-start", paddingTop: "5px", width: "11%"}} >Related Keyword:</Typography>
                            <Box sx={{width: "100%"}}>
                                <Autocomplete
                                    freeSolo
                                    disabled ={!keyword?.id}
                                    sx={{
                                        "& .MuiInputBase-formControl": {
                                            paddingTop: "0px",
                                            paddingBottom: "0px"
                                        }
                                    }}
                                    onInputChange={(e, v, r) => {
                                        dispatch({ type: REL_KW_SEARCH_REL_KEYWORDS, search: v });
                                    }}
                                    onChange={(e, v, r) => {
                                        if(r === "selectOption") {
                                            const keywordObj = v[v.length - 1];
                                            if(keywordObj.id) {
                                                dispatch({
                                                    type: REL_KW_ADD,
                                                    payload: { keywordId: keyword.id, keywordToMap: {name: keywordObj.name, id: keywordObj.id}, arr: v}
                                                });
                                            }else {
                                                // Open dialog box to confirm need to create new keyword
                                                dispatch({
                                                    type: UPDATE_REL_KW_DIALOG_FLAG,
                                                    payload: { onDialog: true, newKeyword: keywordObj.inputValue}
                                                });
                                            }
                                            
                                        } else if(r === "removeOption") {
                                            const difference = keyword.relatedKeywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                            dispatch({
                                                type: REL_KW_REMOVE,
                                                payload: { keywordId: keyword.id, keywordToUnMap: {name: difference[0].name, id: difference[0].id}, arr: v}
                                            });
                                        }
                                    }}
                                    multiple
                                    id="linked-rel-items"
                                    options={relKwOpt}
                                    // getOptionLabel={(option) => option?.name}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                          return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                          return option.inputValue;
                                        }
                                        // Regular option
                                        return option?.name;
                                    }}
                                    isOptionEqualToValue={(option, value) => option?.id === value.id || null}
                                    filterOptions={(options, params) => {
                                        const filter = createFilterOptions();
                                        const filtered = filter(options, params);
                            
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue.toUpperCase() === option?.name.toUpperCase());
        
                                        if (inputValue !== '' && !isExisting) {
                                          filtered.push({
                                            inputValue,
                                            name: `Add "${inputValue}"`,
                                          });
                                        }

                                        return filtered;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                    value={keyword.relatedKeywords || []}
                                    renderTags={() => { }}
                                    renderInput={
                                        (params) => <TextField
                                        fullWidth
                                            {...params}
                                            variant="outlined"
                                            placeholder="Linked Keywords"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment:(
                                                    <>
                                                        {relKwOpLoading ? (<CircularProgress size={20}/>) : null}
                                                        {
                                                            params.InputProps
                                                            .endAdornment
                                                        }
                                                    </>
                                                )
                                            }}
                                        />
                                    }
                                />
                                <Autocomplete
                                    disabled ={!keyword?.id}
                                    onChange={(e, v, r) => {
                                        if (r === "selectOption") {
                                            const keywordObj = v[v.length - 1];
                                            dispatch({
                                                type: REL_KW_ADD,
                                                payload: { keywordId: keyword.id, keywordToMap: {name: keywordObj.name, id: keywordObj.id}, arr: v}
                                            });
                                        } else if(r === "removeOption") {
                                            const difference = keyword.relatedKeywords.filter(x => v.map(o => o.id).indexOf(x.id) < 0);
                                            dispatch({
                                                type: REL_KW_REMOVE,
                                                payload: { keywordId: keyword.id, keywordToUnMap: {name: difference[0].name, id: difference[0].id}, arr: v}
                                            });
                                        }
                                    }}
                                    multiple
                                    id="linked-rel-items"
                                    options={relKwOpt}
                                    getOptionLabel={(option) => option?.id}
                                    getOptionSelected={(option, value) => option?.id === value.id}
                                    value={keyword.relatedKeywords || []}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{height: "150px", overflowY: "auto"}}
                                            fullWidth
                                            {...params}
                                            variant="outlined"
                                            placeholder="Keywords"
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { display: 'none' }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: null
                                            }}
                                        />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            return (
                                                <Chip
                                                    size="small"
                                                    label={option?.name}
                                                    {...getTagProps({ index })}
                                                />
                                            )
                                        })
                                    }
                                />
                            </Box>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Box>
        <Dialog open={onDialog} onClose={offDialog}>
                <DialogTitle sx={{fontSize: 'large'}}>New Keyword</DialogTitle>
                {dialogLoading && <LinearProgress variant="indeterminate" color="primary"/>}
                <DialogContent>
                    <Typography sx={{fontSize: 'medium', fontWeight: "normal", paddingRight: "5px"}}>
                        This keyword is not exist. Do you want to create it?
                    </Typography>
                    <DialogContentText sx={{padding: "10px"}}>
                        <Box sx={{paddingBottom: "10px", display: "flex"}}>
                            <Typography sx={{fontSize: 'medium', fontWeight: "bold", paddingRight: "5px"}}>
                                Name:
                            </Typography>
                            <Typography sx={{fontSize: 'medium', paddingRight: "5px"}}>
                               {newKeyword}
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        dispatch({
                            type: REL_KW_CREATE_NEW_KEYWORD,
                            payload: { name: newKeyword, keywordId: keyword.id}
                        });
                    }}>Confirm</Button>
                    <Button onClick={offDialog}>Dismiss</Button>
                </DialogActions>
            </Dialog>
    </Paper>
}