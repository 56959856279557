import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ImageBox } from "../../components";
import {
    Grid, Box, CircularProgress
} from "@mui/material";
import {
    SELECT_THUMBNAIL
} from "../../actions/preview"
export function PreviewThumbnail() {
    const dispatch = useDispatch();
    const [removedSCData, setRemovedSCData] = useState([]);
    const [thumbData, setThumbData] = useState([]);
    const sourceContent = useSelector((state) => state.preview.sourceContent);
    const SCArrDisplay = useSelector((state) => state.preview.SCArrDisplay)
    // array of data that need to update status to -1
    const removeSrArr = useSelector((state) => state.preview.removeSCArr);

    useEffect(() => {
        setThumbData(SCArrDisplay.map(idx => sourceContent[idx]));     
    }, [sourceContent, SCArrDisplay, setThumbData]);

    useEffect(() => {
        setRemovedSCData(removeSrArr);
    }, [removeSrArr]);

    function handleThumbnailSelect(thumbId) {
        dispatch({ type: SELECT_THUMBNAIL, payload: { id: thumbId } });
    }

    function renderDeleteTag(itemId) {
        return (removedSCData.includes(itemId) && <Box sx={{
            position: "absolute",
            bottom: "5px",
            right: "5px",
            background: "rgba(255,200,200)",
            padding: "5px",
            fontSize: "larger",
            fontWeight: "bold"
        }}>Delete</Box> )
    }

    return <Box sx={{height: "100%"}}>
        {thumbData.length>=1 && 
            <AutoSizer>
                {({ width, height }) => (
                    <Grid width={width} height={height} container sx={{overflow: "scroll"}}>
                        {thumbData.map((data, index) => (
                            <Grid item xs={2} sm={3} md={3} key={index}>
                                <Box 
                                    sx={{
                                        width: "100%",
                                        // padding: "12px",
                                        border: "1px solid #eee",
                                        height: "300px",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        position: "relative",
                                        zIndex: "1"
                                    }}
                                    onClick={(e)=> {
                                        e.preventDefault()
                                        handleThumbnailSelect(data.id)
                                    }}
                                >
                                    <ImageBox 
                                        src={`/imagez/${data.tokenThumb?.replace('thumb', 'thumbnail') || ''}${data.type ? ".webp" : ''}`} 
                                        width="100%" 
                                        height="100%" 
                                        alt={data.altDescription ? data.altDescription : data.id}
                                    />
                                    {renderDeleteTag(data.id)}
                                </Box>
                            </Grid>
                            ))
                        }
                    </Grid>
                )}
            </AutoSizer>
        }
    </Box>
}

