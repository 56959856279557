import Fuse from 'fuse.js';

export class SearchService {
    registry = new Map();

    register(nameSpace, data, options) {
        const fuse = new Fuse(data, {
            includeScore: true,
            useExtendedSearch: true,
            ...options});
        this.registry.set(nameSpace, fuse);
    }

    find(nameSpace, query) {
        if(!query) {
            return [];
        }
        return this.registry
            .get(nameSpace)
            .search(query);
    }
}
