import Axios from "axios";

export function getAxiosClient(store) {
    // const baseURL = (process.env.NODE_ENV === 'development') ?
    //     process.env.REACT_APP_API_URL || '/v1/' :
    //     '/v1/'
    const baseURL = process.env.REACT_APP_API_URL;
    const axios = Axios.create({baseURL});
    return axios;
}
export function connectAxios(axios, store) {
    axios.interceptors.request.use(config => {
        const token = store.getState().auth && store.getState().auth.token;
        if (token) config.headers.common['Authorization'] = `Bearer ${token}`;
        return config;
    });

    axios.interceptors.response.use(res => {
        const {data, extensions, ...others} = res.data;
        // if (extensions) {
        //     store.dispatch({type: APP_API_EXTENSIONS, payload: extensions});
        // }
        const next = {...res, data, ...others}; //need others like error object
        return next;
    });
}

export function round(number, decimals = 0) {
    if (decimals === 0) {
        return Math.round(number);
    }
    if (decimals > 0) {
        // const stage1 = Math.round(+(number + 'e' + decimals));
        // const stage2 = +(stage1 + 'e-' + decimals);
        // return stage2;
        return +(Math.round(+(number + 'e' + decimals)) + 'e-' + decimals);
    } else {
        decimals = -decimals;
        // const stage1 = Math.round(+(number + 'e-' + decimals));
        // const stage2 = +(stage1 + 'e' + decimals);
        // return stage2;
        return +(Math.round(+(number + 'e-' + decimals)) + 'e' + decimals);
    }
}

export function flatten(obj, mode=0) {
    const result = {};
    for(const i in obj) {
        if((typeof obj[i]) === 'object' && !Array.isArray(obj[i])) {
            const temp = flatten(obj[i]);
            for(const j in temp) {
                if(mode > 0) {
                    result[i+'.'+j] = temp[j];
                } else {
                    result[j] = temp[j];
                }
            }
        } else {
            result[i] = obj[i];
        }
    }
    return result;
}