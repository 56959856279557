import { 
    Alert, Button,
    Box, LinearProgress, Paper, Snackbar, 
    Typography , Autocomplete, TextField, CircularProgress
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import {
    LOAD_SC_KW_LIST,
    SELECT_SC_KEYWORD,
    REMOVE_SEARCRH_SC_KEYWORD, 
    LOAD_SC_LIST, 
    SUBMIT_SC_DATA, 
    SELECT_ALL_THUMBNAIL, 
    DOWNLOAD_SOURCE_CONTENTS,
    PREVIEW_CLOSE_NOTIFICATION,
    MANAGER_SEARCH_SC_KEYWORD,
    PREVIEW_OP_CLOSE,
    PREVIEW_OP_OPEN
} from "../../actions/preview";
import {PreviewThumbnail} from './previewThumbnail'
import {PreviewTable} from './previewTable'

export function Preview() {
    const dispatch = useDispatch();
    const project = useSelector(state => state.system.selectedProject);
    const keyword = useSelector((state) => state.preview.keyword);
    const scData = useSelector((state) => state.preview.sourceContent);
    const SCArrDisplay = useSelector((state) => state.preview.SCArrDisplay);
    const notify = useSelector((state) => state.preview.notify);
    const notice = useSelector((state) => state.preview.notice);
    const noticeType = useSelector((state) => state.preview.noticeType);
    const loading = useSelector((state) => state.preview.loading);
    const [msgType, setMsgType] = useState(noticeType);
    const opOpen = useSelector((state) => state.preview.opOpen);
    const opOptions = useSelector((state) => state.preview.opOptions);
    const opSelected = useSelector((state) => state.preview.opSelected);
    const opLoading = opOpen && opOptions.length === 0;
    const filters = useSelector((state) => state.preview.filters);
   

    useEffect(() => {
        dispatch({ type: LOAD_SC_KW_LIST });
    }, [dispatch, project]);

    const handleBack = (e) => {
        e.preventDefault();
        dispatch({ type: REMOVE_SEARCRH_SC_KEYWORD });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TO DO: maybe need checking
        dispatch({ type: SUBMIT_SC_DATA });
    }

    const handleSelectAllThumbnail = (e) => {
        e.preventDefault();
        dispatch({ type: SELECT_ALL_THUMBNAIL });
    }

    const handleDownloadSC = (e) => {
        e.preventDefault();
        dispatch({ type: DOWNLOAD_SOURCE_CONTENTS });
    }

    useEffect(() => {
        if (noticeType) {
            setMsgType(noticeType);
        }
    }, [noticeType]);

    const handleClose = () => {
        dispatch({ type: PREVIEW_CLOSE_NOTIFICATION });
    };

    return <Paper sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: "0.5rem",
    }}>
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notify}
        onClose={handleClose}
        autoHideDuration={6000}
    >
        <Alert variant="filled" severity={msgType}>
            {notice}
        </Alert>
    </Snackbar>
    <Box sx={{
            bgcolor: "primary.light",
            minHeight: "60px",
            display: "flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            padding: "0px 12px 0px 12px"
        }}> 
        <Box sx={{display: "flex",flexDirection:"row",}}>  
            <Typography display="inline" variant="h6" sx={{paddingRight: "10px",fontWeight: "bolder"}}>Keyword :</Typography>
            { keyword?.id && <Box sx={{display: "flex", flexDirection: "column"}}><Typography display="inline" variant="h6">{keyword.name} ({SCArrDisplay.length}/{Object.keys(scData).length})</Typography><Typography sx={{paddingLeft: "10px", fontSize:"smaller"}}># {keyword?.id}</Typography></Box> }
            {!keyword?.id &&  <Box 
            sx={{
                marginTop: "10px",
                marginBottom: "10px",
                flex: "2",
                flexDirection: "row",
                position: "relative",
                marginLeft: "8px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.15)",
                borderRadius: "8px",
                //marginRight: "8px",
                fontSize: "4px",
                width: "1260px",
                "&.hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                },
            }}
            >
                <Autocomplete
                    sx={{ padding: "0px 0px 0px 4px",
                    width: "50%",}}
                    // onChange={(event, value) => {
                    //     if (value) {
                    //         dispatch({
                    //             type: "@@preview/NEW_KW",
                    //             payload: value,
                    //         });
                    //     }
                    // }}
                    onInputChange={(event, value) => {
                        dispatch({
                            type: MANAGER_SEARCH_SC_KEYWORD,
                            word: value,
                        });
                    }}
                    value={opSelected}
                    options={opOptions}
                    getOptionLabel={(option) =>
                        option.name || filters?.keyword
                    }
                    style={{ width: 300 }}
                    filterOptions={(x) => x}
                    open={opOpen}
                    onOpen={() => {
                        dispatch({ type: PREVIEW_OP_OPEN });
                    }}
                    onClose={() => {
                        dispatch({ type: PREVIEW_OP_CLOSE });
                    }}
                    renderInput={(params) => (
                        <div>
                            <TextField
                                {...params}
                                variant="standard"
                                label="Search for keywords"
                                inputProps={{
                                    ...params.inputProps,
                                    endAdornment: (
                                        <>
                                            {opLoading ? (
                                                <CircularProgress
                                                    size={20}
                                                />
                                            ) : null}

                                            {
                                                params.InputProps
                                                    .endAdornment
                                            }
                                        </>
                                    ),
                                }}
                            />
                        </div>
                    )}
                />
                <div style={{marginLeft:"910px"}}>
                    <SearchIcon/>
                </div>
            </Box>}
        </Box>
        { keyword?.id && 
            <Box sx={{display: "flex", flexDirection:"row"}}> 
                <Box sx={{display:"flex", paddingLeft: "5px", paddingRight: "5px"}}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleDownloadSC}
                    >
                        Download
                    </Button>
                </Box>
                <Box sx={{display:"flex", paddingLeft: "5px", paddingRight: "5px"}}>
                    <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleSelectAllThumbnail}
                    >
                        Select All
                    </Button>
                </Box>
                <Box sx={{display:"flex", paddingLeft: "5px", paddingRight: "5px"}}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
                <Box sx={{display:"flex", paddingLeft: "5px", paddingRight: "5px"}}> 
                    <Button
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={handleBack}
                    >
                        BACK
                    </Button>
                </Box>
            </Box>
        }
    </Box>
     {/* : ""} */}
     {loading && <LinearProgress />}
    <Box sx={{flex: 1, padding:"15px 10px 5px 10px"}}>
        {keyword?.id ? <PreviewThumbnail/> : <PreviewTable/>}
    </Box>
</Paper>
}